import React from 'react'
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  SortModelParams,
  PageChangeParams,
  ValueFormatterParams,
  ColParams,
} from '@material-ui/data-grid'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { lookupsQuerySelector } from '../../store/selector'
import {
  centralApplicantListSortModelState,
  centralApplicantListSelectedRowsAtom,
  centralApplicantSelectedRowssAtom,
  centralApplicantListPageSizeAtom,
  centralApplicantListCurrentPageAtom,
  statusFilterAtom,
  preferredLocationFilterAtom,
  preferredRoleFilterAtom,
  preferredShiftFilterAtom,
  allApplicantsListAtom,
  centralApplicantSelectedAllRowssAtom,
  filterByNameAtom,
} from './store/atom'
import { filteredApplicantListSelector } from './store/selector'
import { useHistory } from 'react-router-dom'
import { LookupTypesEnum } from '../../types/enums'
import { IApiResponse } from '../../types/interfaces'
import {
  TextField,
  Box,
  Checkbox,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import StyledAutoComplete from '../../components/StyledAutoComplete'
import customAxios from '../../services/customAxios'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'
import { toLocalDate } from '../../services/helpers'
import debounce from 'lodash.debounce'

const CustomTextField = withStyles({
  root: {
    width: 250,
    marginTop: 0,
    '& .MuiOutlinedInput-root': {
      height: 39,
    },
  },
})(TextField)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      '& .MuiDataGrid-window': {
        overflowX: 'hidden',
      },
    },
  },
  containerGid: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      height: 910,
    },
    [theme.breakpoints.up('md')]: {
      height: 710,
    },
    [theme.breakpoints.down('sm')]: {
      height: 510,
    },
  },
  tooltipPlacementBottom: {
    fontSize: 12,
  },
}))

const CentralTable = React.forwardRef(
  ({ siteId }: { siteId?: number }, ref: any) => {
    let classes = useStyles()
    const isSiteHrRole = siteId && siteId > 0

    let history = useHistory()
    const [selectedRows, setSelectedRows] = useRecoilState(
      centralApplicantSelectedRowssAtom,
    )

    const handleAllChange = (e: any) => {
      setSelectedAllRows(e.target.checked)
      if (e.target.checked) {
        setCountSelectedRows(filteredList.length)
        setSelectedRows(filteredList)
      } else {
        setCountSelectedRows(0)
        setSelectedRows([])
      }
    }
    const handleChange = (e: any) => {
      const rowId = Number(e.target.id)
      const selectedRow = filteredList.filter((f: any) => f.id === rowId)[0]
      const rows = selectedRows.filter((f: any) => f.id !== selectedRow.id)
      if (!e.target.checked) {
        rows.push(selectedRow)
      }
      setSelectedAllRows(false)
      setCountSelectedRows(rows.length)
      setSelectedRows(rows)
    }
    const columns: ColDef[] = [
      {
        field: 'selected',
        sortable: false,
        renderHeader: (params: ColParams) => {
          return (
            <Checkbox
              checked={selectedAllRows}
              onClick={handleAllChange}
              color="primary"
            />
          )
        },
        renderCell: (params: ValueFormatterParams) => {
          const checked =
            selectedAllRows ||
            selectedRows.findIndex((x: any) => x.id === params.rowModel.id) >= 0
          return (
            <Checkbox
              checked={checked}
              id={params.rowModel.id?.toString()}
              onClick={handleChange}
              color="primary"
            />
          )
        },
        width: 80,
      },
      { field: 'titleValue', headerName: 'Title', width: 70 },
      { field: 'firstName', headerName: 'First Name', width: 110 },
      { field: 'surName', headerName: 'Surname', width: 120 },
      { field: 'mobile', headerName: 'Mobile', width: 120 },
      {
        field: 'preferredLocationValue',
        headerName: 'Preferred Location',
        width: 170,
      },
      {
        field: 'siteValue',
        headerName: 'Assigned Site',
        width: 150,
      },
      {
        field: 'preferredShiftValue',
        headerName: 'Preferred Shift',
        width: 180,
        renderCell: (params: ValueFormatterParams) => {
          return (
            <Tooltip
              arrow
              title={params.data.preferredShiftValue}
              classes={{
                tooltipPlacementBottom: classes.tooltipPlacementBottom,
              }}
            >
              <Typography variant="body2" noWrap>
                {params.data.preferredShiftValue}
              </Typography>
            </Tooltip>
          )
        },
      },
      {
        field: 'preferredRoleValue',
        headerName: 'Preferred Role',
        width: 250,
        renderCell: (params: ValueFormatterParams) => {
          return (
            <Tooltip
              arrow
              title={params.data.preferredRoleValue}
              classes={{
                tooltipPlacementBottom: classes.tooltipPlacementBottom,
              }}
            >
              <Typography variant="body2" noWrap>
                {params.data.preferredRoleValue}
              </Typography>
            </Tooltip>
          )
        },
      },
      { field: 'statusValue', headerName: 'Status', width: 100 },
      {
        field: 'createdDate',
        headerName: 'Applied',
        width: 120,
        valueGetter: (params: ValueGetterParams) =>
          toLocalDate(params.getValue('createdDate')) || '',
      },
      {
        field: '',
        headerName: '',
        width: 150,
        renderCell: (params: ValueFormatterParams) => {
          const handleOnClick = () => {
            let applicantId = params.data.id as number
            history.push(`/applicants/${applicantId}`)
          }
          return (
            <OutlinedSecondaryButton
              size="small"
              variant="outlined"
              style={{ marginLeft: 16 }}
              onClick={handleOnClick}
            >
              View
            </OutlinedSecondaryButton>
          )
        },
      },
    ]
    // remove the preferredLocation and assigned site column in Site HR role
    if (isSiteHrRole) {
      let index = columns.findIndex(
        (col) => col.field === 'preferredLocationValue',
      )
      if (index !== -1) columns.splice(index, 2)
    }

    const setAllApplicants = useSetRecoilState(allApplicantsListAtom)

    const fetchData = React.useCallback(async () => {
      let response: IApiResponse
      if (siteId) {
        response = await customAxios.getAsync(
          `/api/Applicants/siteHR/${siteId}`,
        )
      } else {
        response = await customAxios.getAsync(`/api/Applicants`)
      }
      if (response.data) {
        let applicants = response.data.map((item: any) => {
          let applicant = {
            id: item.id,
            statusId: item.statusId,
            statusValue: item.statusValue,
            siteValue: item.siteValue,
            titleValue: item.titleValue,
            firstName: item.firstName,
            surName: item.surName,
            mobile: item.mobile,
            createdDate: item.createdDate,
            preferredLocationId: item.preferredLocationId,
            preferredSiteShiftId: item.preferredSiteShiftId,
            preferredRoleId: item.preferredRoleId,
            preferredLocationValue: item.preferredLocationValue,
            preferredShiftValue: item.preferredShiftValue,
            preferredRoleValue: item.preferredRoleValue,
          }
          return applicant
        })
        setAllApplicants(applicants)
      }
    }, [siteId, setAllApplicants])

    React.useImperativeHandle(ref, () => ({
      reloadData: () => {
        fetchData()
      },
    }))

    React.useEffect(() => {
      fetchData()
    }, [fetchData])

    // store state
    const statuses = useRecoilValue(
      lookupsQuerySelector(LookupTypesEnum.Status),
    )

    const locations = useRecoilValue(lookupsQuerySelector(LookupTypesEnum.Site))

    const roles = useRecoilValue(lookupsQuerySelector(LookupTypesEnum.Roles))

    const shifts = useRecoilValue(lookupsQuerySelector(LookupTypesEnum.Shifts))

    const [sortModel, setSortModel] = useRecoilState(
      centralApplicantListSortModelState,
    )
    const handleSortModelChange = (param: SortModelParams) => {
      if (
        param.sortModel[0].field !== sortModel[0].field ||
        param.sortModel[0].sort !== sortModel[0].sort
      ) {
        setSortModel(param.sortModel)
      }
    }

    const [pageSize, setPageSize] = useRecoilState(
      centralApplicantListPageSizeAtom,
    )
    const handlePageSizeChange = (param: PageChangeParams) => {
      setPageSize(param.pageSize)
    }

    const [currentPage, setCurrentPage] = useRecoilState(
      centralApplicantListCurrentPageAtom,
    )
    const handlePageChange = (param: PageChangeParams) => {
      setCurrentPage(param.page)
    }

    const [countSelectedRows, setCountSelectedRows] = useRecoilState(
      centralApplicantListSelectedRowsAtom,
    )

    const [selectedAllRows, setSelectedAllRows] = useRecoilState(
      centralApplicantSelectedAllRowssAtom,
    )

    const [selectedStatus, setSelectedStatus] = useRecoilState(statusFilterAtom)

    const [selectedLocation, setSelectedLocation] = useRecoilState(
      preferredLocationFilterAtom,
    )

    const [selectedShift, setSelectedShift] = useRecoilState(
      preferredShiftFilterAtom,
    )

    const [selectedRole, setSelectedRole] = useRecoilState(
      preferredRoleFilterAtom,
    )

    const [localName, setLocalName] = React.useState<string>('')

    const [stateName, setStateName] = useRecoilState(filterByNameAtom)

    const onSetStateName = (newValue: string) => {
      //console.log('setStateName: ', newValue)
      setStateName(newValue)
    }

    // using React.useCallback so that the lodash debounce works as expected.
    // Please see more detail here:  https://rajeshnaroth.medium.com/using-throttle-and-debounce-in-a-react-function-component-5489fc3461b3
    const setStateNameDelayed = React.useCallback(
      debounce(onSetStateName, 500),
      [],
    )

    React.useEffect(() => {
      setStateNameDelayed(localName)
    }, [localName, setStateNameDelayed])

    // using the isLoading as the flag so that the localName only get value from stateName one time (first load)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    React.useEffect(() => {
      if (!isLoading) {
        setIsLoading(true)
        if (stateName) {
          setLocalName(stateName)
        }
      }
    }, [isLoading, stateName, setLocalName])

    const filteredList = useRecoilValue(filteredApplicantListSelector)

    return (
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box display="flex" flexWrap="nowrap">
          <Box pb={1}>
            <CustomTextField
              name="name"
              label="Name"
              value={localName}
              onChange={(e: any) => {
                setLocalName(e.target.value)
              }}
              fullWidth
              autoComplete="given-name"
              variant="outlined"
              size="medium"
              margin="dense"
            />
          </Box>
          <Box pl={1}>
            <StyledAutoComplete
              multiple
              size="small"
              options={statuses}
              value={selectedStatus}
              onChange={(event: any, newValue: any) => {
                setSelectedStatus(newValue)
              }}
              getOptionSelected={(option: any, value: any) =>
                option.id === value.id
              }
              getOptionLabel={(option: any) => option.name}
              style={{ width: 250 }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size="small"
                  label="Status"
                  variant="outlined"
                />
              )}
            />
          </Box>
          {!isSiteHrRole && (
            <Box pl={1}>
              <StyledAutoComplete
                multiple
                size="small"
                options={locations}
                value={selectedLocation}
                onChange={(event: any, newValue: any) => {
                  setSelectedLocation(newValue)
                }}
                getOptionSelected={(option: any, value: any) =>
                  option.id === value.id
                }
                getOptionLabel={(option: any) => option.name}
                style={{ width: 300 }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Preferred Location"
                    variant="outlined"
                  />
                )}
              />
            </Box>
          )}

          <Box pl={1}>
            <StyledAutoComplete
              multiple
              size="small"
              options={shifts}
              value={selectedShift}
              onChange={(event: any, newValue: any) => {
                setSelectedShift(newValue)
              }}
              getOptionSelected={(option: any, value: any) =>
                option.id === value.id
              }
              getOptionLabel={(option: any) => option.name}
              style={{ width: 300 }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Preferred Shift"
                  variant="outlined"
                />
              )}
            />
          </Box>

          <Box pl={1}>
            <StyledAutoComplete
              multiple
              size="small"
              options={roles}
              value={selectedRole}
              onChange={(event: any, newValue: any) => {
                setSelectedRole(newValue)
              }}
              getOptionSelected={(option: any, value: any) =>
                option.id === value.id
              }
              getOptionLabel={(option: any) => option.name}
              style={{ width: 300 }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Preferred Role"
                  variant="outlined"
                />
              )}
            />
          </Box>
          <Box pl={1}>
            <OutlinedSecondaryButton
              variant="outlined"
              onClick={() => {
                setSelectedStatus([])
                setSelectedShift([])
                setSelectedLocation([])
                setSelectedRole([])
                setLocalName('')
              }}
            >
              Clear Filter
            </OutlinedSecondaryButton>
          </Box>
        </Box>
        <div className={classes.containerGid}>
          <DataGrid
            rowHeight={40}
            pageSize={pageSize}
            page={currentPage}
            sortModel={sortModel}
            sortingOrder={['desc', 'asc']}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            rows={filteredList}
            columns={columns}
            onSortModelChange={handleSortModelChange}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            className={classes.root}
            hideFooterSelectedRowCount={true}
          />
        </div>

        <div
          style={{
            marginTop: '-36px',
            paddingBottom: '20px',
            width: '100%',
          }}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {countSelectedRows > 0 ? (
              `Selected ${countSelectedRows} rows`
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        </div>
      </Box>
    )
  },
)

export default CentralTable
