import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '../../components/DialogTitle'
import DialogContent from '../../components/DialogContent'
import DialogActions from '../../components/DialogActions'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Box, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import customAxios from '../../services/customAxios'
import ContainedPrimaryButton from '../../components/ContainedPrimaryButton'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'
import { availableShiftsAtom } from './store/atom'
import { useRecoilValue } from 'recoil'
import StyledAutoComplete from '../../components/StyledAutoComplete'

export interface IExportCSV {
  exportType?: number
  exportDateType?: number
  fromDate?: Date
  toDate?: Date
  includePreviously: boolean
  ids: string
  siteId?: number
}

const useStyles = makeStyles((theme) => ({
  row: {
    padding: theme.spacing(1),
  },
  title: {
    marginTop: '8px',
  },
  button: {
    width: 90,
  },
}))
const AddSiteShiftDialog = forwardRef((props: any, ref: any) => {
  const { register, handleSubmit, setValue, formState } = useForm()
  const { isDirty } = formState
  const [open, setOpen] = useState(false)
  const [dirtyBug, setDirtyBug] = useState(false)
  const [state, setState] = useState<any>()
  const availableShifts = useRecoilValue(availableShiftsAtom)
  const onSubmit: SubmitHandler<any> = (data: any) => {
    const model = {
      isActive: true,
      siteId: state.siteId,
      shiftId: data.selectedShifts?.id,
    }
    customAxios
      .postAsync(`/api/SiteShifts/save-site-shift`, model)
      .then((res: any) => {
        if (props.refreshData) {
          props.refreshData()
          setOpen(false)
        }
      })
  }
  useImperativeHandle(ref, () => ({
    showDialog(details?: any) {
      setDirtyBug(true)

      setState(details)

      setOpen(true)
    },
  }))
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
  }
  const autoCompleteChange = (name: string, value: any) => {
    setDirtyBug(false)
    setState({ ...state, name: value })
    setValue(name, value, { shouldDirty: true })
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add Site Shift
        </DialogTitle>
        <DialogContent dividers>
          <Grid container className={classes.row}>
            <Grid item xs={12}>
              <Box pb={1}>
                <StyledAutoComplete
                  size="small"
                  options={availableShifts}
                  value={state?.selectedShifts}
                  ref={() => register('selectedShifts')}
                  onChange={(event: any, newValue: any) => {
                    autoCompleteChange('selectedShifts', newValue)
                  }}
                  getOptionSelected={(option: any, value: any) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option: any) =>
                    ` ${option.name} (${option.description})`
                  }
                  style={{ width: 250 }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Shift"
                      variant="outlined"
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box pt={1} pl={1}>
            <OutlinedSecondaryButton
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
            >
              Cancel
            </OutlinedSecondaryButton>
          </Box>

          <Box pt={1} pl={1}>
            <ContainedPrimaryButton
              type="submit"
              variant="contained"
              className={classes.button}
              disabled={!isDirty || dirtyBug}
            >
              Save
            </ContainedPrimaryButton>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
})

export default AddSiteShiftDialog
