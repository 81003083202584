import { Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const TitleField = withStyles({
  root: {
    color:"#097900"
  },
})(Typography)

export default TitleField
