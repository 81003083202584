import customAxios from './customAxios'
class LookupService {
  async getLookup(
    lookupName: string,
    excludeBlankDescription?: boolean,
    sortBy?: string,
  ) {
    let baseUrl = `/api/Lookups/`
    let url =
      excludeBlankDescription === undefined && sortBy === undefined
        ? baseUrl + lookupName
        : excludeBlankDescription !== undefined && sortBy === undefined
        ? baseUrl +
          lookupName +
          `?excludeBlankDescription=` +
          excludeBlankDescription
        : excludeBlankDescription === undefined && sortBy !== undefined
        ? baseUrl + lookupName + `?sortBy=` + sortBy
        : baseUrl +
          lookupName +
          `?excludeBlankDescription=` +
          excludeBlankDescription +
          `&sortBy=` +
          sortBy

    let res = await customAxios.getAsync(url)
    if (res.data) {
      return res.data
    } else {
      console.log(res.error)
    }
  }
}

let lookupService = new LookupService()
export default lookupService
