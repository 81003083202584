import React from 'react'
import {
  NativeSelect,
  FormControl,
  CssBaseline,
  InputBase,
} from '@material-ui/core'
import { ILookupType } from '../types/interfaces'
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles'

type NativeDropdownProps = {
  options: ILookupType[]
  selectedValue?: number
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void
  includeEmptyItem?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}))

const BootstrapInput = withStyles((theme: Theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

export const NativeDropdown: React.FunctionComponent<NativeDropdownProps> = ({
  options,
  selectedValue,
  handleChange,
  includeEmptyItem = false,
}) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <CssBaseline></CssBaseline>
      <FormControl className={classes.margin}>
        <NativeSelect
          value={selectedValue}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {includeEmptyItem ? (
            <option value={0}></option>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {options.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </React.Fragment>
  )
}
