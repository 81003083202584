import React from 'react'
import MaskedInput, { maskArray } from 'react-text-mask'

interface MaskedInputTextProps {
  inputRef: (ref: HTMLInputElement | null) => void
  maskInput?: maskArray | ((value: string) => maskArray)
  minLength?: number;
  defaultValue?: string | number | ReadonlyArray<string>;
}

const MaskedInputText = (props: MaskedInputTextProps) => {
  const { inputRef, maskInput,minLength,defaultValue, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={maskInput}
      placeholderChar={'\u2000'}
      minLength={minLength}
      guide={false}
      defaultValue={defaultValue}
      keepCharPositions={false}
      onKeyDown={(e: any) => {
        // Get cursor position and key code
        let cursorPos = Number(e.target.selectionStart)
        let keyCode = Number(e.keyCode)

        // Do work only on backspace key and on cursor position 6 (for ')' and 10 for '-')
        if (
          cursorPos > 0 &&
          e.target.value[cursorPos - 1] === '-' &&
          keyCode === 8
        ) {
          // Disable text mask events for this cursor positions
          e.preventDefault()

          // In my personal case I store phone nombers as just numbers 9999999999, instead of (999) 999-9999
          e.target.value = e.target.value || ''
          //e.target.value = e.target.value.replace(/\D+/g, '')

          // Simulate backspace delete
          e.target.value = `${e.target.value.substring(
            0,
            cursorPos - 2,
          )}${e.target.value.substring(cursorPos, e.target.value.length)}`
        }
      }}
    />
  )
}

export default MaskedInputText
