import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

const OutlinedSecondaryButton = withStyles(({ palette }) => ({
  outlined: {
    color: palette.primary.main,
    borderColor: palette.primary.main,
    '&:hover': {
      color: green[50],
      backgroundColor: palette.primary.light,
    },
  },
}))(Button)

export default OutlinedSecondaryButton
