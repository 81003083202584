import React, { MouseEvent, useRef, useState } from 'react'
import {
  Grid,
  Typography,
  CssBaseline,
  Box,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useRecoilValue } from 'recoil'
import { userInfoSelector } from '../../store/selector'
import { authProvider } from '../../authProvider'
import {
  centralApplicantListSelectedRowsAtom,
  centralApplicantSelectedRowssAtom,
} from './store/atom'
import { lookupsQuerySelector } from '../../store/selector'
import { LookupTypesEnum } from '../../types/enums'
import { NativeDropdown } from '../../components/NativeDropdown'
import { useReactToPrint } from 'react-to-print'
import ApplicationReport from '../Report/ApplicationReport'
import customAxios from '../../services/customAxios'
import ExportCSVDialog from '../Report/ExportCSVDialog'
import ContainedPrimaryButton from '../../components/ContainedPrimaryButton'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  assignLabel: {
    paddingRight: theme.spacing(),
  },
  button: {
    width: '90px',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const RightPanel = ({
  siteId,
  onAssignClick,
}: {
  siteId?: number
  onAssignClick?: () => void
}) => {
  const showAssignSection = !(siteId && siteId > 0)
  const classes = useStyles()

  const [selectedAssigner, setSelectedAssigner] = React.useState(0)
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedAssigner(event.target.value as number)
  }
  const assigners = useRecoilValue(lookupsQuerySelector(LookupTypesEnum.Site))
  const countSelectedRows = useRecoilValue(centralApplicantListSelectedRowsAtom)
  const selectedRows = useRecoilValue(centralApplicantSelectedRowssAtom)

  const [loading, setLoading] = React.useState(false)
  const handleAssignClick = async () => {
    if (selectedRows) {
      setLoading(true)
      const ids = selectedRows
        .map((elem: any) => {
          return elem.id
        })
        .join(';')
      await customAxios.postAsync(`/api/Applicants/assign`, {
        ids,
        assigner: selectedAssigner,
      })

      if (onAssignClick) {
        onAssignClick()
      }
      setLoading(false)
    }
  }
  const componentRef = useRef<any>()
  const [printApplicationRows, setPrintApplicationRows] = useState([])
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const onPrintClick = async () => {
    if (selectedRows) {
      const ids = selectedRows
        .map((elem: any) => {
          return elem.id
        })
        .join(';')
      const res = await customAxios.getAsync(
        `/api/Applicants/print-view?ids=${ids}`,
      )
      if (res.data) {
        setPrintApplicationRows(res.data)
        if (handlePrint) handlePrint()
      }
    }
  }
  const exportDialogRef = useRef<any>()
  const onExportClick = () => {
    exportDialogRef.current.showDialog()
  }

  const statuses = useRecoilValue(
    lookupsQuerySelector(LookupTypesEnum.Status),
  )  

  const [selectedStatus, setSelectedStatus] = React.useState(0)
  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedStatus(event.target.value as number)
  }

  const handleChangeStatus = async () => {
    if (selectedRows) {
      setLoading(true)
      const ids = selectedRows
        .map((elem: any) => {
          return elem.id
        })
      await customAxios.postAsync(`/api/Applicants/change-status`, {
        ids,
        statusId: selectedStatus,
      })

      if (onAssignClick) {
        onAssignClick()
      }
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline></CssBaseline>
      <Box
        display="flex"
        flexGrow={1}
        justifyContent="flex-end"
        pr={2}
        flexWrap="nowrap"
      >
        {showAssignSection && (
          <React.Fragment>
            <Box pt={2}>
              <Typography variant="body1" align="left" noWrap>
                Assign to:
              </Typography>
            </Box>

            <Box>
              <NativeDropdown
                options={assigners}
                selectedValue={selectedAssigner}
                handleChange={handleChange}
                includeEmptyItem
              ></NativeDropdown>
            </Box>
            <Box pt={1} className={classes.wrapper}>
              <ContainedPrimaryButton
                variant="contained"
                className={classes.button}
                onClick={handleAssignClick}
                disabled={
                  countSelectedRows <= 0 ||
                  loading ||
                  Number(selectedAssigner) < 1
                }
              >
                Assign
              </ContainedPrimaryButton>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
            <Box pt={1} pl={1}>
              <OutlinedSecondaryButton
                variant="outlined"
                className={classes.button}
                disabled={countSelectedRows <= 0}
                onClick={onPrintClick}
              >
                Print
              </OutlinedSecondaryButton>
            </Box>
          </React.Fragment>
        )}

        {!showAssignSection && (
          <React.Fragment>
            <Box pt={2}>
              <Typography variant="body1" align="left" noWrap>
                Change status to:
              </Typography>
            </Box>

            <Box>
              <NativeDropdown
                options={statuses}
                selectedValue={selectedStatus}
                handleChange={handleStatusChange}
                includeEmptyItem
              ></NativeDropdown>
            </Box>
            <Box pt={1} className={classes.wrapper}>
              <ContainedPrimaryButton
                variant="contained"
                className={classes.button}
                onClick={handleChangeStatus}
                disabled={
                  countSelectedRows <= 0 ||
                  loading ||
                  Number(selectedStatus) < 1
                }
              >
                Assign
              </ContainedPrimaryButton>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
            <Box pt={1} pl={1}>
              <OutlinedSecondaryButton
                variant="outlined"
                className={classes.button}
                disabled={countSelectedRows <= 0}
                onClick={onPrintClick}
              >
                Print
              </OutlinedSecondaryButton>
            </Box>
          </React.Fragment>
        )}
        <Box pt={1} pl={1}>
          <OutlinedSecondaryButton variant="outlined" onClick={onExportClick}>
            Export CSV
          </OutlinedSecondaryButton>
        </Box>
      </Box>
      <div className="onlyPrint">
        <ApplicationReport
          ref={componentRef}
          applications={printApplicationRows}
        />
      </div>
      <ExportCSVDialog ref={exportDialogRef} siteId={siteId} />
    </React.Fragment>
  )
}

const CentralHeader = ({
  siteId,
  handleAssignClick,
}: {
  siteId?: number
  handleAssignClick?: () => void
}) => {
  const userInfo = useRecoilValue(userInfoSelector)

  const classes = useStyles()

  const handleLogoutClick = (event: MouseEvent) => {
    authProvider.logout()
  }

  return (
    <div className={classes.root}>
      <CssBaseline></CssBaseline>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={6} container alignItems="flex-start" spacing={2}>
          <Grid item>
            <Typography variant="h5">Hello, {userInfo?.name}</Typography>
          </Grid>
          <Grid item>
            <OutlinedSecondaryButton
              variant="outlined"
              onClick={handleLogoutClick}
            >
              Log out
            </OutlinedSecondaryButton>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} container>
          <RightPanel
            siteId={siteId}
            onAssignClick={handleAssignClick}
          ></RightPanel>
        </Grid>
      </Grid>
    </div>
  )
}

export default CentralHeader
