import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import ErrorOutline from '@material-ui/icons/ErrorOutline'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: '20px 20px 20px 20px',
    borderRadius: 2,
    background: theme.palette.background.paper,
  },
  alert: {
    flexGrow: 1,
    borderRadius: 4,
  },
}))

const ErrorMessage = ({ text }: { text?: string }) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexGrow={1} className={classes.root}>
      <Alert
        variant="outlined"
        icon={<ErrorOutline fontSize="large" />}
        severity="error"
        className={classes.alert}
      >
        <Typography variant="h6">{text}</Typography>
      </Alert>
    </Box>
  )
}

export default ErrorMessage
