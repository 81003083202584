
const ConditionalRender = ({ condition, children }: { condition?: boolean; children?: any }) => {
  if (condition) {
    return children
  } else {
    return ''
  }
};

export default ConditionalRender;
