import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import LabelValueCell from './LabelValueCell'
import { useRecoilValue } from 'recoil'
import { selectedApplicantAtom } from './store/atom'
import * as Text from '../../assets/FormTextLabels.json'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
}))

const WorkingInNZTab = () => {
  const classes = useStyles()

  const applicantData = useRecoilValue(selectedApplicantAtom)

  const canShowReceivingBenefit =
    applicantData?.workVisaValue === 'NZ / Australian Citizen' ||
    applicantData?.workVisaValue === 'NZ Resident Permit'

  const canShowSSEVisa = applicantData?.workVisaValue === 'Work Visa'

  return (
    <Box className={classes.root}>
      <Box display="flex" flexGrow={1} flexDirection="column">
        <LabelValueCell
          label={Text.section2.question01}
          value={applicantData?.isEligible ? 'Yes' : 'No'}
          canAddColon={false}
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section2.question02}
          value={applicantData?.workVisaValue}
          canAddColon={false}
        ></LabelValueCell>

        {canShowReceivingBenefit && (
          <LabelValueCell
            label={Text.section2.question03}
            value={applicantData?.isReceivingBenefit ? 'Yes' : 'No'}
            canAddColon={false}
          ></LabelValueCell>
        )}

        {canShowSSEVisa && (
          <LabelValueCell
            label="Is this an SSE visa?"
            value={applicantData?.isSSEVisa ? 'Yes' : 'No'}
            canAddColon={false}
          ></LabelValueCell>
        )}

        {!canShowReceivingBenefit && (
          <React.Fragment>
            <LabelValueCell
              label={Text.section2.question04}
              value={applicantData?.visaExpiryDateStr}
            ></LabelValueCell>

            <LabelValueCell
              label={Text.section2.question05}
              value={applicantData?.passportNo}
            ></LabelValueCell>
          </React.Fragment>
        )}

        <LabelValueCell
          label={Text.section2.question06}
          value={applicantData?.nationalityValue}
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section2.question07}
          value={applicantData?.ethnicityValue}
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section2.question08}
          value={applicantData?.isFluentEnglishSpeaker ? 'Yes' : 'No'}
          canAddColon={false}
        ></LabelValueCell>
      </Box>
    </Box>
  )
}

export default WorkingInNZTab
