import { selector, selectorFamily } from 'recoil'
import { IAccountInfo } from 'react-aad-msal'
import customAxios from '../services/customAxios'
import { authProvider } from '../authProvider'
import { ILookupType, IMasterLookupType } from '../types/interfaces'
import { compareName, sortOrder } from '../services/helpers'

export const userLoginInfoSelector = selector<IAccountInfo | null>({
  key: 'userLoginInfoSelector',
  get: () => {
    return authProvider.getAccountInfo()
  },
})

export const userInfoSelector = selector({
  key: 'userInfoSelector',
  get: ({ get }) => {
    const state = get(userLoginInfoSelector)
    return state ? state.account : null
  },
})

export const lookupsQuerySelector = selectorFamily({
  key: 'lookupsQuerySelector',
  get: (lookupTypeName: string) => async () => {
    const res = await customAxios.getAsync(`/api/Lookups/${lookupTypeName}`)
    return res.data ? (res.data as ILookupType[]).sort(compareName) : []
  },
})

export const lookupsQuerySelectorWithSortOrder = selectorFamily({
  key: 'lookupsQuerySelectorWithSortOrder',
  get: (lookupTypeName: string) => async () => {
    const res = await customAxios.getAsync(`/api/Lookups/${lookupTypeName}`)
    return res.data ? (res.data as ILookupType[]).sort(sortOrder) : []
  },
})

export const editableLookupTypesQuerySelector = selectorFamily({
  key: 'editableLookupTypesQuerySelector',
  get: (parameter: any) => async () => {
    const res = await customAxios.getAsync(`/api/Lookups/editable-lookup-type`)
    return res.data ? (res.data as IMasterLookupType[]) : []
  },
})
