import React, { useImperativeHandle, useRef, useState } from 'react'
import { Box, TextField } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import StyledAutoComplete from '../../components/StyledAutoComplete'
import { ColDef, DataGrid, ValueFormatterParams } from '@material-ui/data-grid'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'
import NewIcon from '@material-ui/icons/Add'
import { useRecoilState, useRecoilValue } from 'recoil'
import { lookupsQuerySelector } from '../../store/selector'
import {
  allSiteShiftsListAtom,
  availableShiftsAtom,
  shiftsLookups,
} from './store/atom'
import { IApiResponse, ILookupType } from '../../types/interfaces'
import customAxios from '../../services/customAxios'
import { LookupTypesEnum } from '../../types/enums'
import RemoveConfirm from './RemoveConfirm'
import AddSiteShiftDialog from './AddSiteShiftDialog'
import lookupService from '../../services/lookupService'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      '& .MuiDataGrid-window': {
        overflowX: 'hidden',
      },
    },
  },
  containerGid: {
    width: '100%',
    height: 590,
  },
  tooltipPlacementBottom: {
    fontSize: 12,
  },
}))

const SiteShiftsTab = React.forwardRef((props: any, ref: any) => {
  let classes = useStyles()
  const lookpDialogRef = useRef<any>()
  const newDialogRef = useRef<any>()

  const siteShifts = useRecoilValue(lookupsQuerySelector(LookupTypesEnum.Site))

  const [shifts, setShifts] = useRecoilState<ILookupType[]>(shiftsLookups)

  const [availableShifts, setAvailableShifts] = useRecoilState(
    availableShiftsAtom,
  )
  const [selectedSiteShifts, setSelectedSiteShifts] = useState<ILookupType>()
  const [siteShiftsList, setSiteShiftsList] = useRecoilState(
    allSiteShiftsListAtom,
  )
  const fetchSiteShifts = React.useCallback(async () => {
    let response: IApiResponse
    const siteId = selectedSiteShifts?.id
    if (siteId) {
      response = await customAxios.getAsync(
        `/api/SiteShifts/${siteId}/site-shifts`,
      )
      if (response.data) {
        setSiteShiftsList(response.data)
        return
      }
    }
    setSiteShiftsList([])
  }, [selectedSiteShifts, setSiteShiftsList])

  const fetchShifts = React.useCallback(async () => {
    let data = await lookupService.getLookup(LookupTypesEnum.Shifts)
    if (data) {
      setShifts(data)
      return
    }
    setShifts([])
  }, [setShifts])

  useImperativeHandle(ref, () => ({
    reloadData() {
      fetchShifts()
    },
  }))

  React.useEffect(() => {
    fetchSiteShifts()
  }, [fetchSiteShifts])

  React.useEffect(() => {
    const available = shifts.filter(
      (x) => siteShiftsList.findIndex((y) => y.shiftId === x.id) < 0,
    )
    setAvailableShifts(available)
  }, [setAvailableShifts, shifts, siteShiftsList])

  const onSiteShiftsDetails = (details?: any) => {
    if (selectedSiteShifts) {
      lookpDialogRef.current.showDialog(details)
    }
  }
  const onNewSiteShiftsDetails = () => {
    if (selectedSiteShifts) {
      newDialogRef.current.showDialog({ siteId: selectedSiteShifts?.id })
    }
  }
  const columns: ColDef[] = [
    { field: 'name', headerName: 'Shift', width: 280 },
    { field: 'description', headerName: 'Hours', width: 280 },
    {
      field: '',
      headerName: '',
      width: 120,
      renderCell: (params: ValueFormatterParams) => {
        const handleOnClick = () => {
          onSiteShiftsDetails(params.data)
        }
        return (
          <OutlinedSecondaryButton
            size="small"
            variant="outlined"
            style={{ marginLeft: 16 }}
            onClick={handleOnClick}
          >
            Remove
          </OutlinedSecondaryButton>
        )
      },
    },
  ]

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" flexWrap="nowrap">
        <Box pb={1}>
          <StyledAutoComplete
            size="small"
            options={siteShifts}
            value={selectedSiteShifts}
            onChange={(event: any, newValue: any) => {
              setSelectedSiteShifts(newValue)
            }}
            getOptionSelected={(option: any, value: any) =>
              option.id === value.id
            }
            getOptionLabel={(option: any) => option.name}
            style={{ width: 250 }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size="small"
                label="Site"
                variant="outlined"
              />
            )}
          />
        </Box>
      </Box>
      <div className={classes.containerGid}>
        <DataGrid
          rowHeight={40}
          sortingOrder={['desc', 'asc']}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          rows={siteShiftsList}
          columns={columns}
          className={classes.root}
          hideFooterSelectedRowCount={true}
        />
      </div>
      <Box display="flex" flexWrap="nowrap">
        <Box pt={2}>
          <OutlinedSecondaryButton
            size="small"
            variant="outlined"
            startIcon={<NewIcon />}
            onClick={() => onNewSiteShiftsDetails()}
            disabled={!selectedSiteShifts || availableShifts?.length <= 0}
          >
            Add Shift
          </OutlinedSecondaryButton>
        </Box>
      </Box>
      <RemoveConfirm ref={lookpDialogRef} refreshData={fetchSiteShifts} />
      <AddSiteShiftDialog ref={newDialogRef} refreshData={fetchSiteShifts} />
    </Box>
  )
})

export default SiteShiftsTab
