import React from 'react'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { FormGroupProps } from '@material-ui/core/FormGroup/FormGroup'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    color: '#212529',
  },
}))
const BooleanRadioGroup = ({
  name,
  defaultValue,
  isRequired = true,
  inputRef,
  onChange,
  disabled = false,
}: {
  name?: string
  defaultValue?: FormGroupProps['defaultValue']
  isRequired?: boolean
  inputRef?: React.Ref<any>
  onChange?: (target: any) => void
  disabled?: boolean
}) => {
  const classes = useStyles()
  return (
    <RadioGroup name={name} aria-label="Title" row onChange={onChange}>
      <FormControlLabel
        value="true"
        inputRef={inputRef}
        disabled={disabled}
        control={
          isRequired ? (
            <Radio required checked={defaultValue === 'true'} />
          ) : (
            <Radio checked={defaultValue === 'true'} />
          )
        }
        label={
          <Typography className={classes.formControlLabel}>Yes</Typography>
        }
      />
      <FormControlLabel
        value="false"
        inputRef={inputRef}
        disabled={disabled}
        control={<Radio checked={defaultValue === 'false'} />}
        label={<Typography className={classes.formControlLabel}>No</Typography>}
      />
    </RadioGroup>
  )
}

export default BooleanRadioGroup
