import React, { useRef } from 'react'
import {
  Box,
  Tabs,
  Tab,
  Grid,
  AppBar,
  TextField,
  TabsActions,
} from '@material-ui/core'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import PersonalDetailsTab from './PersonalDetailsTab'
import WorkingInNZTab from './WorkingInNZTab'
import WorkingAtEastPack from './WorkingAtEastPack'
import MedicalTab from './MedicalTab'
import CriminalTab from './CriminalTab'
import PayrollTab from './PayrollTab'
import ConsentTab from './ConsentTab'
import {
  selectedApplicantAtom,
  notesAtom,
  enableSaveButtonAtom,
} from './store/atom'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import * as Text from '../../assets/FormTextLabels.json'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ minHeight: 500 }}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
}))

const StyledTab = withStyles((theme) => ({
  root: {
    '&:focus': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  selected: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))((props: any) => <Tab disableRipple {...props} />)

const BottomSection = () => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  const applicantData = useRecoilValue(selectedApplicantAtom)
  const setEnableSaveBtn = useSetRecoilState(enableSaveButtonAtom)
  const [notes, setNotes] = useRecoilState(notesAtom)

  const ref = useRef<TabsActions>(null)
  React.useEffect(() => {
    //hard-code fix bug missing indicator when first load https://github.com/mui-org/material-ui/issues/20527
    setTimeout(() => {
      ref.current?.updateIndicator()
    }, 1000)
  }, [])

  React.useMemo(() => {
    setNotes(applicantData?.note ? applicantData.note : '')
  }, [applicantData, setNotes])
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item container xs={10} style={{ paddingRight: 20 }}>
          <AppBar position="static" color="transparent">
            <Tabs
              action={ref}
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <StyledTab label={Text.section1.title} {...a11yProps(0)} />
              <StyledTab label={Text.section2.title} {...a11yProps(1)} />
              <StyledTab label={Text.section3.title} {...a11yProps(2)} />
              <StyledTab label={Text.section4.title} {...a11yProps(3)} />
              <StyledTab label={Text.section5.title} {...a11yProps(4)} />
              <StyledTab label={Text.section6.title} {...a11yProps(5)} />
              <StyledTab label={Text.section7.title} {...a11yProps(6)} />
            </Tabs>
          </AppBar>
          <Box style={{ width: '100%', padding: 20 }}>
            <TabPanel value={value} index={0}>
              <PersonalDetailsTab></PersonalDetailsTab>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <WorkingInNZTab></WorkingInNZTab>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <WorkingAtEastPack></WorkingAtEastPack>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <MedicalTab></MedicalTab>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <CriminalTab></CriminalTab>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <PayrollTab></PayrollTab>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <ConsentTab></ConsentTab>
            </TabPanel>
          </Box>
        </Grid>
        <Grid item container xs={2}>
          <TextField
            id="outlined-multiline-static"
            label="Notes"
            fullWidth
            multiline
            rows={22}
            variant="outlined"
            value={notes}
            onChange={(event) => {
              setNotes(event.target.value)
              setEnableSaveBtn(true)
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default BottomSection
