import { atom } from 'recoil'
import { ILookupType, IApplicant } from '../../../types/interfaces'

export const selectedApplicantAtom = atom<IApplicant | null>({
  key: 'selectedApplicantSelector',
  default: null,
})

export const selectedStatusAtom = atom<ILookupType | null>({
  key: 'selectedStatusAtom',
  default: null,
})

export const selectedSiteAtom = atom<ILookupType | null>({
  key: 'selectedSiteAtom',
  default: null,
})

export const selectedShiftAtom = atom<ILookupType | null>({
  key: 'selectedShiftAtom',
  default: null,
})

export const selectedSitePositionAtom = atom<ILookupType | null>({
  key: 'selectedSitePositionAtom',
  default: null,
})

export const selectedTaxCodeAtom = atom<ILookupType | null>({
  key: 'selectedTaxCodeAtom',
  default: null,
})

export const selectedKiwisaverPercentageAtom = atom<ILookupType | null>({
  key: 'selectedKiwisaverPercentageAtom',
  default: null,
})

export const selectedEmpTypeAtom = atom<ILookupType | null>({
  key: 'selectedEmpTypeAtom',
  default: null,
})

export const selectedGraderLineAtom = atom<ILookupType | null>({
  key: 'selectedGraderLineAtom',
  default: null,
})

export const commencementDateAtom = atom<string | Date | null>({
  key: 'commencementDateAtom',
  default: null,
})

export const occupancyStartDateAtom = atom<string | Date | null>({
  key: 'occupancyStartDateAtom',
  default: null,
})

export const contractStartDateAtom = atom<string | Date | null>({
  key: 'contractStartDateAtom',
  default: null,
})

export const contractEndDateAtom = atom<string | Date | null>({
  key: 'contractEndDateAtom',
  default: null,
})

export const hourlyRateAtom = atom<string>({
  key: 'hourlyRateAtom',
  default: '',
})

export const employeeCodeAtom = atom<string>({
  key: 'employeeCodeAtom',
  default: '',
})

export const irdNoAtom = atom<string>({
  key: 'irdNoAtom',
  default: '',
})

export const bankAccountNoAtom = atom<string>({
  key: 'bankAccountNoAtom',
  default: '',
})

export const isEnrolledInKiwiSaverAtom = atom<boolean>({
  key: 'isEnrolledInKiwiSaverAtom',
  default: false,
})

export const isContributingtoKiwiSaverAtom = atom<boolean>({
  key: 'isContributingtoKiwiSaverAtom',
  default: false,
})

export const enableSaveButtonAtom = atom<boolean>({
  key: 'enableSaveButtonAtom',
  default: false,
})

export const notesAtom = atom<string>({
  key: 'notesAtom',
  default: '',
})

export const enablePayrollControlAtom = atom<boolean>({
  key: 'enablePayrollControlAtom',
  default: false,
})

export const enableKiwiSaverControlAtom = atom<boolean>({
  key: 'enableKiwiSaverControlAtom',
  default: false,
})

export const visibleEditPayrollButtonAtom = atom<boolean>({
  key: 'visibleEditPayrollButtonAtom',
  default: true,
})

export const visibleEditEmpTypeButtonAtom = atom<boolean>({
  key: 'visibleEditEmpTypeButtonAtom',
  default: true,
})
