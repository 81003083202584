import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import LabelValueCell from './LabelValueCell'
import { useRecoilValue } from 'recoil'
import { selectedApplicantAtom } from './store/atom'
import * as Text from '../../assets/FormTextLabels.json'
import ConditionalRender from '../../components/ConditionalRender'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
}))

const WorkingAtEastPack = () => {
  const classes = useStyles()

  const applicantData = useRecoilValue(selectedApplicantAtom)

  const canShowForkliftCer =
    applicantData?.preferredRoleValue
      .toLowerCase()
      .indexOf('Forklift Driver'.toLowerCase()) !== -1
  return (
    <Box className={classes.root}>
      <Box display="flex" flexGrow={1} flexDirection="column">
        <LabelValueCell
          label={Text.section3.question01}
          value={applicantData?.isFormerEmployee ? 'Yes' : 'No'}
          canAddColon={false}
        ></LabelValueCell>

        {applicantData?.isFormerEmployee && (
          <LabelValueCell
            label={Text.section3.question02}
            value={applicantData?.employeeNo}
            canAddColon={false}
          ></LabelValueCell>
        )}
        <LabelValueCell
          label={Text.section3.question03}
          value={applicantData?.recentEmployerName}
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section3.question04}
          value={applicantData?.recentEmployerPhone}
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section3.question05}
          value={applicantData?.leaveReason}
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section3.question06}
          value={applicantData?.preferredLocationValue}
          canAddColon={false}
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section3.question07}
          value={applicantData?.preferredShiftValue}
          canAddColon={false}
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section3.question08}
          value={applicantData?.isAvailableForAllHours ? 'Yes' : 'No'}
        ></LabelValueCell>

        <ConditionalRender
          condition={applicantData?.isAvailableForAllHours === false}
        >
          <LabelValueCell
            label={Text.section3.question09}
            value={applicantData?.availabilityDetail}
          ></LabelValueCell>
        </ConditionalRender>

        <LabelValueCell
          label={Text.section3.question10}
          value={applicantData?.preferredRoleValue}
          canAddColon={false}
        ></LabelValueCell>
        {canShowForkliftCer && (
          <LabelValueCell
            label={Text.section3.question11}
            value={applicantData?.forkliftCertificateValue}
            canAddColon={false}
          ></LabelValueCell>
        )}
      </Box>
    </Box>
  )
}

export default WorkingAtEastPack
