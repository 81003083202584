import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ApplicationForm from './pages/ApplicationForm/ApplicationForm'
import NotFound from './pages/NotFound'
import Admin from './pages/Admin'
import ApplicantDetailPage from './pages/Admin/ApplicantDetailPage'
import Payroll from './pages/Admin/Payroll'

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={ApplicationForm} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/payroll" component={Payroll} />
        <Route exact path="/applicants/:id" component={ApplicantDetailPage} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default Routes
