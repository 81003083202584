import React from 'react'
import { Box, Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ISitePostion } from '../../types/interfaces'
import StyleTextField from '../../components/StyleTextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import ContainedPrimaryButton from '../../components/ContainedPrimaryButton'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import customAxios from '../../services/customAxios'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    minWidth: 500,
  },
}))

const SitePositionDialog = React.forwardRef(
  (
    {
      sitePosition,
      handleCancel,
      handleSave,
    }: {
      sitePosition: ISitePostion
      handleCancel?: () => void
      handleSave: (result: ISitePostion) => void
    },
    ref: any,
  ) => {
    const { register, handleSubmit, formState, reset } = useForm<ISitePostion>()
    const { isDirty } = formState

    const classes = useStyles()

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const [open, setOpen] = React.useState(false)

    const [error, setError] = React.useState<string>()

    React.useImperativeHandle(ref, () => ({
      openDialog: () => {
        setOpen(true)
      },
    }))

    const [currentSitePosition, setCurrentSitePosition] = React.useState<
      ISitePostion
    >(sitePosition)

    React.useEffect(() => {
      setCurrentSitePosition(sitePosition)
      reset(sitePosition)
    }, [sitePosition, setCurrentSitePosition, reset])

    const onSubmit: SubmitHandler<any> = async (data: any) => {
      const model = { ...currentSitePosition, ...data }
      let res = await customAxios.postAsync(
        `/api/Lookups/save-site-position`,
        model,
      )
      if (res.data) {
        setOpen(false)
        setError('')
        handleSave(res.data)
      } else if (res.error) {
        setError(res.error)
      }
    }

    const onTextFieldChange = (e: any) => {
      let changeObj = {
        ...currentSitePosition,
        [e.target.name]: e.target.value,
      }
      setCurrentSitePosition(changeObj)
    }

    const onCancel = () => {
      setOpen(false)
      setError('')
      if (handleCancel) {
        handleCancel()
      }
    }

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title">{`${
            currentSitePosition.id > 0 ? 'Edit' : 'Add'
          } Site Position`}</DialogTitle>
          <DialogContent dividers>
            <Box
              className={classes.content}
              display="flex"
              flexDirection="column"
            >
              <StyleTextField
                required
                name="code"
                inputRef={register}
                label="Code"
                defaultValue={currentSitePosition.code}
                onChange={onTextFieldChange}
                inputProps={{ maxLength: 100 }}
                fullWidth
                autoComplete="given-name"
              />
              <StyleTextField
                required
                inputRef={register}
                name="description"
                label="Description"
                defaultValue={currentSitePosition.description}
                onChange={onTextFieldChange}
                inputProps={{ maxLength: 500 }}
                fullWidth
                autoComplete="given-name"
                style={{ marginTop: 20 }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentSitePosition.isActive}
                    onChange={(event) => {
                      setCurrentSitePosition({
                        ...currentSitePosition,
                        [event.target.name]: event.target.checked,
                      })
                    }}
                    name="isActive"
                    color="primary"
                    inputRef={register}
                  />
                }
                label="Active"
                style={{ marginTop: 20 }}
              />

              {error && (
                <Alert
                  variant="outlined"
                  severity="error"
                  style={{ marginTop: 20 }}
                >
                  {error}
                </Alert>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <OutlinedSecondaryButton variant="outlined" onClick={onCancel}>
              Cancel
            </OutlinedSecondaryButton>

            <ContainedPrimaryButton
              type="submit"
              variant="contained"
              autoFocus
              disabled={!isDirty}
            >
              Save
            </ContainedPrimaryButton>
          </DialogActions>
        </form>
      </Dialog>
    )
  },
)

export default SitePositionDialog
