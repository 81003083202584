import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useRecoilValue } from 'recoil'
import { selectedApplicantAtom } from './store/atom'
import LabelValueCell from './LabelValueCell'
import * as Text from '../../assets/FormTextLabels.json'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
}))

const PayrollTab = () => {
  const classes = useStyles()

  const applicantData = useRecoilValue(selectedApplicantAtom)

  return (
    <Box className={classes.root}>
      <Box display="flex" flexGrow={1} flexDirection="column">
        <LabelValueCell
          label={Text.section6.question01}
          value={applicantData?.irdNo}
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section6.question02}
          value={applicantData?.bankAccountNo}
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section6.question03}
          value={applicantData?.taxCodeValue}
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section6.question04}
          value={applicantData?.isEnrolledInKiwiSaver ? 'Yes' : 'No'}
          canAddColon={false}
        ></LabelValueCell>

        {applicantData?.isEnrolledInKiwiSaver && (
          <React.Fragment>
            <LabelValueCell
              label={Text.section6.question05}
              value={applicantData?.isContributingtoKiwiSaver ? 'Yes' : 'No'}
              canAddColon={false}
            ></LabelValueCell>

            {applicantData?.isContributingtoKiwiSaver && (
              <LabelValueCell
                label={Text.section6.question06}
                value={applicantData?.kiwisaverContributionPercentageValue}
              ></LabelValueCell>
            )}
          </React.Fragment>
        )}
      </Box>
    </Box>
  )
}

export default PayrollTab
