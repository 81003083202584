import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useRecoilValue } from 'recoil'
import { selectedApplicantAtom } from './store/atom'
import LabelValueCell from './LabelValueCell'
import * as Text from '../../assets/FormTextLabels.json'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
}))

const PersonalDetailsTab = () => {
  const classes = useStyles()

  const applicantData = useRecoilValue(selectedApplicantAtom)

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={6} container direction="column">
          <Typography variant="body1">
            <Box fontWeight="fontWeightBold">{Text.section1.subtitle1}</Box>
          </Typography>
          <LabelValueCell
            label={Text.section1.question01}
            value={applicantData?.titleValue}
          ></LabelValueCell>
          <LabelValueCell
            label={Text.section1.question03}
            value={applicantData?.surName}
          ></LabelValueCell>
          <LabelValueCell
            label={Text.section1.question02}
            value={applicantData?.firstName}
          ></LabelValueCell>
          <LabelValueCell
            label={Text.section1.question04}
            value={applicantData?.preferredName}
          ></LabelValueCell>
          <LabelValueCell
            label={Text.section1.question05}
            value={applicantData?.genderValue}
          ></LabelValueCell>
          <LabelValueCell
            label="Address"
            value={`${applicantData?.street}, ${applicantData?.suburb}, ${applicantData?.town} ${applicantData?.postCode}`}
          ></LabelValueCell>
          <LabelValueCell
            label={Text.section1.question10}
            value={`${applicantData?.mobile}`}
          ></LabelValueCell>
          <LabelValueCell
            label={Text.section1.question11}
            value={`${applicantData?.email}`}
          ></LabelValueCell>
          <LabelValueCell
            label={Text.section1.question12}
            value={applicantData?.dateOfBirthStr}
          ></LabelValueCell>
        </Grid>
        <Grid item xs={6} container direction="column">
          <Typography variant="body1">
            <Box fontWeight="fontWeightBold">{Text.section1.subtitle2}</Box>
          </Typography>
          <LabelValueCell
            label={Text.section1.question14}
            value={applicantData?.emergencySurName}
          ></LabelValueCell>
          <LabelValueCell
            label={Text.section1.question13}
            value={applicantData?.emergencyFirstName}
          ></LabelValueCell>
          <LabelValueCell
            label={Text.section1.question15}
            value={applicantData?.relationshipValue}
          ></LabelValueCell>
          <LabelValueCell
            label={Text.section1.question16}
            value={applicantData?.emergencyPhone}
          ></LabelValueCell>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PersonalDetailsTab
