import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '../../components/DialogTitle'
import DialogContent from '../../components/DialogContent'
import DialogActions from '../../components/DialogActions'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import StyleTextField from '../../components/StyleTextField'
import { makeStyles } from '@material-ui/core/styles'
import customAxios from '../../services/customAxios'
import ContainedPrimaryButton from '../../components/ContainedPrimaryButton'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'
import Alert from '@material-ui/lab/Alert'

export interface IExportCSV {
  exportType?: number
  exportDateType?: number
  fromDate?: Date
  toDate?: Date
  includePreviously: boolean
  ids: string
  siteId?: number
}

const useStyles = makeStyles((theme) => ({
  row: {
    padding: theme.spacing(1),
  },
  title: {
    marginTop: '8px',
  },
  button: {
    width: 90,
  },
}))
const LookupDialog = forwardRef((props: any, ref: any) => {
  const { register, handleSubmit, setValue, formState } = useForm()
  const { isDirty } = formState
  const [open, setOpen] = useState(false)
  const [dirtyBug, setDirtyBug] = useState(false)
  const [state, setState] = useState<any>()
  const [error, setError] = useState<string>()
  const onSubmit: SubmitHandler<any> = (data: any) => {
    const model = { ...state, ...data }
    customAxios
      .postAsync(`/api/Lookups/save-lookup`, model)
      .then((res: any) => {
        setError(res.error)
        if (!res.error && props.refreshData) {
          props.refreshData()
          setOpen(false)
        }
      })
  }
  useImperativeHandle(ref, () => ({
    showDialog(details?: any) {
      setDirtyBug(true)
      setState(details)
      setError('')
      setValue('name', details.name, {
        shouldDirty: false,
      })
      setValue('description', details.description, {
        shouldDirty: false,
      })
      setValue('isActive', details.isActive, {
        shouldDirty: false,
      })
      setOpen(true)
    },
  }))
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
  }

  const onChange = (e: any) => {
    setDirtyBug(false)
    setState({ ...state, [e.target.name]: e.target.value })
    setValue(e.target.name, e.target.value, { shouldDirty: true })
  }

  const onChangeCheckobx = (e: any) => {
    setDirtyBug(false)
    setState({ ...state, [e.target.name]: e.target.checked })
    setValue(e.target.name, e.target.checked, { shouldDirty: true })
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {state?.id ? 'Edit Lookup' : 'Add Lookup'}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container className={classes.row}>
            <Grid item xs={12}>
              <StyleTextField
                required
                name="name"
                label="Name"
                defaultValue={state?.name}
                inputRef={register}
                onChange={onChange}
                inputProps={{ maxLength: 500 }}
                fullWidth
                autoComplete="given-name"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.row}>
            <Grid item xs={12}>
              <StyleTextField
                name="description"
                label="Description"
                defaultValue={state?.description}
                inputRef={register}
                onChange={onChange}
                inputProps={{ maxLength: 1000 }}
                fullWidth
                autoComplete="given-name"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.row}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state?.isActive}
                    name="isActive"
                    color="primary"
                    onChange={onChangeCheckobx}
                    inputRef={register}
                  />
                }
                label="Active"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.row}>
            <Grid item xs={12}>
              {error && (
                <Alert variant="outlined" severity="error">
                  {error}
                </Alert>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box pt={1} pl={1}>
            <OutlinedSecondaryButton
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
            >
              Cancel
            </OutlinedSecondaryButton>
          </Box>

          <Box pt={1} pl={1}>
            <ContainedPrimaryButton
              type="submit"
              variant="contained"
              className={classes.button}
              disabled={!isDirty || dirtyBug}
            >
              Save
            </ContainedPrimaryButton>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
})

export default LookupDialog
