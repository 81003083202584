import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { grey, green } from '@material-ui/core/colors'

const ContainedPrimaryButton = withStyles(({ palette }) => ({
  contained: {
    color: green[50],
    '&:disabled': {
      color: grey[400],
    },
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
}))(Button)

export default ContainedPrimaryButton
