import { createMuiTheme } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'

// customize the material-ui theme here.
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: blue[600],
    },
    primary: {
      main: '#78BE20',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Gilroy-Light'].join(','),
  },
  overrides: {
    MuiFormLabel: { root: { color: '#78BE20' } },
  },
})

export default theme
