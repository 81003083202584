import { MsalAuthProvider, LoginType } from 'react-aad-msal'
// import { LogLevel, Logger } from "msal";

// const logger = new Logger(
//   (logLevel, message, containsPii) => {
//     console.log("[MSAL]", message);
//   },
//   {
//     level: LogLevel.Verbose,
//     piiLoggingEnabled: false,
//   }
// );

// If two providers are created on the same page it will cause authentication errors.
export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: process.env.REACT_APP_AUTH_AUTHORITY as string,
      clientId: process.env.REACT_APP_AUTH_AUDIENCE as string,
      // postLogoutRedirectUri: window.location.origin,
      postLogoutRedirectUri: 'https://www.eastpack.co.nz',
      redirectUri: window.location.origin,
      validateAuthority: true,

      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: true,
    },
    system: {
      // logger: logger as any,
      loadFrameTimeout: 20000,
      tokenRenewalOffsetSeconds: 300
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  },
  {
    scopes: [process.env.REACT_APP_AUTH_SCOPE as string],
  },
  {
    loginType: LoginType.Redirect,
    // When a token is refreshed it will be done by loading a page in an iframe.
    // Rather than reloading the same page, we can point to an empty html file which will prevent
    // site resources from being loaded twice.
    tokenRefreshUri: window.location.origin + '/auth.html',
  },
)
