import { Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import React from 'react'
import { convertToString } from '../../services/helpers'
import * as Text from '../../assets/FormTextLabels.json'
class ApplicationReport extends React.PureComponent<any> {
  renderRow2Cols(rows: any[], section: string) {
    return rows.map(
      (row: any, index) =>
        row && (
          <TableRow key={`${section}_${index}`}>
            <TableCell colSpan={row.colSpan} className="cellTitle">
              {row.title}
            </TableCell>
            <TableCell colSpan={4 - row.colSpan}>{row.value}</TableCell>
          </TableRow>
        ),
    )
  }

  renderRowCols(rows: any[], section: string) {
    return rows.map((row: any, index) => {
      if (row && row.title1) {
        return (
          row && (
            <TableRow key={`${section}_${index}`}>
              <TableCell className="cellTitle cellHeader30">
                {row.title}
              </TableCell>
              <TableCell className="cellHeader20">{row.value}</TableCell>
              <TableCell className="cellTitle cellHeader25">
                {row.title1}
              </TableCell>
              <TableCell className="cellHeader25">{row.value1}</TableCell>
            </TableRow>
          )
        )
      } else {
        return (
          row && (
            <TableRow key={`${section}_${index}`}>
              <TableCell colSpan={row.colSpan} className="cellTitle">
                {row.title}
              </TableCell>
              <TableCell colSpan={4 - row.colSpan}>{row.value}</TableCell>
            </TableRow>
          )
        )
      }
    })
  }

  renderOneApplication(application: any, index: number) {
    const section1 = [
      {
        title: `${Text.section1.question01}:`,
        value: application.titleValue,
        colSpan: 1,
        title1: `${Text.section1.question03}:`,
        value1: application.surName,
      },
      {
        title: `${Text.section1.question02}:`,
        value: application.firstName,
        colSpan: 1,
        title1: `${Text.section1.question04}:`,
        value1: application.preferredName,
      },
      {
        title: `${Text.section1.address}:`,
        value: application.address,
        colSpan: 1,
      },
      {
        title: `${Text.section1.question11}:`,
        value: application.email,
        colSpan: 1,
      },
      {
        title: `${Text.section1.question10}:`,
        value: application.mobile,
        colSpan: 1,
      },
      {
        title: `${Text.section1.question12}:`,
        value: application.dateOfBirthStr,
        colSpan: 1,
        title1: `${Text.section1.question05}:`,
        value1: application.genderValue,
      },
      {
        title: `${Text.section2.question06}:`,
        value: application.nationalityValue,
        colSpan: 1,
        title1: `${Text.section2.question07}:`,
        value1: application.ethnicityValue,
      },
    ]

    const section2 = [
      {
        title: `${Text.section1.question13}:`,
        value: application.emergencyFirstName,
        colSpan: 1,
        title1: `${Text.section1.question14}:`,
        value1: application.emergencySurName,
      },
      {
        title: `${Text.section1.question15}:`,
        value: application.relationshipValue,
        colSpan: 1,
        title1: `${Text.section1.question16}:`,
        value1: application.emergencyPhone,
      },
    ]

    const section3 = [
      {
        title: `${Text.section4.question01}:`,
        value: convertToString(application.hasMedicalCondition),
        colSpan: 3,
      },
      application.hasMedicalCondition
        ? {
            title: `${Text.section4.question02}:`,
            value: application.medicalConditionDetail,
            colSpan: 1,
          }
        : undefined,
      {
        title: `${Text.section4.question03}:`,
        value: convertToString(application.hasAggravatedCondition),
        colSpan: 3,
      },
      application.hasAggravatedCondition
        ? {
            title: `${Text.section4.question04}:`,
            value: application.aggravatedConditionDetail,
            colSpan: 1,
          }
        : undefined,
      {
        title: `${Text.section4.question05}:`,
        value: convertToString(application.requiresMedicine),
        colSpan: 3,
      },
      application.requiresMedicine
        ? {
            title: `${Text.section4.question06}:`,
            value: application.requiresMedicineDetail,
            colSpan: 1,
          }
        : undefined,
      {
        title: `${Text.section4.question07}:`,
        value: convertToString(application.hasBackInjury),
        colSpan: 3,
      },
      application.hasBackInjury
        ? {
            title: `${Text.section4.question08}:`,
            value: application.backInjuryDetail,
            colSpan: 1,
          }
        : undefined,
      {
        title: `${Text.section4.question09}:`,
        value: convertToString(application.hasAcc),
        colSpan: 3,
      },
      application.hasAcc
        ? {
            title: `${Text.section4.question10}:`,
            value: application.accDetail,
            colSpan: 1,
          }
        : undefined,
      {
        title: `${Text.section4.question11}:`,
        value: convertToString(application.hasArrivedIntoNz),
        colSpan: 3,
      },
      application.hasArrivedIntoNz
        ? {
            title: `${Text.section4.question12}:`,
            value: application.arrivedIntoNzDateStr,
            colSpan: 3,
          }
        : undefined,
      {
        title: `${Text.section4.question13}:`,
        value: convertToString(application.hasInfectiousDisease),
        colSpan: 3,
      },
      application.hasInfectiousDisease
        ? {
            title: `${Text.section4.question14}:`,
            value: application.infectiousDiseaseDetail,
            colSpan: 1,
          }
        : undefined,
      {
        title: `${Text.section4.question15}:`,
        value: convertToString(application.hasTestConsent),
        colSpan: 3,
      },
      !application.hasTestConsent
        ? {
            title: `${Text.section4.question16}:`,
            value: application.testConsentDetail,
            colSpan: 1,
          }
        : undefined,

      {
        title: `${Text.section4.question17}:`,
        value: convertToString(application.shareCovidVaccination),
        colSpan: 3,
      },
      application.shareCovidVaccination
        ? {
            title: `${Text.section4.question18}:`,
            value: application.covidStatus,
            colSpan: 1,
          }
        : undefined,
    ]

    const section4 = [
      {
        title: `${Text.section3.question01}:`,
        value: convertToString(application.isFormerEmployee),
        colSpan: 1,
      },
      application.isFormerEmployee
        ? {
            title: `${Text.section3.question02}:`,
            value: application.employeeNo,
            colSpan: 1,
          }
        : undefined,
      {
        title: `${Text.section3.question03}:`,
        value: application.recentEmployerName,
        colSpan: 2,
      },
      {
        title: `${Text.section3.question04}:`,
        value: application.recentEmployerPhone,
        colSpan: 2,
      },
      {
        title: `${Text.section3.question05}:`,
        value: application.leaveReason,
        colSpan: 1,
      },
    ]

    const section5 = [
      {
        title: `${Text.section3.question06}:`,
        value: application.preferredLocationValue,
        colSpan: 1,
        title1: `${Text.section3.question07}:`,
        value1: application.preferredShiftValue,
      },
      {
        title: `${Text.section3.question10}:`,
        value: application.preferredRoleValue,
        colSpan: 1,
      },
      application.preferredRoleValue.includes('Forklift Driver')
        ? {
            title: `${Text.section3.question11}:`,
            value: application.forkliftCertificateValue,
            colSpan: 3,
          }
        : undefined,
      {
        title: `${Text.section3.question08}:`,
        value: convertToString(application.isAvailableForAllHours),
        colSpan: 3,
      },
      !application.isAvailableForAllHours
        ? {
            title: `${Text.section3.question09}:`,
            value: application.availabilityDetail,
            colSpan: 2,
          }
        : undefined,
      {
        title: `${Text.section2.question01}:`,
        value: convertToString(application.isEligible),
        colSpan: 3,
      },
      application.workVisaValue === 'Work Visa'
        ? {
            title: `${Text.section2.question02}:`,
            value: application.workVisaValue,
            colSpan: 1,
            title1: 'Is this an SSE visa?:',
            value1: convertToString(application.isSSEVisa),
          }
        : {
            title: `${Text.section2.question02}:`,
            value: application.workVisaValue,
            colSpan: 1,
          },
      application.workVisaValue === 'NZ Work Permit' ||
      application.workVisaValue === 'Student Visa' ||
      application.workVisaValue === 'Work Permit' ||
      application.workVisaValue === 'Work Visa'
        ? {
            title: `${Text.section2.question04}:`,
            value: application.visaExpiryDateStr,
            colSpan: 1,
          }
        : undefined,
      application.workVisaValue === 'NZ Work Permit' ||
      application.workVisaValue === 'Student Visa' ||
      application.workVisaValue === 'Work Permit' ||
      application.workVisaValue === 'Work Visa'
        ? {
            title: `${Text.section2.question05}:`,
            value: application.passportNo,
            colSpan: 1,
          }
        : undefined,
      {
        title: `${Text.section2.question08}:`,
        value: convertToString(application.isFluentEnglishSpeaker),
        colSpan: 3,
      },
    ]
    const section6 = [
      {
        title: `${Text.section6.question01}:`,
        value: application.irdNo,
        colSpan: 1,
        title1: `${Text.section6.question03}:`,
        value1: application.taxCodeValue,
      },
      {
        title: `${Text.section6.question02}:`,
        value: application.bankAccountNo,
        colSpan: 1,
      },
      {
        title: `${Text.section6.question04}:`,
        value: convertToString(application.isEnrolledInKiwiSaver),
        colSpan: 1,
      },
      application.isEnrolledInKiwiSaver
        ? {
            title: `${Text.section6.question05}:`,
            value: convertToString(application.isContributingtoKiwiSaver),
            colSpan: 1,
            title1: `${Text.section6.question06}:`,
            value1: application.kiwisaverContributionValue,
          }
        : undefined,
    ]

    const section7 = [
      {
        title: `${Text.section5.question01}:`,
        value: convertToString(application.hasCriminalConviction),
        colSpan: 3,
      },
      application.hasCriminalConviction
        ? {
            title: `${Text.section5.question02}:`,
            value: application.criminalConvictionDetail,
            colSpan: 1,
          }
        : undefined,
      {
        title: `${Text.section5.question03}:`,
        value: convertToString(application.hasDrivingOffence),
        colSpan: 3,
      },
      application.hasDrivingOffence
        ? {
            title: `${Text.section5.question04}:`,
            value: application.drivingOffenceDetail,
            colSpan: 1,
          }
        : undefined,
    ]

    const section8 = [
      {
        title: `${Text.section7.question01}:`,
        value: 'Yes',
        colSpan: 1,
      },
      {
        title: `${Text.section7.question02}:`,
        value: application.jobReferralValue,
        colSpan: 1,
      },
      (application.jobReferralValue as String).includes(
        'Referred by an EastPack Employee',
      )
        ? {
            title: `${Text.section7.question03}:`,
            value: application.referralEmployeeDetail,
            colSpan: 1,
          }
        : undefined,
    ]
    return (
      <div key={index}>
        <Table>
          <TableBody>
            <TableRow className="rowBlue">
              <TableCell colSpan={4}>1. Personal Details</TableCell>
            </TableRow>
            {this.renderRowCols(section1, 'section1')}
            <TableRow className="rowBlue">
              <TableCell colSpan={4}>2. Emergency Contact Details:</TableCell>
            </TableRow>
            {this.renderRowCols(section2, 'section2')}

            <TableRow className="rowBlue">
              <TableCell colSpan={4}>3. Health and Safety:</TableCell>
            </TableRow>
            {this.renderRow2Cols(section3, 'section3')}

            <TableRow className="rowBlue">
              <TableCell colSpan={4}>4. Employment Details:</TableCell>
            </TableRow>
            {this.renderRow2Cols(section4, 'section4')}
            <TableRow className="rowBlue">
              <TableCell colSpan={4}>5. Availability for shifts:</TableCell>
            </TableRow>
            {this.renderRowCols(section5, 'section5')}
            <TableRow className="rowBlue">
              <TableCell colSpan={4}>6. Payroll Details:</TableCell>
            </TableRow>
            {this.renderRowCols(section6, 'section6')}
            <TableRow className="rowBlue">
              <TableCell colSpan={4}>7. Criminal History:</TableCell>
            </TableRow>
            {this.renderRowCols(section7, 'section7')}
            <TableRow className="rowBlue">
              <TableCell colSpan={4}>8. Declaration and Consent:</TableCell>
            </TableRow>
            {this.renderRowCols(section8, 'section8')}
          </TableBody>
        </Table>
        <div className="pagebreak" />
      </div>
    )
  }
  render() {
    const applications: any[] = this.props.applications
      ? this.props.applications
      : []
    return (
      <div>
        {applications.map((row: any, index: number) =>
          this.renderOneApplication(row, index),
        )}
      </div>
    )
  }
}

export default ApplicationReport
