import { atom } from 'recoil'
import { SortModel } from '@material-ui/data-grid'
import { ILookupType } from '../../../types/interfaces'
import { RowsProp } from '@material-ui/data-grid'

export const allApplicantsListAtom = atom<RowsProp>({
  key: 'allApplicantsListAtom',
  default: [],
})

export const centralApplicantListSortModelState = atom<SortModel>({
  key: 'centralApplicantListSortModelState',
  default: [
    {
      field: 'createdDate',
      sort: 'desc',
    },
  ],
})

export const centralApplicantListPageSizeAtom = atom({
  key: 'centralApplicantListPageSizeAtom',
  default: 100,
})

export const centralApplicantListCurrentPageAtom = atom({
  key: 'centralApplicantListCurrentPageAtom',
  default: 1,
})

export const centralApplicantListSelectedRowsAtom = atom({
  key: 'centralApplicantListSelectedRowsAtom',
  default: 0,
})

export const centralApplicantSelectedRowssAtom = atom<any>({
  key: 'centralApplicantSelectedRowssAtom',
  default: [],
})
export const centralApplicantSelectedAllRowssAtom = atom<any>({
  key: 'centralApplicantSelectedAllRowssAtom',
  default: false,
})
export const statusFilterAtom = atom<ILookupType[]>({
  key: 'statusFilterAtom',
  default: [],
})

export const preferredLocationFilterAtom = atom<ILookupType[]>({
  key: 'preferredLocationFilterAtom',
  default: [],
})

export const preferredShiftFilterAtom = atom<ILookupType[]>({
  key: 'preferredShiftFilterAtom',
  default: [],
})

export const preferredRoleFilterAtom = atom<ILookupType[]>({
  key: 'preferredRoleFilterAtom',
  default: [],
})

export const filterByNameAtom = atom<string>({
  key: 'filterByNameAtom',
  default: '',
})
