import React from "react";
import { Button } from "@material-ui/core";
import ConditionalRender from "./ConditionalRender";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    border: "2px solid #78BE20",
    "&:hover": {
      border: "2px solid #78BE20",
      color: "#FFF",
      background: "#78BE20",
    },
  },
}));

const StepsButton = ({ hiddenBack, onBack, onNext, isSubmit}: { 
    hiddenBack?: boolean; 
    isSubmit?:boolean;
    onBack?: () => void;
    onNext?: () => void;}) => {
  const classes = useStyles();
  return (
    <div className={classes.buttons}>
      <ConditionalRender condition={!hiddenBack}>
        <Button  variant="outlined" onClick={onBack} color="primary"  className={classes.button}>
          Back
        </Button>
      </ConditionalRender>
      <Button type="submit" onClick={onNext} variant="outlined"   color="primary" className={classes.button}>
        {isSubmit ? "Submit" : "Next"}
      </Button>
    </div>
  );
};

export default StepsButton;
