import { selector } from 'recoil'
import {
  statusFilterAtom,
  preferredLocationFilterAtom,
  preferredShiftFilterAtom,
  preferredRoleFilterAtom,
  filterByNameAtom,
  allApplicantsListAtom,
} from './atom'
import { RowsProp } from '@material-ui/data-grid'

export const filteredApplicantListSelector = selector<RowsProp>({
  key: 'filteredApplicantListSelector',
  get: ({ get }) => {
    let allApplicantList = get(allApplicantsListAtom)
    let selectedStatuses = get(statusFilterAtom)
    let selectedLocations = get(preferredLocationFilterAtom)
    let selectedShifts = get(preferredShiftFilterAtom)
    let selectedRoles = get(preferredRoleFilterAtom)
    let name = get(filterByNameAtom)

    let filterApplicantList: RowsProp = allApplicantList
    if (selectedStatuses && selectedStatuses.length > 0) {
      filterApplicantList = filterApplicantList.filter((item) => {
        let index = selectedStatuses.findIndex((s) => s.id === item.statusId)
        return index !== -1
      })
    }

    if (selectedLocations && selectedLocations.length > 0) {
      filterApplicantList = filterApplicantList.filter((item) => {
        let index = selectedLocations.findIndex(
          (l) => l.id === item.preferredLocationId,
        )
        return index !== -1
      })
    }

    if (selectedShifts && selectedShifts.length > 0) {
      filterApplicantList = filterApplicantList.filter((item) => {
        if (item.preferredShiftValue) {
          let preferredShiftValue = item.preferredShiftValue.toLowerCase()
          let index = selectedShifts.findIndex(
            (x) => preferredShiftValue.indexOf(x.name.toLowerCase()) !== -1,
          )
          return index !== -1
        }
        return false
      })
    }

    if (selectedRoles && selectedRoles.length > 0) {
      filterApplicantList = filterApplicantList.filter((item) => {
        if (item.preferredRoleValue) {
          let preferredRoleValue = item.preferredRoleValue.toLowerCase()
          let index = selectedRoles.findIndex(
            (role) =>
              preferredRoleValue.indexOf(role.name.toLowerCase()) !== -1,
          )
          return index !== -1
        }
        return false
      })
    }

    if (name) {
      let lowerName = name.toLowerCase()
      filterApplicantList = filterApplicantList.filter((item) => {        
        let fullName = `${item.firstName.toLowerCase()} ${item.surName.toLowerCase()}`
        return fullName.indexOf(lowerName) !== -1
      })
    }

    return filterApplicantList.map((x: any) => {
      return { ...x, selected: false }
    })
  },
})
