import React, { forwardRef, useImperativeHandle } from "react";
import { Button, CircularProgress, PropTypes } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    marginLeft: theme.spacing(1),
    border: '2px solid #78BE20',
    '&:hover': {
      border: '2px solid #78BE20',
      color: '#FFF',
      background: '#78BE20',
    },
  },
}));

interface LoadingButtonProps {  
  onClick?:  () => void;
  type?: 'submit' | 'reset' | 'button';
  variant?: 'text' | 'outlined' | 'contained';
  color?: PropTypes.Color;
  label?:string;
  className?: string;
}

const LoadingButton = forwardRef((props: LoadingButtonProps, ref: any) => {
  
  const { onClick,type, variant, color, label, className} = props
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  
  useImperativeHandle(ref, () => ({
    showLoading(isShow:boolean) {
      setLoading(isShow);
    },
  }))
  
  return (
    <div className={classes.wrapper}>
        <Button
          variant={variant}
          color={color}
          disabled={loading}
          onClick={onClick}
          className={className}
          type={type}
        >
          {label}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
});

export default LoadingButton;
