import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ISection5, section5Atom } from './state'
import { useRecoilState } from 'recoil'
import { FormControl, FormLabel, Grid } from '@material-ui/core'
import * as Text from '../../assets/FormTextLabels.json'
import ConditionalRender from '../../components/ConditionalRender'
import BooleanRadioGroup from '../../components/BooleanRadioGroup'
import StepsButton from '../../components/StepsButton'
import StyleTextField from '../../components/StyleTextField'
import TitleField from '../../components/TitleField'

const Section5 = ({ next, back }: { next?: () => void; back?: () => void }) => {
  const { register, handleSubmit } = useForm<ISection5>({ mode: 'all' })
  const [state, setState] = useRecoilState<ISection5>(section5Atom)
  let action: any
  const onSubmit: SubmitHandler<ISection5> = (data) => {
    const section5 = {
      ...data,
    }
    setState({
      ...state,
      ...section5,
    })
    if (action) action()
  }

  const {
    hasCriminalConviction,
    criminalConvictionDetail,
    hasDrivingOffence,
    drivingOffenceDetail,
  } = state

  const onChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  return (
    <form className="form-step" onSubmit={handleSubmit(onSubmit)}>
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TitleField variant="h4" gutterBottom>
              {Text.section5.subtitle1}
            </TitleField>
          </Grid>
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{ __html: Text.section5.blurb }}></p>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section5.question01}
              </FormLabel>
              <BooleanRadioGroup
                name="hasCriminalConviction"
                defaultValue={hasCriminalConviction}
                inputRef={register}
                onChange={onChange}
              />
              <ConditionalRender condition={hasCriminalConviction === 'true'}>
                <StyleTextField
                  required
                  name="criminalConvictionDetail"
                  label={Text.section5.question02}
                  inputRef={register}
                  onChange={onChange}
                  defaultValue={criminalConvictionDetail}
                  fullWidth
                  multiline={true}
                />
              </ConditionalRender>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section5.question03}
              </FormLabel>
              <BooleanRadioGroup
                name="hasDrivingOffence"
                defaultValue={hasDrivingOffence}
                inputRef={register}
                onChange={onChange}
              />

              <ConditionalRender condition={hasDrivingOffence === 'true'}>
                <StyleTextField
                  required
                  name="drivingOffenceDetail"
                  label={Text.section5.question04}
                  inputRef={register}
                  onChange={onChange}
                  defaultValue={drivingOffenceDetail}
                  fullWidth
                  multiline={true}
                />
              </ConditionalRender>
            </FormControl>
          </Grid>
        </Grid>
      </React.Fragment>
      <StepsButton onBack={back} onNext={() => (action = next)} />
    </form>
  )
}

export default Section5
