import { selector } from 'recoil'
import customAxios from '../../../services/customAxios'
import { ILookupType } from '../../../types/interfaces'
import { selectedSiteAtom } from './atom'

export const sitePositionsSelector = selector<ILookupType[]>({
  key: 'sitePositionsSelector',
  get: async ({ get }) => {
    let selectedSite = get(selectedSiteAtom)
    if (selectedSite) {
      const res = await customAxios.getAsync(
        `/api/Lookups/${selectedSite.id}/site-positions`,
      )
      return res.data ? (res.data as ILookupType[]) : []
    }
    return []
  },
})

export const siteShiftsSelector = selector<ILookupType[]>({
  key: 'siteShiftsSelector',
  get: async ({ get }) => {
    let selectedSite = get(selectedSiteAtom)
    if (selectedSite) {
      const res = await customAxios.getAsync(
        `/api/Lookups/${selectedSite.id}/site-shifts`,
      )
      return res.data ? (res.data as ILookupType[]) : []
    }
    return []
  },
})
