import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '../../components/DialogContent'
import DialogActions from '../../components/DialogActions'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TitleField from '../../components/TitleField'
import customAxios from '../../services/customAxios'
import ContainedPrimaryButton from '../../components/ContainedPrimaryButton'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'

const useStyles = makeStyles((theme) => ({
  row: {
    padding: theme.spacing(1),
  },
  title: {
    marginTop: '8px',
  },
  button: {
    width: 90,
  },
}))
const RemoveConfirm = forwardRef((props: any, ref: any) => {
  const [open, setOpen] = useState(false)
  const [state, setState] = useState<any>()
  useImperativeHandle(ref, () => ({
    showDialog(details?: any) {
      setState(details)
      setOpen(true)
    },
  }))
  const classes = useStyles()
  const handleRemove = () => {
    const model = { ...state, isActive: false }
    customAxios
      .postAsync(`/api/SiteShifts/save-site-shift`, model)
      .then((res: any) => {
        if (props.refreshData) {
          props.refreshData()
          setOpen(false)
        }
      })
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
    >
      <form>
        <DialogContent dividers>
          <Grid container className={classes.row}>
            <TitleField gutterBottom className={classes.title}>
              Are you sure that you wish to remove this Site Shift Allocation?
            </TitleField>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box pt={1} pl={1}>
            <OutlinedSecondaryButton
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
            >
              No
            </OutlinedSecondaryButton>
          </Box>

          <Box pt={1} pl={1}>
            <ContainedPrimaryButton
              variant="contained"
              className={classes.button}
              onClick={handleRemove}
            >
              Yes
            </ContainedPrimaryButton>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
})

export default RemoveConfirm
