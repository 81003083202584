import React from 'react'
import NumberFormat from 'react-number-format'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
)

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
    />
  )
}

export default function NumberFormattInput({
  value,
  decimalScale,
  prefix,
  handleChange,
  inputRef,
  bold,
  disabled = false,
}: {
  value: string
  decimalScale: number
  prefix?: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  inputRef?: (instance: NumberFormat | null) => void
  bold?: boolean
  disabled?: boolean
}) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <TextField
        value={value}
        onChange={handleChange}
        disabled={disabled}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
          inputRef: inputRef,
          inputProps: {
            decimalScale: decimalScale,
            prefix: prefix,
            style: {
              fontWeight: bold ? 700 : 400,
            },
          },
        }}
      />
    </div>
  )
}
