import React from 'react'
import { TextField, Box, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { lookupsQuerySelector } from '../../store/selector'
import { useRecoilValue } from 'recoil'
import StyledAutoComplete from '../../components/StyledAutoComplete'
import {
  DataGrid,
  ColDef,
  ValueFormatterParams,
  SortModel,
  RowData,
  SelectionChangeParams,
} from '@material-ui/data-grid'
import { LookupTypesEnum } from '../../types/enums'
import { ILookupType, ISitePostion } from '../../types/interfaces'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'
import customAxios from '../../services/customAxios'
import SitePostionDialog from './SitePostionDialog'
import NewIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  containerGid: {
    width: '100%',
    height: 590,
  },
  gridRoot: {
    [theme.breakpoints.up('lg')]: {
      '& .MuiDataGrid-window': {
        overflowX: 'hidden',
      },
    },
  },
}))

interface IPagingModel {
  pageSize: number
  page: number
  sortModel: SortModel
}

const GridData = ({ site }: { site: ILookupType | null }) => {
  const classes = useStyles()

  const dialogRef = React.useRef<any>()

  const columns: ColDef[] = [
    { field: 'code', headerName: 'Code', width: 200 },
    { field: 'description', headerName: 'Description', width: 450 },
    {
      field: 'isActive',
      headerName: 'Status',
      width: 120,
      renderCell: (params: ValueFormatterParams) => {
        return (
          <Typography variant="body2">
            {params.data.isActive ? 'Active' : 'InActive'}
          </Typography>
        )
      },
    },
    {
      field: '',
      headerName: '',
      width: 150,
      renderCell: (params: ValueFormatterParams) => {
        const handleOnClick = () => {
          setCurrentSitePosition(params.data as ISitePostion)
          dialogRef.current.openDialog()
        }
        return (
          <OutlinedSecondaryButton
            size="small"
            variant="outlined"
            style={{ marginLeft: 16 }}
            onClick={handleOnClick}
          >
            Edit
          </OutlinedSecondaryButton>
        )
      },
    },
  ]

  const [sitePositions, setSitePositions] = React.useState<ISitePostion[]>([])

  const [pagingModel] = React.useState<IPagingModel>({
    pageSize: 100,
    page: 1,
    sortModel: [
      {
        field: 'code',
        sort: 'asc',
      },
    ],
  })

  const fetchSitePositions = async (site: ILookupType | null) => {
    if (site) {
      const res = await customAxios.getAsync(
        `/api/Lookups/${site.id}/site-positions?includeInActive=true`,
      )
      if (res.data) {
        setSitePositions(res.data as ISitePostion[])
      }
    }
  }

  React.useEffect(() => {
    fetchSitePositions(site)
  }, [site])

  const newSitePosition: ISitePostion = {
    id: 0,
    isActive: true,
    code: '',
    description: '',
    siteId: 0,
  }
  const [currentSitePosition, setCurrentSitePosition] = React.useState<
    ISitePostion
  >(newSitePosition)

  const handleAddNewClick = () => {
    if (site) {
      newSitePosition.siteId = site.id
      setCurrentSitePosition(newSitePosition)
      dialogRef.current.openDialog()
    }
  }

  const activate = 'Activate'
  const deactivate = 'Deactivate'
  const [disabled, setDisabled] = React.useState(true)
  const [text, setText] = React.useState(activate)
  const [selectedRows, setSelectedRows] = React.useState<RowData[]>([])

  React.useMemo(() => {
    if (selectedRows.length > 0) {
      let hasActive = selectedRows.findIndex((r) => r.isActive) !== -1
      let hasInActive = selectedRows.findIndex((r) => !r.isActive) !== -1
      if (hasActive && hasInActive) {
        setDisabled(true)
      } else if (hasActive) {
        setText(deactivate)
        setDisabled(false)
      } else {
        setText(activate)
        setDisabled(false)
      }
    } else {
      setDisabled(true)
    }
  }, [selectedRows, setDisabled, setText])

  const handleChangeStatus = async () => {
    let data = selectedRows.map((item) => {
      return { ...item, isActive: text === activate ? true : false }
    })
    let res = await customAxios.postAsync(
      `/api/Lookups/change-site-position-statues`,
      data,
    )
    if (res.data) {
      fetchSitePositions(site)
    }
  }
  return (
    <Box display="flex" flexDirection="column" className={classes.root} pt={2}>
      <Box className={classes.containerGid}>
        <DataGrid
          checkboxSelection
          rowHeight={40}
          pageSize={pagingModel.pageSize}
          page={pagingModel.page}
          sortModel={pagingModel.sortModel}
          sortingOrder={['desc', 'asc']}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          rows={sitePositions}
          columns={columns}
          className={classes.gridRoot}
          disableSelectionOnClick
          onSelectionChange={(e: SelectionChangeParams) => {
            setSelectedRows(e.rows)
          }}
        />
      </Box>
      <Box display="flex" mt={2}>
        <OutlinedSecondaryButton
          variant="outlined"
          onClick={handleAddNewClick}
          startIcon={<NewIcon />}
        >
          Add Site Position
        </OutlinedSecondaryButton>
        <Box ml={1}>
          <OutlinedSecondaryButton
            variant="outlined"
            onClick={handleChangeStatus}
            disabled={disabled}
          >
            {text}
          </OutlinedSecondaryButton>
        </Box>
      </Box>
      <SitePostionDialog
        ref={dialogRef}
        sitePosition={currentSitePosition}
        handleCancel={() => {}}
        handleSave={(sitePosition) => {
          fetchSitePositions(site)
        }}
      ></SitePostionDialog>
    </Box>
  )
}

const SitePositionsTab = () => {
  const classes = useStyles()

  const sites = useRecoilValue(lookupsQuerySelector(LookupTypesEnum.Site))

  const [selectedSite, setSelectedSite] = React.useState<ILookupType | null>(
    null,
  )

  React.useMemo(() => {
    if (sites && sites.length > 0) {
      setSelectedSite(sites[0])
    }
  }, [sites, setSelectedSite])

  return (
    <Box display="flex" flexDirection="column" className={classes.root} p={3}>
      <Box>
        <StyledAutoComplete
          size="small"
          options={sites}
          value={selectedSite}
          onChange={(event: any, newValue: any) => {
            setSelectedSite(newValue)
          }}
          getOptionSelected={(option: any, value: any) =>
            option.id === value.id
          }
          getOptionLabel={(option: any) => option.name}
          style={{ width: 300 }}
          renderInput={(params: any) => (
            <TextField {...params} label="Site" variant="outlined" />
          )}
        />
      </Box>
      <GridData site={selectedSite}></GridData>
    </Box>
  )
}

export default SitePositionsTab
