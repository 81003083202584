import { atom } from 'recoil'
import { ILookupType } from '../../types/interfaces'

export interface ILookups {
  [key: string]: ILookupType[]
}

export interface ISection1 {
  titleId?: number
  firstName?: string
  surName?: string
  preferredName?: string
  genderId?: number
  street?: string
  suburb?: string
  town?: string
  postcode?: string
  mobile?: string
  email?: string
  dateOfBirth?: Date | null
  emergencyFirstName?: string
  emergencySurName?: string
  emergencyRelationShipId?: number
  emergencyRelationShip?: ILookupType | null
  emergencyPhone?: string
}

export interface ISection2 {
  isEligible?: string
  workVisaId?: number
  showReceivingBenefit?: boolean
  showVisaExpiryDate?: boolean
  showSSEVisa?: boolean
  isReceivingBenefit?: string
  isSSEVisa?: string
  visaExpiryDate?: Date | null
  passportNo?: string
  nationalityId?: ILookupType | null
  ethnicityId?: ILookupType | null
  isFluentEnglishSpeaker?: string
}

export interface ISection3 {
  isFormerEmployee?: string
  employeeNo?: string
  recentEmployerName?: string
  recentEmployerPhone?: string
  leaveReason?: string
  preferredSiteShiftId?: number
  isCasualWorker?: boolean
  isAvailableForAllHours?: string
  availabilityDetail?: string
  preferredLocationId?: number
  selectedPreferredRoles?: ILookupType[]
  forkliftCertificateId?: number
}

export interface ISection4 {
  hasMedicalCondition?: string
  medicalConditionDetail?: string
  hasAggravatedCondition?: string
  aggravatedConditionDetail?: string
  requiresMedicine?: string
  requiresMedicineDetail?: string
  hasBackInjury?: string
  backInjuryDetail?: string
  hasAcc?: string
  accDetail?: string
  hasArrivedIntoNz?: string
  arrivedIntoNzDate?: Date | null
  hasInfectiousDisease?: string
  infectiousDiseaseDetail?: string
  hasTestConsent?: string
  testConsentDetail?: string,
  shareCovidVaccination?: string,
  covidStatus?: ILookupType | null
}

export interface ISection5 {
  hasCriminalConviction?: string
  criminalConvictionDetail?: string
  hasDrivingOffence?: string
  drivingOffenceDetail?: string
}

export interface ISection6 {
  irdNo?: string
  bankAccountNo?: string
  taxCodeId?: number
  selectedTaxeCode?: ILookupType | null
  isEnrolledInKiwiSaver?: string
  isContributingtoKiwiSaver?: string
  kiwisaverContributionPercentageId?: number
  selectedKiwisaverContribution?: ILookupType | null
}

export interface ISection7 {
  isConfirm?: boolean
  hasEmployees?: boolean
  jobReferralIds?: ILookupType[]
  referralEmployeeDetail?: string
}

// These are fine as default states all empty
const lookUpsAtom = atom<ILookups>({
  key: 'applicationForm#Lookups',
  default: {
    TaxCode: [],
    KiwisaverPercentage: [],
    JobReferral: [],
    Roles: [],
  },
})

const section1Atom = atom<ISection1>({
  key: 'applicationForm#Section1',
  default: {
    dateOfBirth: null,
    emergencyRelationShip: null,
  },
})

const section2Atom = atom<ISection2>({
  key: 'applicationForm#Section2',
  default: {
    visaExpiryDate: null,
    nationalityId: null,
    ethnicityId: null,
  },
})

const section3Atom = atom<ISection3>({
  key: 'applicationForm#Section3',
  default: {
    isCasualWorker: false,
    selectedPreferredRoles: [],
  },
})

const section4Atom = atom<ISection4>({
  key: 'applicationForm#Section4',
  default: {
    arrivedIntoNzDate: null,
  },
})

const section5Atom = atom<ISection5>({
  key: 'applicationForm#Section5',
  default: {},
})

const section6Atom = atom<ISection6>({
  key: 'applicationForm#Section6',
  default: {
    selectedTaxeCode: null,
    selectedKiwisaverContribution: null,
  },
})

const section7Atom = atom<ISection7>({
  key: 'applicationForm#Section7',
  default: {
    isConfirm: false,
    hasEmployees: false,
    jobReferralIds: [],
  },
})

export {
  lookUpsAtom,
  section1Atom,
  section2Atom,
  section3Atom,
  section4Atom,
  section5Atom,
  section6Atom,
  section7Atom,
}
