import { FormControl, FormLabel, Grid, TextField } from '@material-ui/core'
import debounce from '@material-ui/core/utils/debounce'
import * as React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useRecoilState } from 'recoil'
import * as Text from '../../assets/FormTextLabels.json'
import BooleanRadioGroup from '../../components/BooleanRadioGroup'
import ConditionalRender from '../../components/ConditionalRender'
import MaskedInputText from '../../components/MaskedInputText'
import RadioGroupList from '../../components/RadioGroupList'
import StepsButton from '../../components/StepsButton'
import StyledAutoComplete from '../../components/StyledAutoComplete'
import StyleTextField from '../../components/StyleTextField'
import TitleField from '../../components/TitleField'
import customAxios from '../../services/customAxios'
import { replaceMaskText } from '../../services/helpers'
import lookupService from '../../services/lookupService'
import { LookupTypesEnum } from '../../types/enums'
import { ILookupType } from '../../types/interfaces'
import { ILookups, ISection3, lookUpsAtom, section3Atom } from './state'

const Section3 = ({ next, back }: { next?: () => void; back?: () => void }) => {
  const [state, setState] = useRecoilState<ISection3>(section3Atom)
  const [focus] = React.useState(false)

  const { register, handleSubmit, setValue } = useForm<ISection3>({
    defaultValues: state,
  })
  const [lookUpsState, setlookUpsState] = useRecoilState<ILookups>(lookUpsAtom)
  let action: any
  const onSubmit: SubmitHandler<ISection3> = (data) => {
    const section3 = {
      ...data,
      forkliftCertificateId: Number(data.forkliftCertificateId),
      recentEmployerPhone: replaceMaskText(data.recentEmployerPhone),
    }

    setState({
      ...state,
      ...section3,
    })
    if (action) action()
  }
  const onChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setValue(e.target.name, e.target.value, { shouldDirty: true })
  }

  const autoCompleteChange = (name: string, value: any) => {
    setState({ ...state, [name]: value })
    setValue(name, value, { shouldDirty: true })
  }

  const onChangedSite = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setValue(e.target.name, e.target.value, { shouldDirty: true })
  }

  const onChangedShift = (e: any) => {
    const id = Number(e.target.value)
    const shift = shifts.find((x) => x.id === id)
    const showProvideDetails =
      shift &&
      (shift.name === 'Part-time / Casual' || shift.name === 'Weekends Only' || shift.name === 'Monday - Friday only')
        ? true
        : false

    setValue(e.target.name, e.target.value, { shouldDirty: true })
    setValue('isCasualWorker', showProvideDetails, { shouldDirty: true })
    setState({
      ...state,
      [e.target.name]: e.target.value,
      isCasualWorker: showProvideDetails,
    })
  }

  React.useEffect(() => {
    if (
      !lookUpsState[LookupTypesEnum.Site] ||
      lookUpsState[LookupTypesEnum.Site].length < 1
    ) {
      let lookups: [Promise<any>, Promise<any>, Promise<any>] = [
        lookupService.getLookup(LookupTypesEnum.Site, true, 'description'),
        lookupService.getLookup(LookupTypesEnum.Roles),
        lookupService.getLookup(LookupTypesEnum.ForkliftOSHCertificate),
      ]

      Promise.all(lookups).then((results: any[]) => {
        setlookUpsState({
          ...lookUpsState,
          [LookupTypesEnum.Site]: results[0],
          [LookupTypesEnum.Roles]: results[1],
          [LookupTypesEnum.ForkliftOSHCertificate]: results[2],
        })
      })
    }
  }, [lookUpsState, setlookUpsState])

  const { Site, Roles, ForkliftOSHCertificate } = lookUpsState
  const {
    isFormerEmployee,
    employeeNo,
    recentEmployerName,
    recentEmployerPhone,
    leaveReason,
    preferredSiteShiftId,
    isCasualWorker,
    isAvailableForAllHours,
    availabilityDetail,
    preferredLocationId,
    selectedPreferredRoles,
    forkliftCertificateId,
  } = state

  const [shifts, setShifts] = React.useState<ILookupType[]>([])
  React.useEffect(() => {
    if (preferredLocationId) {
      const debouncedSave = debounce(async () => {
        const res = await customAxios.getAsync(
          `/api/Lookups/${preferredLocationId}/site-shifts`,
        )
        setShifts(res.data ? (res.data as ILookupType[]) : [])
      }, 500)
      debouncedSave()
    }
  }, [preferredLocationId])

  return (
    <form className="form-step" onSubmit={handleSubmit(onSubmit)}>
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TitleField variant="h4" gutterBottom>
              {Text.section3.subtitle1}
            </TitleField>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section3.question01}
              </FormLabel>
              <BooleanRadioGroup
                name="isFormerEmployee"
                defaultValue={isFormerEmployee}
                inputRef={register}
                onChange={onChange}
              />
              <ConditionalRender condition={isFormerEmployee === 'true'}>
                <FormControl component="fieldset" fullWidth>
                  <FormControl>
                    <StyleTextField
                      label={Text.section3.question02}
                      onChange={onChange}
                      defaultValue={employeeNo}
                      name="employeeNo"
                      InputLabelProps={{
                        shrink: employeeNo || focus ? true : false,
                      }}
                      InputProps={{
                        inputComponent: MaskedInputText as any,
                        inputProps: {
                          inputRef: () => register('employeeNo'),
                          minLength: 4,
                          maskInput: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
                        },
                      }}
                    />
                  </FormControl>
                </FormControl>
              </ConditionalRender>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyleTextField
              name="recentEmployerName"
              label={Text.section3.question03}
              inputRef={register}
              defaultValue={recentEmployerName}
              onChange={onChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyleTextField
              name="recentEmployerPhone"
              label={Text.section3.question04}
              onChange={onChange}
              inputRef={register}
              defaultValue={recentEmployerPhone}
              InputLabelProps={{
                shrink: recentEmployerPhone || focus ? true : false,
              }}
              InputProps={{
                inputComponent: MaskedInputText as any,
                inputProps: {
                  minLength: 9,
                  inputRef: () => register('recentEmployerPhone'),
                  maskInput: [
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ],
                },
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <StyleTextField
              name="leaveReason"
              label={Text.section3.question05}
              inputRef={register}
              defaultValue={leaveReason}
              fullWidth
              multiline={true}
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TitleField variant="h4" gutterBottom>
              {Text.section3.subtitle2}
            </TitleField>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section3.question06}
              </FormLabel>
              <RadioGroupList
                name="preferredLocationId"
                showDescriptionOnly
                list={Site}
                checkedValue={preferredLocationId}
                inputRef={register}
                onChange={onChangedSite}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{ __html: Text.section3.blurb }}></p>
          </Grid>
          <ConditionalRender condition={preferredLocationId !== undefined}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" required>
                  {Text.section3.question07}
                </FormLabel>
                <RadioGroupList
                  name="preferredSiteShiftId"
                  showDescriptionAlso
                  list={shifts}
                  checkedValue={preferredSiteShiftId}
                  inputRef={register}
                  onChange={onChangedShift}
                />
              </FormControl>
            </Grid>
          </ConditionalRender>

          <ConditionalRender condition={isCasualWorker === false}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" required>
                  {Text.section3.question08}
                </FormLabel>
                <BooleanRadioGroup
                  name="isAvailableForAllHours"
                  defaultValue={isAvailableForAllHours}
                  inputRef={register}
                  onChange={onChange}
                />
                <ConditionalRender
                  condition={isAvailableForAllHours === 'false'}
                >
                  <StyleTextField
                    required
                    name="availabilityDetail"
                    label={Text.section3.question09}
                    inputRef={register}
                    onChange={onChange}
                    defaultValue={availabilityDetail}
                    fullWidth
                    multiline={true}
                  />
                </ConditionalRender>
              </FormControl>
            </Grid>
          </ConditionalRender>

          <ConditionalRender condition={isCasualWorker === true}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <StyleTextField
                  required
                  name="availabilityDetail"
                  label={Text.section3.question09}
                  inputRef={register}
                  onChange={onChange}
                  defaultValue={availabilityDetail}
                  fullWidth
                  multiline={true}
                />
              </FormControl>
            </Grid>
          </ConditionalRender>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <StyledAutoComplete
                aria-required
                multiple
                size="small"
                options={Roles}
                getOptionLabel={(option: any) => option.name}
                getOptionSelected={(option: any, value: any) =>
                  option.id === value.id
                }
                value={selectedPreferredRoles}
                ref={() => register('selectedPreferredRoles')}
                onChange={(event: any, newValue: any) =>
                  autoCompleteChange('selectedPreferredRoles', newValue)
                }
                ListboxProps={{ style: { maxHeight: '15rem' } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    label={Text.section3.question10}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <ConditionalRender
            condition={
              selectedPreferredRoles &&
              selectedPreferredRoles.findIndex((x: ILookupType) => {
                return x.name === 'Forklift Driver'
              }) >= 0
            }
          >
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" required>
                  {Text.section3.question11}
                </FormLabel>
                <RadioGroupList
                  name="forkliftCertificateId"
                  list={ForkliftOSHCertificate}
                  checkedValue={forkliftCertificateId}
                  inputRef={register}
                  onChange={onChange}
                />
              </FormControl>
            </Grid>
          </ConditionalRender>
        </Grid>
      </React.Fragment>

      <StepsButton onBack={back} onNext={() => (action = next)} />
    </form>
  )
}

export default Section3
