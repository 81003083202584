import {
  Box,
  CssBaseline,
  Grid,
  Link,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import * as React from 'react'
import TagManager from 'react-gtm-module'
import { useRecoilState } from 'recoil'
import * as Text from '../../assets/FormTextLabels.json'
import EastPackLogo from '../../assets/logo.png'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'
import customAxios from '../../services/customAxios'
import { calculateAge } from '../../services/helpers'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Section6 from './Section6'
import Section7 from './Section7'
import {
  section1Atom,
  section2Atom,
  section3Atom,
  section4Atom,
  section5Atom,
  section6Atom,
  section7Atom,
} from './state'
import './styles.css'

interface IApplicationFormState {
  stepIndex: number
  isIneligible: boolean
  steps: string[]
}

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.eastpack.co.nz">
        EastPack
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(0, 2, 2, 2),
    width: '100%',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
    },
  },
  gridItem: {
    width: 1000,
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  stepper: {
    padding: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
}))

const StepRenderer = ({
  stepIndex,
  isIneligible,
  steps,
  next,
  back,
  // handleChange,
  checkRejectRule,
  submit,
}: {
  stepIndex: number
  isIneligible: boolean
  steps: string[]
  next?: () => void
  back?: () => void
  // handleChange?: (event: React.ChangeEvent<{}>, newValue: number) => void
  checkRejectRule?: () => void
  submit?: () => void
}) => {
  const classes = useStyles()
  // const ref = React.useRef<TabsActions>(null)
  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <React.Fragment>
          {isIneligible ? (
            <Grid>
              <Typography
                variant="subtitle1"
                dangerouslySetInnerHTML={{ __html: Text.autoRejectionForm }}
              />
              <Box>
                <OutlinedSecondaryButton
                  variant="outlined"
                  onClick={(event) =>
                    (window.location.href = 'https://www.eastpack.co.nz/')
                  }
                >
                  Home
                </OutlinedSecondaryButton>
              </Box>
            </Grid>
          ) : stepIndex === steps.length ? (
            <Grid>
              <Typography variant="h5" gutterBottom>
                All Done
              </Typography>
              <Typography
                variant="subtitle1"
                dangerouslySetInnerHTML={{ __html: Text.applicationForm }}
              />
            </Grid>
          ) : (
            <Grid>
              <React.Suspense
                fallback={
                  <div style={{ display: 'flex', fontSize: 20 }}>
                    {' '}
                    Loading...
                  </div>
                }
              >
                {stepIndex === 0 && (
                  <Section1 next={next} checkRejectRule={checkRejectRule} />
                )}
                {stepIndex === 1 && (
                  <Section2
                    next={next}
                    back={back}
                    checkRejectRule={checkRejectRule}
                  />
                )}
                {stepIndex === 2 && <Section3 next={next} back={back} />}
                {stepIndex === 3 && <Section4 next={next} back={back} />}
                {stepIndex === 4 && <Section5 next={next} back={back} />}
                {stepIndex === 5 && <Section6 next={next} back={back} />}
                {stepIndex === 6 && <Section7 submit={submit} back={back} />}
              </React.Suspense>
            </Grid>
          )}
        </React.Fragment>
        <Copyright />
      </main>
    </React.Fragment>
  )
}
const ApplicationForm = () => {
  const [state, setState] = React.useState<IApplicationFormState>({
    stepIndex: 0,
    isIneligible: false,
    steps: [
      'Personal Details',
      'Working in NZ',
      'Working at EastPack',
      'Medical',
      'Criminal History',
      'Payroll Information',
      'Declaration & Consent',
    ],
  })
  const [section1State] = useRecoilState(section1Atom)
  const [section2State] = useRecoilState(section2Atom)
  const [section3State] = useRecoilState(section3Atom)
  const [section4State] = useRecoilState(section4Atom)
  const [section5State] = useRecoilState(section5Atom)
  const [section6State] = useRecoilState(section6Atom)
  const [section7State] = useRecoilState(section7Atom)

  // Proceed to next section
  const nextStep = () => {
    // Set state hook
    // Need to create a "new" state from old one using spread {...}
    // i.e immutable state
    setState({
      ...state,
      stepIndex: state.stepIndex + 1,
    })
    TagManager.dataLayer(tagManagerArgs)
  }

  // Go back to previous section
  const prevStep = () => {
    setState({
      ...state,
      stepIndex: state.stepIndex - 1,
    })
  }

  const checkRejectRule = () => {
    const isIneligible =
      !section1State.dateOfBirth ||
      !calculateAge(section1State.dateOfBirth) ||
      section2State.isEligible === 'false' ||
      section2State.isFluentEnglishSpeaker === 'false'
    setState({
      ...state,
      isIneligible,
    })
    return isIneligible
  }

  const submit = () => {
    const data = {
      titleId: section1State.titleId,
      firstName: section1State.firstName,
      surName: section1State.surName,
      preferredName: section1State.preferredName,
      genderId: section1State.genderId,
      street: section1State.street,
      suburb: section1State.suburb,
      town: section1State.town,
      postcode: section1State.postcode,
      mobile: section1State.mobile,
      email: section1State.email,
      dateOfBirth: section1State.dateOfBirth?.toDateString(),
      emergencyFirstName: section1State.emergencyFirstName,
      emergencySurName: section1State.emergencySurName,
      emergencyRelationShipId: section1State.emergencyRelationShipId,
      emergencyPhone: section1State.emergencyPhone,
      //Section 2
      isEligible: section2State.isEligible,
      workVisaId: section2State.workVisaId,
      isReceivingBenefit: section2State.isReceivingBenefit,
      isSSEVisa: section2State.isSSEVisa,
      visaExpiryDate: section2State.visaExpiryDate?.toDateString(),
      passportNo: section2State.passportNo,
      nationalityId: section2State.nationalityId?.id,
      ethnicityId: section2State.ethnicityId?.id,
      isFluentEnglishSpeaker: section2State.isFluentEnglishSpeaker,
      //section 3
      isFormerEmployee: section3State.isFormerEmployee,
      employeeNo: section3State.employeeNo,
      recentEmployerName: section3State.recentEmployerName,
      recentEmployerPhone: section3State.recentEmployerPhone,
      leaveReason: section3State.leaveReason,
      preferredSiteShiftId: section3State.preferredSiteShiftId,
      isAvailableForAllHours: section3State.isAvailableForAllHours,
      availabilityDetail: section3State.availabilityDetail,
      preferredLocationId: section3State.preferredLocationId,
      preferredRoleValue: section3State.selectedPreferredRoles
        ?.map((elem: any) => {
          return elem.id
        })
        .join(';'),
      forkliftCertificateId: section3State.forkliftCertificateId,
      //section 4
      hasMedicalCondition: section4State.hasMedicalCondition,
      medicalConditionDetail: section4State.medicalConditionDetail,
      hasAggravatedCondition: section4State.hasAggravatedCondition,
      aggravatedConditionDetail: section4State.aggravatedConditionDetail,
      requiresMedicine: section4State.requiresMedicine,
      requiresMedicineDetail: section4State.requiresMedicineDetail,
      hasBackInjury: section4State.hasBackInjury,
      backInjuryDetail: section4State.backInjuryDetail,
      hasAcc: section4State.hasAcc,
      accDetail: section4State.accDetail,
      hasArrivedIntoNz: section4State.hasArrivedIntoNz,
      arrivedIntoNzDate: section4State.arrivedIntoNzDate?.toDateString(),
      hasInfectiousDisease: section4State.hasInfectiousDisease,
      infectiousDiseaseDetail: section4State.infectiousDiseaseDetail,
      hasTestConsent: section4State.hasTestConsent,
      testConsentDetail: section4State.testConsentDetail,
      shareCovidVaccination: section4State.shareCovidVaccination,
      covidStatus: section4State.covidStatus?.name,
      //section 5
      hasCriminalConviction: section5State.hasCriminalConviction,
      criminalConvictionDetail: section5State.criminalConvictionDetail,
      hasDrivingOffence: section5State.hasDrivingOffence,
      drivingOffenceDetail: section5State.drivingOffenceDetail,
      //section 6
      irdNo: section6State.irdNo,
      bankAccountNo: section6State.bankAccountNo,
      taxCodeId: section6State.taxCodeId,
      isEnrolledInKiwiSaver: section6State.isEnrolledInKiwiSaver,
      isContributingtoKiwiSaver: section6State.isContributingtoKiwiSaver,
      kiwisaverContributionPercentageId:
        section6State.kiwisaverContributionPercentageId,

      jobReferralValue: section7State.jobReferralIds
        ?.map((elem: any) => {
          return elem.id
        })
        .join(';'),
      referralEmployeeDetail: section7State.referralEmployeeDetail,
    }

    customAxios.postAsync(`/api/Applicants`, data).then((res: any) => {
      setState({
        ...state,
        stepIndex: state.stepIndex + 1,
      })
    })
  }

  const { stepIndex, isIneligible, steps } = state
  const classes = useStyles()
  const tagManagerArgs = {
    dataLayer: {
      event: 'StepDataLayer',
      stepNumber: { stepIndex },
    },
    dataLayerName: 'StepDataLayer',
  }
  return (
    <Paper className={classes.paper}>
      <Grid className={classes.gridItem}>
        <a id="logo" href="https://www.eastpack.co.nz/">
          <img src={EastPackLogo} alt="EastPack Logo" />
        </a>
      </Grid>
      <Grid className={classes.gridItem}>
        <h1
          style={{
            textAlign: 'left',
            padding: 15,
            color: '#78BE20',
            fontSize: '35px',
            lineHeight: 1,
            borderBottom: '1px solid rgba(0,0,0,0.20)',
          }}
        >
          Seasonal Work Application Form
        </h1>
      </Grid>
      <Grid className={classes.gridItem}>
        <StepRenderer
          stepIndex={stepIndex}
          isIneligible={isIneligible}
          steps={steps}
          next={nextStep}
          back={prevStep}
          // handleChange={handleChange}
          checkRejectRule={checkRejectRule}
          submit={submit}
        />
      </Grid>
    </Paper>
  )
}

export default ApplicationForm
