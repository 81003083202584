import withStyles from '@material-ui/core/styles/withStyles'
import { Autocomplete } from '@material-ui/lab'

const StyledAutoComplete = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 700,
  },
  input: {
    fontSize: 14,
    fontWeight: 700,
  },
  option: {
    fontSize: 14,
    fontWeight: 700,
  },
})(Autocomplete)

export default StyledAutoComplete
