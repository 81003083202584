import React, { Fragment } from 'react'
import { authProvider } from '../../authProvider'
import {
  AzureAD,
  AuthenticationState,
  IAzureADFunctionProps,
} from 'react-aad-msal'

import AuthorizeUser from './AuthorizeUser'
import { IUserRoleInfo } from '../../types/interfaces'
import ApplicantList from '../ApplicantList'
import ErrorMessage from '../../components/ErrorMessage'
import LoadingBar from '../../components/LoadingBar'
import { RoleNames } from '../../types/enums'
import * as Text from '../../assets/FormTextLabels.json'

const Admin = () => {
  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      {({ authenticationState }: IAzureADFunctionProps) => {
        let content
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            content = (
              <AuthorizeUser>
                {({ roles, siteId }: IUserRoleInfo) => {
                  if (roles) {
                    if (roles.indexOf(RoleNames.CentralHR) !== -1) {
                      return <ApplicantList></ApplicantList>
                    } else if (
                      roles.indexOf(RoleNames.SiteHR) !== -1 &&
                      siteId
                    ) {
                      return <ApplicantList siteId={siteId}></ApplicantList>
                    }
                  }
                  return (
                    <ErrorMessage
                      text={Text.messages.unauthorized}
                    ></ErrorMessage>
                  )
                }}
              </AuthorizeUser>
            )
            break
          case AuthenticationState.Unauthenticated:
            content = (
              <ErrorMessage text={Text.messages.unauthenticated}></ErrorMessage>
            )
            break
          case AuthenticationState.InProgress:
            content = (
              <LoadingBar text={Text.messages.authenticating}></LoadingBar>
            )
            break
        }

        return <Fragment>{content}</Fragment>
      }}
    </AzureAD>
  )
}

export default Admin
