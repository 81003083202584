import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useRecoilState } from 'recoil'
import * as Text from '../../assets/FormTextLabels.json'
import LocalKeyboardDatePicker from '../../components/LocalKeyboardDatePicker'
import MaskedInputText from '../../components/MaskedInputText'
import RadioGroupList from '../../components/RadioGroupList'
import StepsButton from '../../components/StepsButton'
import StyledAutoComplete from '../../components/StyledAutoComplete'
import StyleTextField from '../../components/StyleTextField'
import TitleField from '../../components/TitleField'
import { replaceMaskText } from '../../services/helpers'
import lookupService from '../../services/lookupService'
import { LookupTypesEnum } from '../../types/enums'
import { ILookups, ISection1, lookUpsAtom, section1Atom } from './state'

interface IFormInputs {
  LocalKeyboardDatePicker: Date
}

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    '& .MuiFormControl-marginNormal': {
      marginTop: theme.spacing(0),
    },
    '& .MuiFormLabel-root': {
      color: '#D8D8D8',
    },
    '& label.Mui-focused': {
      color: '#78BE20',
    },
  },
}))

export const Section1 = ({
  next,
  checkRejectRule,
}: {
  next?: () => void
  checkRejectRule?: () => void
}) => {
  const classes = useStyles()
  const [focusMobile, setFocusMobile] = React.useState(false)
  const [focus, setFocus] = React.useState(false)
  const [lookUpsState, setlookUpsState] = useRecoilState<ILookups>(lookUpsAtom)
  const [state, setState] = useRecoilState<ISection1>(section1Atom)
  const { register, handleSubmit, setValue } = useForm<ISection1>({
    defaultValues: state,
    mode: 'all',
  })
  let action: any
  const onSubmit: SubmitHandler<ISection1> = (data) => {
    const section1 = {
      ...data,
      titleId: Number(data.titleId),
      emergencyRelationShipId: data.emergencyRelationShip?.id,
      mobile: replaceMaskText(data.mobile),
      emergencyPhone: replaceMaskText(data.emergencyPhone),
    }

    setState({
      ...state,
      ...section1,
    })
    const isReject = checkRejectRule && checkRejectRule()
    if (action && !isReject) action()
  }

  React.useEffect(() => {
    if (
      !lookUpsState[LookupTypesEnum.Gender] ||
      lookUpsState[LookupTypesEnum.Gender].length < 1
    ) {
      let lookups: [Promise<any>, Promise<any>, Promise<any>] = [
        lookupService.getLookup(LookupTypesEnum.Gender),
        lookupService.getLookup(LookupTypesEnum.Title),
        lookupService.getLookup(LookupTypesEnum.RelationshipToYou),
      ]

      Promise.all(lookups).then((results: any[]) => {
        setlookUpsState({
          ...lookUpsState,
          [LookupTypesEnum.Gender]: results[0],
          [LookupTypesEnum.Title]: results[1],
          [LookupTypesEnum.RelationshipToYou]: results[2],
        })
      })
    }
  }, [lookUpsState, setlookUpsState])

  const onChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setValue(e.target.name, e.target.value, { shouldDirty: true })
  }
  const autoCompleteChange = (name: string, value: any) => {
    setState({ ...state, [name]: value })
    setValue(name, value, { shouldDirty: true })
  }

  const handleChangeAge = (value: any) => {
    setState({ ...state, dateOfBirth: value })
    setValue('dateOfBirth', value, { shouldDirty: true })
  }

  const handleChangeInputNumber = (e: any) => {
    const val = e.target.value
    const currentLength = val.length
    const maxLength = 4
    const newVal =
      currentLength < maxLength ? val : val.toString().substring(0, maxLength)
    setState({ ...state, [e.target.name]: newVal })
    setValue(e.target.name, newVal, { shouldDirty: true })
  }
  const {
    titleId,
    firstName,
    surName,
    preferredName,
    genderId,
    street,
    suburb,
    town,
    postcode,
    mobile,
    email,
    dateOfBirth,
    emergencyFirstName,
    emergencySurName,
    emergencyRelationShip,
    emergencyPhone,
  } = state

  const { Title, Gender, RelationshipToYou } = lookUpsState
  const boldStyle = { fontFamily: 'Gilroy-ExtraBold' }

  return (
    <form className="form-step" onSubmit={handleSubmit(onSubmit)}>
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p>
              {Text.section1.blurb1}
              <span style={boldStyle}> {Text.section1.blurb2}</span>{' '}
              {Text.section1.blurb3}
            </p>
          </Grid>
          <Grid item xs={12}>
            <TitleField variant="h4" gutterBottom>
              {Text.section1.subtitle1}
            </TitleField>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section1.question01}
              </FormLabel>
              <RadioGroupList
                name="titleId"
                list={Title}
                checkedValue={titleId}
                inputRef={register}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyleTextField
              required
              name="firstName"
              label={Text.section1.question02}
              inputRef={register}
              onChange={onChange}
              defaultValue={firstName}
              inputProps={{ maxLength: 30 }}
              fullWidth
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyleTextField
              required
              name="surName"
              label={Text.section1.question03}
              inputRef={register}
              onChange={onChange}
              defaultValue={surName}
              inputProps={{ maxLength: 30 }}
              fullWidth
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyleTextField
              name="preferredName"
              label={Text.section1.question04}
              inputRef={register}
              onChange={onChange}
              defaultValue={preferredName}
              inputProps={{ maxLength: 30 }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section1.question05}
              </FormLabel>
              <RadioGroupList
                name="genderId"
                list={Gender}
                checkedValue={genderId}
                inputRef={register}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend" required>
              Address
            </FormLabel>
            <p>{Text.section1.addressHelp}</p>
          </Grid>
          <Grid item xs={12}>
            <StyleTextField
              required
              name="street"
              label={Text.section1.question06}
              inputRef={register}
              onChange={onChange}
              defaultValue={street}
              inputProps={{ maxLength: 25 }}
              fullWidth
              autoComplete="address-line1"
            />
          </Grid>
          <Grid item xs={12}>
            <StyleTextField
              required
              name="suburb"
              label={Text.section1.question07}
              inputRef={register}
              onChange={onChange}
              defaultValue={suburb}
              inputProps={{ maxLength: 30 }}
              fullWidth
              autoComplete="address-line2"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyleTextField
              required
              name="town"
              label={Text.section1.question08}
              inputRef={register}
              onChange={onChange}
              defaultValue={town}
              inputProps={{ maxLength: 30 }}
              fullWidth
              autoComplete="address-level2"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyleTextField
              name="postcode"
              label={Text.section1.question09}
              inputRef={register}
              defaultValue={postcode}
              fullWidth
              onChange={handleChangeInputNumber}
              autoComplete="postal-code"
              InputLabelProps={{
                shrink: postcode || focus ? true : false,
              }}
              InputProps={{
                inputComponent: MaskedInputText as any,
                inputProps: {
                  inputRef: () => register('postcode'),
                  minLength: 4,
                  maskInput: [/\d/, /\d/, /\d/, /\d/],
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <StyleTextField
                required
                label={Text.section1.question10}
                defaultValue={mobile}
                onChange={onChange}
                onFocus={() => setFocusMobile(true)}
                onBlur={() => setFocusMobile(false)}
                InputLabelProps={{
                  shrink: mobile || focusMobile ? true : false,
                }}
                name="mobile"
                InputProps={{
                  inputComponent: MaskedInputText as any,
                  inputProps: {
                    inputRef: () => register('mobile'),
                    minLength: 9,
                    maskInput: [
                      '(',
                      /[0]/,
                      /[2]/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ],
                  },
                }}
              />
              <FormHelperText>{Text.section1.question10Helper}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <StyleTextField
              name="email"
              label={Text.section1.question11}
              inputRef={register}
              defaultValue={email}
              type="email"
              placeholder="example@example.com"
              onChange={onChange}
              fullWidth
              autoComplete="email"
              required
            />
            <FormHelperText>{Text.section1.question11Helper}</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="legend" required>
              {Text.section1.question12}
            </FormLabel>
            <LocalKeyboardDatePicker
              required
              name="dateOfBirth"
              inputRef={register}
              value={dateOfBirth}
              handleDateChange={(date: Date) => {
                handleChangeAge(date)
              }}
              disableToolBar={false}
            />
            <FormHelperText>{Text.section1.question12Helper}</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TitleField variant="h4" gutterBottom>
              {Text.section1.subtitle2}
            </TitleField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyleTextField
              name="emergencyFirstName"
              label={Text.section1.question13}
              inputRef={register}
              onChange={onChange}
              defaultValue={emergencyFirstName}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyleTextField
              name="emergencySurName"
              label={Text.section1.question14}
              inputRef={register}
              onChange={onChange}
              defaultValue={emergencySurName}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {RelationshipToYou && (
              <StyledAutoComplete
                className={classes.autoComplete}
                options={RelationshipToYou}
                getOptionLabel={(option: any) => option.name}
                getOptionSelected={(option: any, value: any) =>
                  option.id === value.id
                }
                value={emergencyRelationShip}
                ref={() => register('emergencyRelationShip')}
                onChange={(event: any, newValue: any) =>
                  autoCompleteChange('emergencyRelationShip', newValue)
                }
                ListboxProps={{ style: { maxHeight: '15rem' } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Text.section1.question15}
                    margin="normal"
                    required
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <StyleTextField
                required
                label={Text.section1.question16}
                defaultValue={emergencyPhone}
                onChange={onChange}
                name="emergencyPhone"
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                InputLabelProps={{
                  shrink: emergencyPhone || focus ? true : false,
                }}
                InputProps={{
                  inputComponent: MaskedInputText as any,
                  inputProps: {
                    minLength: 9,
                    inputRef: () => register('emergencyPhone'),
                    maskInput: [
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ],
                  },
                }}
              />
              <FormHelperText>{Text.section1.question16Helper}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <p></p>
          </Grid>
        </Grid>
      </React.Fragment>
      <StepsButton hiddenBack={true} onNext={() => (action = next)} />
    </form>
  )
}

export default Section1
