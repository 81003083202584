import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { ILookupType } from '../types/interfaces'

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    color: '#212529',
  },
}))

const RadioGroupList = ({
  name,
  list,
  checkedValue,
  showDescriptionAlso,
  showDescriptionOnly,
  inputRef,
  onChange,
}: {
  name: string
  list: ILookupType[]
  checkedValue?: number
  showDescriptionAlso?: boolean
  showDescriptionOnly?: boolean
  inputRef?: React.Ref<any>
  onChange?: (target: any) => void
}) => {
  const classes = useStyles()
  return (
    <RadioGroup name={name} aria-label={name} row onChange={onChange}>
      {list &&
        list.map((item: ILookupType) => {
          const label = showDescriptionOnly
            ? `${item.description}`
            : `${item.name}${
                showDescriptionAlso && item.description
                  ? ' (' + item.description + ')'
                  : ''
              }`
          return (
            <FormControlLabel
              key={item.id}
              value={item.id.toString()}
              inputRef={inputRef}
              control={
                <Radio required checked={item.id === Number(checkedValue)} />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  {label}
                </Typography>
              }
            />
          )
        })}
    </RadioGroup>
  )
}

export default RadioGroupList
