import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useRecoilValue } from 'recoil'
import { selectedApplicantAtom } from './store/atom'
import LabelValueCellWithDetail from './LabelValueCellWithDetail'
import * as Text from '../../assets/FormTextLabels.json'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
}))

const CriminalTab = () => {
  const classes = useStyles()

  const applicantData = useRecoilValue(selectedApplicantAtom)

  return (
    <Box className={classes.root}>
      <Box display="flex" flexGrow={1} flexDirection="column">
        <LabelValueCellWithDetail
          label={Text.section5.question01}
          value={applicantData?.hasCriminalConviction}
          details={applicantData?.criminalConvictionDetail}
        ></LabelValueCellWithDetail>

        <LabelValueCellWithDetail
          label={Text.section5.question03}
          value={applicantData?.hasDrivingOffence}
          details={applicantData?.drivingOffenceDetail}
        ></LabelValueCellWithDetail>
      </Box>
    </Box>
  )
}

export default CriminalTab
