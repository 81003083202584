import React from 'react'
import { Typography, Box, CircularProgress } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import InfoOutlined from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: '20px 20px 20px 20px',
    borderRadius: 2,
    background: theme.palette.background.paper,
  },
  alert: {
    flexGrow: 1,
    borderRadius: 4,
  },
  circular: {
    color: theme.palette.secondary.dark,
    marginLeft: 10,
  },
}))

const LoadingBar = ({ text }: { text?: string }) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexGrow={1} className={classes.root}>
      <Alert
        variant="outlined"
        icon={<InfoOutlined fontSize="large" />}
        severity="info"
        className={classes.alert}
      >
        <Box display="flex" flexGrow={1}>
          <Typography variant="h6">{text}</Typography>
          <CircularProgress
            className={classes.circular}
            size={25}
          ></CircularProgress>
        </Box>
      </Alert>
    </Box>
  )
}

export default LoadingBar
