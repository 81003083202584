import { TextField } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const StyleTextField = withStyles({
  root: {
    '& .MuiFormLabel-root': {
      color: '#D8D8D8',
    },
    '& label.Mui-focused': {
      color: '#78BE20',
    },
  },
})(TextField)

export default StyleTextField
