import moment from 'moment'
import internal from 'stream'

export const replaceMaskText = (value?: string) => {
  return value?.replace(/-| |\(|\)/g, '')
}
export const convertToString = (value?: any) => {
  return value ? 'Yes' : 'No'
}

export const sortOrder = (a: any, b: any) => {
  if (a.sortOrder < b.sortOrder) {
    return -1
  }
  if (a.sortOrder > b.sortOrder) {
    return 1
  }
  return 0
}

export const compareName = (a: any, b: any) => {
  var nameA = a.name.toUpperCase()
  var nameB = b.name.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}
export const calculateAge = (birthday: Date) => {
  const today = new Date()
  const birthDate = new Date(birthday)
  var ageNow = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    ageNow--
  }
  return ageNow >= 15
}
export const toLocalDate = (UtcString: any) => {
  if (UtcString !== undefined) {
    return moment.utc(UtcString).local().format('DD MMM YYYY')
  }
}
export const toLocalDateTime = (UtcString: any) => {
  if (UtcString !== undefined) {
    return moment.utc(UtcString).local().format('DD MMM YYYY hh:mm a')
  }
}
