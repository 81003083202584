import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ILookups, lookUpsAtom, ISection4, section4Atom } from './state'
import { useTheme } from '@material-ui/core/styles'
import StepsButton from '../../components/StepsButton'
import {
  FormControl,
  FormLabel,
  Grid,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import BooleanRadioGroup from '../../components/BooleanRadioGroup'
import { useRecoilState } from 'recoil'
import ConditionalRender from '../../components/ConditionalRender'
import StyleTextField from '../../components/StyleTextField'
import TitleField from '../../components/TitleField'
import * as Text from '../../assets/FormTextLabels.json'
import LocalKeyboardDatePicker from '../../components/LocalKeyboardDatePicker'
import { LookupTypesEnum } from '../../types/enums'
import lookupService from '../../services/lookupService'
import StyledAutoComplete from '../../components/StyledAutoComplete'

const Section4 = ({ next, back }: { next?: () => void; back?: () => void }) => {
  const { register, handleSubmit, setValue } = useForm<ISection4>()
  const [state, setState] = useRecoilState<ISection4>(section4Atom)
  const [lookUpsState, setlookUpsState] = useRecoilState<ILookups>(lookUpsAtom)
  let action: any

  const onSubmit: SubmitHandler<ISection4> = (data) => {
    const section4 = {
      ...data,
      shareCovidVaccination:
        data.shareCovidVaccination == null || data.shareCovidVaccination == ''
          ? 'false'
          : data.shareCovidVaccination,
    }

    setState({
      ...state,
      ...section4,
    })
    if (action) action()
  }

  const handleArrivedIntoNzDateChange = (value: any) => {
    setState({ ...state, arrivedIntoNzDate: value })
    setValue('arrivedIntoNzDate', value, { shouldDirty: true })
  }

  const onChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setValue(e.target.name, e.target.value, { shouldDirty: true })
  }

  const autoCompleteChange = (name: string, value: any) => {
    setState({ ...state, [name]: value })
    setValue(name, value, { shouldDirty: true })
  }

  React.useEffect(() => {
    if (
      !lookUpsState[LookupTypesEnum.CovidOptions] ||
      lookUpsState[LookupTypesEnum.CovidOptions].length < 1
    ) {
      let lookups: [Promise<any>] = [
        lookupService.getLookup(
          LookupTypesEnum.CovidOptions,
          false,
          'SortOrder',
        ),
      ]

      Promise.all(lookups).then((results: any[]) => {
        setlookUpsState({
          ...lookUpsState,
          [LookupTypesEnum.CovidOptions]: results[0],
        })
      })
    }
  }, [lookUpsState, setlookUpsState])

  const {
    hasMedicalCondition,
    medicalConditionDetail,
    hasAggravatedCondition,
    aggravatedConditionDetail,
    requiresMedicine,
    requiresMedicineDetail,
    hasBackInjury,
    backInjuryDetail,
    hasAcc,
    accDetail,
    hasArrivedIntoNz,
    arrivedIntoNzDate,
    hasInfectiousDisease,
    infectiousDiseaseDetail,
    hasTestConsent,
    testConsentDetail,
    shareCovidVaccination,
    covidStatus,
  } = state

  const { CovidOptions } = lookUpsState

  const mobilePlaceholder = useMediaQuery(useTheme().breakpoints.down('xs'))
    ? Text.section2.mobileHelper
    : ''

  return (
    <form className="form-step" onSubmit={handleSubmit(onSubmit)}>
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TitleField variant="h4" gutterBottom>
              {Text.section4.subtitle1}
            </TitleField>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section4.question01}
              </FormLabel>
              <BooleanRadioGroup
                name="hasMedicalCondition"
                defaultValue={hasMedicalCondition}
                inputRef={register}
                onChange={onChange}
              />
              <ConditionalRender condition={hasMedicalCondition === 'true'}>
                <StyleTextField
                  required
                  name="medicalConditionDetail"
                  label={Text.section4.question02}
                  inputRef={register}
                  onChange={onChange}
                  defaultValue={medicalConditionDetail}
                  fullWidth
                  multiline={true}
                />
              </ConditionalRender>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section4.question03}
              </FormLabel>
              <BooleanRadioGroup
                name="hasAggravatedCondition"
                defaultValue={hasAggravatedCondition}
                inputRef={register}
                onChange={onChange}
              />
              <ConditionalRender condition={hasAggravatedCondition === 'true'}>
                <StyleTextField
                  required
                  name="aggravatedConditionDetail"
                  label={Text.section4.question04}
                  inputRef={register}
                  onChange={onChange}
                  defaultValue={aggravatedConditionDetail}
                  fullWidth
                  multiline={true}
                />
              </ConditionalRender>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section4.question05}
              </FormLabel>
              <BooleanRadioGroup
                name="requiresMedicine"
                defaultValue={requiresMedicine}
                inputRef={register}
                onChange={onChange}
              />
              <ConditionalRender condition={requiresMedicine === 'true'}>
                <StyleTextField
                  required
                  name="requiresMedicineDetail"
                  label={Text.section4.question06}
                  inputRef={register}
                  onChange={onChange}
                  defaultValue={requiresMedicineDetail}
                  fullWidth
                  multiline={true}
                />
              </ConditionalRender>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section4.question07}
              </FormLabel>
              <BooleanRadioGroup
                name="hasBackInjury"
                defaultValue={hasBackInjury}
                inputRef={register}
                onChange={onChange}
              />
              <ConditionalRender condition={hasBackInjury === 'true'}>
                <StyleTextField
                  required
                  name="backInjuryDetail"
                  label={Text.section4.question08}
                  inputRef={register}
                  onChange={onChange}
                  defaultValue={backInjuryDetail}
                  fullWidth
                  multiline={true}
                />
              </ConditionalRender>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section4.question09}
              </FormLabel>
              <BooleanRadioGroup
                name="hasAcc"
                defaultValue={hasAcc}
                inputRef={register}
                onChange={onChange}
              />
              <ConditionalRender condition={hasAcc === 'true'}>
                <StyleTextField
                  required
                  name="accDetail"
                  label={Text.section4.question10}
                  inputRef={register}
                  onChange={onChange}
                  defaultValue={accDetail}
                  fullWidth
                  multiline={true}
                />
              </ConditionalRender>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section4.question11}
              </FormLabel>
              <BooleanRadioGroup
                name="hasArrivedIntoNz"
                defaultValue={hasArrivedIntoNz}
                inputRef={register}
                onChange={onChange}
              />
              <ConditionalRender condition={hasArrivedIntoNz === 'true'}>
                <Grid item xs={12} sm={6}>
                  <FormLabel component="legend" required>
                    {Text.section4.question12}
                  </FormLabel>
                  <LocalKeyboardDatePicker
                    required
                    name="arrivedIntoNzDate"
                    inputRef={register}
                    value={arrivedIntoNzDate}
                    handleDateChange={(date: Date) => {
                      handleArrivedIntoNzDateChange(date)
                    }}
                    disableToolBar={false}
                  />
                </Grid>
              </ConditionalRender>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section4.question13}
              </FormLabel>
              <BooleanRadioGroup
                name="hasInfectiousDisease"
                defaultValue={hasInfectiousDisease}
                inputRef={register}
                onChange={onChange}
              />
              <ConditionalRender condition={hasInfectiousDisease === 'true'}>
                <StyleTextField
                  required
                  name="infectiousDiseaseDetail"
                  label={Text.section4.question14}
                  inputRef={register}
                  onChange={onChange}
                  defaultValue={infectiousDiseaseDetail}
                  fullWidth
                  multiline={true}
                />
              </ConditionalRender>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section4.question15}
              </FormLabel>
              <BooleanRadioGroup
                name="hasTestConsent"
                defaultValue={hasTestConsent}
                inputRef={register}
                onChange={onChange}
              />
              <ConditionalRender condition={hasTestConsent === 'false'}>
                <StyleTextField
                  required
                  name="testConsentDetail"
                  label={Text.section4.question16}
                  inputRef={register}
                  onChange={onChange}
                  defaultValue={testConsentDetail}
                  fullWidth
                  multiline={true}
                />
              </ConditionalRender>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">
                {Text.section4.question17}
              </FormLabel>
              <BooleanRadioGroup
                name="shareCovidVaccination"
                defaultValue={shareCovidVaccination}
                isRequired={false}
                inputRef={register}
                onChange={onChange}
              />
              <ConditionalRender condition={shareCovidVaccination === 'true'}>
                <FormLabel component="legend">
                  {Text.section4.question18}
                </FormLabel>
                <StyledAutoComplete
                  options={CovidOptions}
                  getOptionLabel={(option: any) => option.name}
                  getOptionSelected={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={covidStatus}
                  ref={() => register('covidStatus')}
                  onChange={(event: any, newValue: any) =>
                    autoCompleteChange('covidStatus', newValue)
                  }
                  ListboxProps={{ style: { maxHeight: '15rem' } }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      placeholder={mobilePlaceholder}
                    />
                  )}
                />
              </ConditionalRender>
            </FormControl>
          </Grid>
        </Grid>
      </React.Fragment>

      <StepsButton onBack={back} onNext={() => (action = next)} />
    </form>
  )
}

export default Section4
