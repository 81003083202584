import React from 'react'
import { useSetRecoilState } from 'recoil'
import { Box } from '@material-ui/core'
import { selectedApplicantAtom } from './store/atom'
import { IApplicant, IApiResponse } from '../../types/interfaces'
import { useParams } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MainSection from './MainSection'
import BottomSection from './BottomSection'
import customAxios from '../../services/customAxios'
import LoadingBar from '../../components/LoadingBar'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: '20px 20px 0px 20px',
    borderRadius: 2,
    background: theme.palette.background.paper,
  },
}))

const ApplicantDetail = ({ siteId }: { siteId?: number }) => {
  const classes = useStyles()

  const { id } = useParams<{ id: string }>()
  const setSelectedApplicant = useSetRecoilState(selectedApplicantAtom)
  React.useEffect(() => {
    const fetchData = async (applicantId: number) => {
      let res: IApiResponse
      if (siteId) {
        res = await customAxios.getAsync(
          `/api/Applicants/${applicantId}/siteHR/${siteId}`,
        )
      } else {
        res = await customAxios.getAsync(`/api/Applicants/${applicantId}`)
      }

      if (res.data) {
        setSelectedApplicant(res.data as IApplicant)
      }
    }

    fetchData(Number(id))
  }, [id, siteId, setSelectedApplicant])

  return (
    <React.Suspense fallback={<LoadingBar text="Loading ..."></LoadingBar>}>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        className={classes.root}
      >
        <MainSection siteId={siteId}></MainSection>
        <hr
          style={{
            borderTop: '1px dashed black',
            width: '100%',
            marginTop: 20,
            marginBottom: 20,
          }}
        ></hr>
        <BottomSection></BottomSection>
      </Box>
    </React.Suspense>
  )
}

export default ApplicantDetail
