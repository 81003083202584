import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useRecoilValue } from 'recoil'
import { selectedApplicantAtom } from './store/atom'
import LabelValueCellWithDetail from './LabelValueCellWithDetail'
import * as Text from '../../assets/FormTextLabels.json'
import ConditionalRender from '../../components/ConditionalRender'
import LabelValueCell from './LabelValueCell'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
}))

const MedicalTab = () => {
  const classes = useStyles()

  const applicantData = useRecoilValue(selectedApplicantAtom)

  return (
    <Box className={classes.root}>
      <Box display="flex" flexGrow={1} flexDirection="column">
        <LabelValueCellWithDetail
          label={Text.section4.question01}
          value={applicantData?.hasMedicalCondition}
          details={applicantData?.medicalConditionDetail}
        ></LabelValueCellWithDetail>

        <LabelValueCellWithDetail
          label={Text.section4.question03}
          value={applicantData?.hasAggravatedCondition}
          details={applicantData?.aggravatedConditionDetail}
        ></LabelValueCellWithDetail>

        <LabelValueCellWithDetail
          label={Text.section4.question05}
          value={applicantData?.requiresMedicine}
          details={applicantData?.requiresMedicineDetail}
        ></LabelValueCellWithDetail>

        <LabelValueCellWithDetail
          label={Text.section4.question07}
          value={applicantData?.hasBackInjury}
          details={applicantData?.backInjuryDetail}
        ></LabelValueCellWithDetail>

        <LabelValueCellWithDetail
          label={Text.section4.question09}
          value={applicantData?.hasAcc}
          details={applicantData?.accDetail}
        ></LabelValueCellWithDetail>

        <LabelValueCellWithDetail
          label={Text.section4.question11}
          value={applicantData?.hasArrivedIntoNz}
          details={applicantData?.arrivedIntoNzDateStr}
        ></LabelValueCellWithDetail>

        <LabelValueCellWithDetail
          label={Text.section4.question13}
          value={applicantData?.hasInfectiousDisease}
          details={applicantData?.infectiousDiseaseDetail}
        ></LabelValueCellWithDetail>

        <LabelValueCellWithDetail
          label={Text.section4.question15}
          value={applicantData?.hasTestConsent}
          details={applicantData?.testConsentDetail}
        ></LabelValueCellWithDetail>
        <ConditionalRender condition={applicantData?.isAvailableForAllHours === false} >
          <LabelValueCell
            label={Text.section4.question16}
            value={applicantData?.testConsentDetail}
          ></LabelValueCell>
        </ConditionalRender>

        <LabelValueCell
          label={Text.section4.question17}
          value={applicantData?.shareCovidVaccination ? 'Yes' : 'No'}
          canAddColon={false}
        ></LabelValueCell>
        <ConditionalRender condition={applicantData?.shareCovidVaccination === true}>
          <LabelValueCell
            label={Text.section4.question18}
            value={applicantData?.covidStatus}
          ></LabelValueCell>
        </ConditionalRender>
      </Box>
    </Box>
  )
}

export default MedicalTab
