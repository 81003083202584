import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ISection6, section6Atom, ILookups, lookUpsAtom } from './state'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from '@material-ui/core'
import * as Text from '../../assets/FormTextLabels.json'
import BooleanRadioGroup from '../../components/BooleanRadioGroup'
import ConditionalRender from '../../components/ConditionalRender'
import { useRecoilState } from 'recoil'
import MaskedInputText from '../../components/MaskedInputText'
import lookupService from '../../services/lookupService'
import { LookupTypesEnum } from '../../types/enums'
import StyledAutoComplete from '../../components/StyledAutoComplete'
import StepsButton from '../../components/StepsButton'
import TitleField from '../../components/TitleField'
import StyleTextField from '../../components/StyleTextField'
import { replaceMaskText } from '../../services/helpers'

const Section6 = ({ next, back }: { next?: () => void; back?: () => void }) => {
  const [state, setState] = useRecoilState<ISection6>(section6Atom)
  const [focus, setFocus] = useState(false)
  const { register, handleSubmit, setValue } = useForm<ISection6>({
    defaultValues: state,
    mode: 'all',
  })
  const [lookUpsState, setlookUpsState] = useRecoilState<ILookups>(lookUpsAtom)
  let action: any
  const onSubmit: SubmitHandler<ISection6> = (data) => {
    const section6 = {
      ...data,
      taxCodeId: data.selectedTaxeCode?.id,
      kiwisaverContributionPercentageId: data.selectedKiwisaverContribution?.id,
      bankAccountNo: replaceMaskText(data.bankAccountNo),
      irdNo: replaceMaskText(data.irdNo),
      isEnrolledInKiwiSaver:
        data.isEnrolledInKiwiSaver == null || data.isEnrolledInKiwiSaver == ''
          ? 'false'
          : data.isEnrolledInKiwiSaver,
    }
    setState({
      ...state,
      ...section6,
    })
    if (action) action()
  }

  const { TaxCode, KiwisaverPercentage } = lookUpsState

  React.useEffect(() => {
    if (
      !lookUpsState[LookupTypesEnum.TaxCode] ||
      lookUpsState[LookupTypesEnum.TaxCode].length < 1
    ) {
      let lookups: [Promise<any>, Promise<any>] = [
        lookupService.getLookup(LookupTypesEnum.TaxCode),
        lookupService.getLookup(LookupTypesEnum.KiwisaverPercentage),
      ]

      Promise.all(lookups).then((results: any[]) => {
        setlookUpsState({
          ...lookUpsState,
          [LookupTypesEnum.TaxCode]: results[0],
          [LookupTypesEnum.KiwisaverPercentage]: results[1],
        })
      })
    }
  }, [lookUpsState, setlookUpsState])

  const onChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setValue(e.target.name, e.target.value, { shouldDirty: true })
  }

  const autoCompleteChange = (name: string, value: any) => {
    setState({ ...state, [name]: value })
    setValue(name, value, { shouldDirty: true })
  }
  const {
    irdNo,
    bankAccountNo,
    selectedTaxeCode,
    isEnrolledInKiwiSaver,
    isContributingtoKiwiSaver,
    selectedKiwisaverContribution,
  } = state
  return (
    <form className="form-step" onSubmit={handleSubmit(onSubmit)}>
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TitleField variant="h4" gutterBottom>
              {Text.section6.subtitle1}
            </TitleField>
          </Grid>
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{ __html: Text.section6.blurb }}></p>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormControl>
                <StyleTextField
                  label={Text.section6.question01}
                  defaultValue={irdNo}
                  onChange={onChange}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  InputLabelProps={{ shrink: irdNo || focus ? true : false }}
                  name="irdNo"
                  InputProps={{
                    inputComponent: MaskedInputText as any,
                    inputProps: {
                      minLength: 11,
                      inputRef: () => register('irdNo'),
                      defaultValue: irdNo,
                      maskInput: [
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                      ],
                    },
                  }}
                />
              </FormControl>
              <FormHelperText>{Text.section6.question01Helper}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormControl>
                <StyleTextField
                  label={Text.section6.question02}
                  onChange={onChange}
                  defaultValue={bankAccountNo}
                  InputLabelProps={{
                    shrink: bankAccountNo || focus ? true : false,
                  }}
                  name="bankAccountNo"
                  InputProps={{
                    inputComponent: MaskedInputText as any,
                    inputProps: {
                      minLength: 18,
                      inputRef: () => register('bankAccountNo'),
                      defaultValue: bankAccountNo,
                      maskInput: [
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                      ],
                    },
                  }}
                />
              </FormControl>
              <FormHelperText>{Text.section6.question02Helper}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">
                {Text.section6.question03}
              </FormLabel>
              <StyledAutoComplete
                options={TaxCode}
                getOptionLabel={(option: any) => option.name}
                getOptionSelected={(option: any, value: any) =>
                  option.id === value.id
                }
                value={selectedTaxeCode}
                ref={() => register('selectedTaxeCode')}
                onChange={(event: any, newValue: any) =>
                  autoCompleteChange('selectedTaxeCode', newValue)
                }
                ListboxProps={{ style: { maxHeight: '15rem' } }}
                renderInput={(params) => (
                  <TextField {...params} label="" margin="normal" />
                )}
              />
              <FormHelperText
                dangerouslySetInnerHTML={{
                  __html: Text.section6.question03Helper,
                }}
              ></FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">
                {Text.section6.question04}
              </FormLabel>
              <BooleanRadioGroup
                name="isEnrolledInKiwiSaver"
                defaultValue={isEnrolledInKiwiSaver}
                isRequired={false}
                inputRef={register}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <ConditionalRender condition={isEnrolledInKiwiSaver === 'true'}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                  {Text.section6.question05}
                </FormLabel>
                <BooleanRadioGroup
                  name="isContributingtoKiwiSaver"
                  defaultValue={isContributingtoKiwiSaver}
                  inputRef={register}
                  onChange={onChange}
                />
              </FormControl>
            </Grid>
          </ConditionalRender>
          <ConditionalRender
            condition={
              isEnrolledInKiwiSaver === 'true' &&
              isContributingtoKiwiSaver === 'true'
            }
          >
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                  {Text.section6.question06}
                </FormLabel>
                <StyledAutoComplete
                  options={KiwisaverPercentage}
                  getOptionLabel={(option: any) => option.name}
                  getOptionSelected={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={selectedKiwisaverContribution}
                  ref={() => register('selectedKiwisaverContribution')}
                  onChange={(event: any, newValue: any) =>
                    autoCompleteChange(
                      'selectedKiwisaverContribution',
                      newValue,
                    )
                  }
                  ListboxProps={{ style: { maxHeight: '15rem' } }}
                  renderInput={(params) => (
                    <TextField {...params} label="" margin="normal" />
                  )}
                />
              </FormControl>
            </Grid>
          </ConditionalRender>
        </Grid>
      </React.Fragment>

      <StepsButton onBack={back} onNext={() => (action = next)} />
    </form>
  )
}

export default Section6
