import React from 'react'
import { userRoleAtom } from '../../store/atom'
import { useRecoilState } from 'recoil'
import customAxios from '../../services/customAxios'
import { IUserRoleInfo } from '../../types/interfaces'
import LoadingBar from '../../components/LoadingBar'
import ErrorMessage from '../../components/ErrorMessage'
import { useParams } from 'react-router-dom'
import * as Text from '../../assets/FormTextLabels.json'

type AuthorizeUserProps = {
  parentName?: string
}

const AuthorizeUser: React.FunctionComponent<AuthorizeUserProps> = ({
  children,
  parentName,
}) => {
  const { id } = useParams<{ id: string }>()

  const [userRoleInfo, setUserRoleInfo] = useRecoilState(userRoleAtom)

  const noPermission = (
    <ErrorMessage text={Text.messages.unauthorized}></ErrorMessage>
  )

  const [error, setError] = React.useState('')

  React.useEffect(() => {
    const getUserRoles = async () => {
      if (!userRoleInfo.roles) {
        let queryString: string = ''
        if (id && parentName === 'ApplicantDetailPage') {
          queryString = `?applicantId=${id}`
        }
        let res = await customAxios.getAsync(
          `/api/Permissions/getUserRoles${queryString}`,
        )

        if (res.data) {
          setUserRoleInfo(res.data)
        } else {
          setError(res.error as string)
        }
      }
    }
    getUserRoles()
  }, [id, parentName, userRoleInfo, setUserRoleInfo])

  let func = children as (props: IUserRoleInfo) => any
  if (error) {
    return <ErrorMessage text={Text.messages.authorizeError}></ErrorMessage>
  } else {
    if (userRoleInfo.roles && userRoleInfo.roles.length === 0) {
      return noPermission
    }

    if (!userRoleInfo.roles) {
      return <LoadingBar text={Text.messages.authorizeUser}></LoadingBar>
    }

    return func(userRoleInfo)
  }
}

export default AuthorizeUser
