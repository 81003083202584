import React from 'react'
import HeaderSection from './HeaderSection'
import TableSection from './TableSection'
import { Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import LoadingBar from '../../components/LoadingBar'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: '20px 20px 0px 20px',
    borderRadius: 2,
    background: theme.palette.background.paper,
  },
  mainTable: {
    paddingTop: theme.spacing(3),
  },
}))

const ApplicantList = ({ siteId }: { siteId?: number }) => {
  const classes = useStyles()

  const tableRef = React.useRef<any>()

  return (
    <React.Suspense fallback={<LoadingBar text="Loading ..."></LoadingBar>}>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <HeaderSection
              siteId={siteId}
              handleAssignClick={() => {
                tableRef.current.reloadData()
              }}
            ></HeaderSection>
          </Grid>

          <Grid container item xs={12} className={classes.mainTable}>
            <TableSection ref={tableRef} siteId={siteId}></TableSection>
          </Grid>
        </Grid>
      </div>
    </React.Suspense>
  )
}

export default ApplicantList
