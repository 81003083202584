import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useRecoilValue } from 'recoil'
import { selectedApplicantAtom } from './store/atom'
import LabelValueCell from './LabelValueCell'
import * as Text from '../../assets/FormTextLabels.json'
import { toLocalDateTime } from '../../services/helpers'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
}))

const ConsentTab = () => {
  const classes = useStyles()

  const applicantData = useRecoilValue(selectedApplicantAtom)

  const canShowEmployeesDetail =
    applicantData?.jobReferralValue
      .toLowerCase()
      .indexOf('Referred by an EastPack Employee'.toLowerCase()) !== -1

  return (
    <Box className={classes.root}>
      <Box display="flex" flexGrow={1} flexDirection="column">
        <LabelValueCell
          label={Text.section7.question01}
          value="Yes"
        ></LabelValueCell>

        <LabelValueCell
          label={Text.section7.question02}
          value={applicantData?.jobReferralValue}
          canAddColon={false}
        ></LabelValueCell>

        {canShowEmployeesDetail && (
          <LabelValueCell
            label={Text.section7.question03}
            value={applicantData?.referralEmployeeDetail}
            canAddColon={false}
          ></LabelValueCell>
        )}

        <LabelValueCell
          label="Application Submitted"
          value={toLocalDateTime(applicantData?.createdDate)}
          canAddColon={false}
        ></LabelValueCell>
      </Box>
    </Box>
  )
}

export default ConsentTab
