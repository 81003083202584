import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import * as React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useRecoilState } from 'recoil'
import BooleanRadioGroup from '../../components/BooleanRadioGroup'
import ConditionalRender from '../../components/ConditionalRender'
import RadioGroupList from '../../components/RadioGroupList'
import StepsButton from '../../components/StepsButton'
import StyledAutoComplete from '../../components/StyledAutoComplete'
import StyleTextField from '../../components/StyleTextField'
import TitleField from '../../components/TitleField'
import lookupService from '../../services/lookupService'
import { LookupTypesEnum } from '../../types/enums'
import { ILookups, ISection2, lookUpsAtom, section2Atom } from './state'
import * as Text from '../../assets/FormTextLabels.json'
import LocalKeyboardDatePicker from '../../components/LocalKeyboardDatePicker'

const Section2 = ({
  next,
  back,
  checkRejectRule,
}: {
  next?: () => void
  back?: () => void
  checkRejectRule?: () => void
}) => {
  const [lookUpsState, setlookUpsState] = useRecoilState<ILookups>(lookUpsAtom)
  const [state, setState] = useRecoilState<ISection2>(section2Atom)
  const { register, handleSubmit, setValue } = useForm<ISection2>({
    defaultValues: state,
  })
  let action: any
  const onSubmit: SubmitHandler<ISection2> = (data) => {
    setState({
      ...state,
      ...data,
    })
    const isReject = checkRejectRule && checkRejectRule()
    if (action && !isReject) action()
  }
  React.useEffect(() => {
    if (
      !lookUpsState[LookupTypesEnum.VisaOptions] ||
      lookUpsState[LookupTypesEnum.VisaOptions].length < 1
    ) {
      let lookups: [Promise<any>, Promise<any>, Promise<any>] = [
        lookupService.getLookup(LookupTypesEnum.VisaOptions),
        lookupService.getLookup(LookupTypesEnum.Nationality),
        lookupService.getLookup(LookupTypesEnum.Ethnicity),
      ]

      Promise.all(lookups).then((results: any[]) => {
        setlookUpsState({
          ...lookUpsState,
          [LookupTypesEnum.VisaOptions]: results[0],
          [LookupTypesEnum.Nationality]: results[1],
          [LookupTypesEnum.Ethnicity]: results[2],
        })
      })
    }
  }, [lookUpsState, setlookUpsState])

  const onChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setValue(e.target.name, e.target.value, { shouldDirty: true })
  }

  const onRaidoButtonChange = (e: any) => {
    const id = Number(e.target.value)
    const visa = VisaOptions.find((x) => x.id === id)
    const showReceivingBenefit =
      visa &&
      (visa.name === 'NZ / Australian Citizen' ||
        visa.name === 'NZ Resident Permit')
        ? true
        : false
    const showVisaExpiryDate =
      visa &&
      (visa.name === 'NZ Work Permit' ||
        visa.name === 'Student Visa' ||
        visa.name === 'Work Permit' ||
        visa.name === 'Work Visa')
        ? true
        : false
    const showSSEVisa = visa && visa.name === 'Work Visa' ? true : false
    setValue('showReceivingBenefit', showReceivingBenefit, {
      shouldDirty: true,
    })
    setValue('showVisaExpiryDate', showVisaExpiryDate, {
      shouldDirty: true,
    })
    setValue('showSSEVisa', showSSEVisa, {
      shouldDirty: true,
    })

    setValue(e.target.name, e.target.value, { shouldDirty: true })
    setState({
      ...state,
      [e.target.name]: e.target.value,
      showReceivingBenefit: showReceivingBenefit,
      showVisaExpiryDate: showVisaExpiryDate,
      showSSEVisa: showSSEVisa,
    })
  }

  const handleVisaExpiryDateChange = (value: any) => {
    setState({ ...state, visaExpiryDate: value })
    setValue('visaExpiryDate', value, { shouldDirty: true })
  }

  const autoCompleteChange = (name: string, value: any) => {
    setState({ ...state, [name]: value })
    setValue(name, value, { shouldDirty: true })
  }

  const {
    isEligible,
    workVisaId,
    showReceivingBenefit,
    showVisaExpiryDate,
    showSSEVisa,
    isReceivingBenefit,
    isSSEVisa,
    visaExpiryDate,
    passportNo,
    nationalityId,
    ethnicityId,
    isFluentEnglishSpeaker,
  } = state

  const { VisaOptions, Nationality, Ethnicity } = lookUpsState

  const mobilePlaceholder = useMediaQuery(useTheme().breakpoints.down('xs'))
    ? Text.section2.mobileHelper
    : ''

  return (
    <form
      className="form-step"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TitleField variant="h4" gutterBottom>
              {Text.section2.subtitle1}
            </TitleField>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section2.question01}
              </FormLabel>
              <BooleanRadioGroup
                name="isEligible"
                defaultValue={isEligible}
                inputRef={register}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <ConditionalRender condition={isEligible === 'true'}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" required>
                  {Text.section2.question02}
                </FormLabel>
                <RadioGroupList
                  name="workVisaId"
                  list={VisaOptions}
                  checkedValue={workVisaId}
                  inputRef={register}
                  onChange={onRaidoButtonChange}
                />
              </FormControl>
            </Grid>
          </ConditionalRender>
          <ConditionalRender
            condition={isEligible === 'true' && showReceivingBenefit}
          >
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" required>
                  {Text.section2.question03}
                </FormLabel>
                <BooleanRadioGroup
                  name="isReceivingBenefit"
                  defaultValue={isReceivingBenefit}
                  inputRef={register}
                  onChange={onChange}
                />
              </FormControl>
            </Grid>
          </ConditionalRender>
          <ConditionalRender condition={isEligible === 'true' && showSSEVisa}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" required>
                  Is this an SSE visa?
                </FormLabel>
                <BooleanRadioGroup
                  name="isSSEVisa"
                  defaultValue={isSSEVisa}
                  inputRef={register}
                  onChange={onChange}
                />
              </FormControl>
            </Grid>
          </ConditionalRender>
          <ConditionalRender
            condition={isEligible === 'true' && showVisaExpiryDate}
          >
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" required>
                  {Text.section2.question04}
                </FormLabel>
                <LocalKeyboardDatePicker
                  required
                  name="visaExpiryDate"
                  inputRef={register}
                  value={visaExpiryDate}
                  handleDateChange={(date: Date) => {
                    handleVisaExpiryDateChange(date)
                  }}
                  disableToolBar={false}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" fullWidth>
                <StyleTextField
                  inputProps={{ maxLength: 20 }}
                  required
                  name="passportNo"
                  label={Text.section2.question05}
                  inputRef={register}
                  onChange={onChange}
                  defaultValue={passportNo}
                  fullWidth
                />
              </FormControl>
            </Grid>
          </ConditionalRender>
          <ConditionalRender condition={isEligible === 'true'}>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" required>
                  {Text.section2.question06}
                </FormLabel>
                <FormHelperText>
                  {Text.section2.question06Helper}
                </FormHelperText>
                <StyledAutoComplete
                  options={Nationality}
                  getOptionLabel={(option: any) => option.name}
                  getOptionSelected={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={nationalityId}
                  ref={() => register('nationalityId')}
                  onChange={(event: any, newValue: any) =>
                    autoCompleteChange('nationalityId', newValue)
                  }
                  ListboxProps={{ style: { maxHeight: '15rem' } }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      margin="normal"
                      placeholder={mobilePlaceholder}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" required>
                  {Text.section2.question07}
                </FormLabel>
                <StyledAutoComplete
                  options={Ethnicity}
                  getOptionLabel={(option: any) => option.name}
                  getOptionSelected={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={ethnicityId}
                  ref={() => register('ethnicityId')}
                  onChange={(event: any, newValue: any) =>
                    autoCompleteChange('ethnicityId', newValue)
                  }
                  ListboxProps={{ style: { maxHeight: '15rem' } }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      margin="normal"
                      placeholder={mobilePlaceholder}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" required>
                  {Text.section2.question08}
                </FormLabel>
                <BooleanRadioGroup
                  name="isFluentEnglishSpeaker"
                  defaultValue={isFluentEnglishSpeaker}
                  inputRef={register}
                  onChange={onChange}
                />
              </FormControl>
            </Grid>
          </ConditionalRender>
        </Grid>
      </React.Fragment>
      <StepsButton onBack={back} onNext={() => (action = next)} />
    </form>
  )
}

export default Section2
