export enum LookupTypesEnum {
  Gender = 'Gender',
  Nationality = 'Nationality',
  Ethnicity = 'Ethnicity',
  RelationshipToYou = 'RelationshipToYou',
  VisaOptions = 'VisaOptions',
  PackhouseLocation = 'PackhouseLocation',
  Shifts = 'Shifts',
  Roles = 'Roles',
  ForkliftOshCertificate = 'ForkliftOSHCertificate',
  TaxCode = 'TaxCode',
  Status = 'Status',
  Site = 'Site',
  KiwisaverPercentage = 'KiwisaverPercentage',
  Title = 'Title',
  JobReferral = 'JobReferral',
  GraderLine = 'GraderLine',
  ForkliftOSHCertificate = 'ForkliftOSHCertificate',
  CovidOptions = 'CovidOptions',
  EmployeeType = 'EmployeeType',
}

export enum RoleNames {
  CentralHR = 'CentralHR',
  SiteHR = 'SiteHR',
  Payroll = 'Payroll',
}
