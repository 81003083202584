import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '../../components/DialogTitle'
import DialogContent from '../../components/DialogContent'
import DialogActions from '../../components/DialogActions'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
} from '@material-ui/core'
import RadioGroupList from '../../components/RadioGroupList'
import StyleTextField from '../../components/StyleTextField'
import { makeStyles } from '@material-ui/core/styles'
import TitleField from '../../components/TitleField'
import customAxios from '../../services/customAxios'
import { centralApplicantSelectedRowssAtom } from '../ApplicantList/store/atom'
import { useRecoilValue } from 'recoil'
import LoadingButton from '../../components/LoadingButton'
import { ILookupType } from '../../types/interfaces'

export interface IExportCSV {
  exportType?: number
  exportDateType?: number
  fromDate?: Date
  toDate?: Date
  includePreviously: boolean
  ids: string
  siteId?: number
}

const useStyles = makeStyles((theme) => ({
  row: {
    padding: theme.spacing(1),
  },
  title: {
    marginTop: '8px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
    border: '2px solid #78BE20',
    '&:hover': {
      border: '2px solid #78BE20',
      color: '#FFF',
      background: '#78BE20',
    },
  },
}))
const ExportCSVDialog = forwardRef((props: any, ref: any) => {
  const { register, handleSubmit, setValue } = useForm({
    mode: 'all',
  })
  const [open, setOpen] = useState(false)
  const [state, setState] = useState<IExportCSV>({
    exportType: 1,
    exportDateType: 1,
    includePreviously: false,
    ids: '',
  })

  const selectedRows = useRecoilValue(centralApplicantSelectedRowssAtom)
  const submitButtonRef = useRef<any>()
  const onSubmit: SubmitHandler<IExportCSV> = (data: IExportCSV) => {
    data.ids =
      Number(data.exportDateType) === 1
        ? selectedRows
            .map((elem: any) => {
              return elem.id
            })
            .join(';')
        : ''
    data.siteId = props.siteId
    submitButtonRef.current.showLoading(true)
    customAxios
      .download(`/api/Applicants/export`, 'export.csv', data)
      .then((res: any) => {
        setState({
          ...state,
          ...data,
        })
      })
      .finally(() => {
        submitButtonRef.current.showLoading(false)
      })
  }
  useImperativeHandle(ref, () => ({
    showDialog() {
      setOpen(true)
    },
  }))
  const classes = useStyles()
  const handleClose = () => {
    setState({
      exportType: 1,
      exportDateType: 1,
      includePreviously: false,
      ids: '',
    })
    setOpen(false)
  }

  const onChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setValue(e.target.name, e.target.value, { shouldDirty: true })
  }

  const onChangeCheckobx = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.checked })
    setValue(e.target.name, e.target.checked, { shouldDirty: true })
  }
  const {
    exportType,
    exportDateType,
    fromDate,
    toDate,
    includePreviously,
  } = state
  const exportTypes: ILookupType[] = [
    {
      name: 'Induction',
      id: 1,
      isActive: true,
      description: '',
      isDefault: true,
    },
    {
      name: 'Payroll',
      id: 2,
      isActive: true,
      description: '',
      isDefault: true,
    },
  ]
  const exportDatas: ILookupType[] = [
    {
      name: 'Selected data',
      id: 1,
      isActive: true,
      description: '',
      isDefault: true,
    },
    {
      name: 'All data',
      id: 2,
      isActive: true,
      description: '',
      isDefault: true,
    },
  ]

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Export to CSV
        </DialogTitle>
        <DialogContent dividers>
          <Grid container className={classes.row}>
            <Grid item xs={3}>
              <TitleField gutterBottom className={classes.title}>
                Export type
              </TitleField>
            </Grid>
            <Grid item xs={9}>
              <FormControl component="fieldset" fullWidth>
                <RadioGroupList
                  name="exportType"
                  list={exportTypes}
                  checkedValue={exportType}
                  inputRef={register}
                  onChange={onChange}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.row}>
            <Grid item xs={3}>
              <TitleField gutterBottom className={classes.title}>
                Export data
              </TitleField>
            </Grid>
            <Grid item xs={9}>
              <FormControl component="fieldset" fullWidth>
                <RadioGroupList
                  name="exportDateType"
                  list={exportDatas}
                  checkedValue={exportDateType}
                  inputRef={register}
                  onChange={onChange}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.row}>
            <Grid item xs={3}></Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset" fullWidth>
                <StyleTextField
                  name="fromDate"
                  label="From"
                  inputRef={register}
                  defaultValue={fromDate}
                  type="date"
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset" fullWidth>
                <StyleTextField
                  name="toDate"
                  label="To"
                  inputRef={register}
                  defaultValue={toDate}
                  type="date"
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  InputProps={{ inputProps: { min: fromDate } }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.row}>
            <Grid item xs={3}></Grid>
            <Grid item xs={9}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includePreviously}
                    name="includePreviously"
                    color="primary"
                    onChange={onChangeCheckobx}
                    inputRef={register}
                  />
                }
                label="Include previously exported data"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="outlined"
              color="primary"
              className={classes.button}
              label="Export"
              ref={submitButtonRef}
            />
          </div>
        </DialogActions>
      </form>
    </Dialog>
  )
})

export default ExportCSVDialog
