import DateFnsUtils from '@date-io/date-fns'
import enLocale from 'date-fns/locale/en-US'
import React from 'react'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const LocalKeyboardDatePicker = ({
  name,
  value,
  inputRef,
  handleDateChange,
  minDate,
  handleOnError,
  disableToolBar = true,
  required = false,
}: {
  name?: string
  value: ParsableDate
  inputRef?: any
  handleDateChange: (date: Date) => void
  minDate?: ParsableDate
  handleOnError?: (
    error: React.ReactNode,
    value: MaterialUiPickersDate | ParsableDate,
  ) => void
  disableToolBar?: boolean
  required?: boolean
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
      <KeyboardDatePicker
        name={name}
        value={value}
        inputRef={inputRef}
        placeholder="dd/mm/yyyy"
        onChange={(date) => {
          if (handleDateChange) {
            handleDateChange(date as Date)
          }
        }}
        format="dd/MM/yyyy"
        variant="inline"
        autoOk
        inputVariant="outlined"
        minDate={minDate}
        onError={handleOnError}
        disableToolbar={disableToolBar}
        required={required}
      />
    </MuiPickersUtilsProvider>
  )
}

export default LocalKeyboardDatePicker
