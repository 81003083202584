import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ThemeProvider } from '@material-ui/styles'
import React from 'react'
import { RecoilRoot } from 'recoil'
import './App.css'
import Routes from './routes'
import theme from './theme'
import TagManager from 'react-gtm-module'

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'auto',
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
    background: 'linear-gradient(#62bb46, #8fc73e) no-repeat',
  },
  subContainer: {
    display: 'inline-table',
  },
}))

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID as string,
  dataLayerName: 'StepDataLayer',
}
TagManager.initialize(tagManagerArgs)
function App() {
  const classes = useStyles()
  return (
    <RecoilRoot>
      <Grid className={classes.container} container direction="column">
        <Grid className={classes.subContainer} item container>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </Grid>
      </Grid>
    </RecoilRoot>
  )
}
export default App
