import { atom } from 'recoil'
import { RowsProp } from '@material-ui/data-grid'
import { ILookupType } from '../../../types/interfaces'

export const allLookupListAtom = atom<RowsProp>({
  key: 'allLookupListAtom',
  default: [],
})
export const allSiteShiftsListAtom = atom<RowsProp>({
  key: 'allSiteShiftsListAtom',
  default: [],
})

export const availableShiftsAtom = atom<RowsProp>({
  key: 'availableShiftsAtom',
  default: [],
})

export const shiftsLookups = atom<ILookupType[]>({
  key: 'shiftsLookups',
  default: [],
})
