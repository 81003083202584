import axios, { AxiosInstance } from 'axios'
import { authProvider } from '../authProvider'
import { IApiResponse } from "../types/interfaces";
import FileSaver from 'file-saver'
class CustomAxios {
  instance: AxiosInstance
  idToken: string = ''
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_CONFIG_ENDPOINT,
      timeout: Number(process.env.REACT_APP_CONFIG_CACHE_TIMEOUT),
    })
  }

  async getIdToken() {
    try {
      let response = await authProvider.getIdToken()
      this.idToken = response && response.idToken ? response.idToken.rawIdToken : ''
      return this.idToken
    } catch(err) {
      console.log('getIdToken ERROR', err)      
    }
  }

  async getAsync(url: string): Promise<IApiResponse> {
    let accountInfo = authProvider.getAccountInfo()
    if (accountInfo) {
      let token = await this.getIdToken()
      this.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }

    let res = await this.instance.get(url).catch((error) => {
      if (error.response) {
        return { data: null, statusText: error.response.data ? error.response.data : error.response.status as string}
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return {
          data: null,
          statusText: 'The request was made but no response was received',
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        return { data: null, statusText: error.message }
      }
    })
    return res.data
      ? { data: res.data, error: null }
      : { data: null, error: res.statusText }
  }

  async postAsync(url: string, data: any): Promise<IApiResponse> {
    let accountInfo = authProvider.getAccountInfo()
    if (accountInfo) {
      let token = await this.getIdToken()
      this.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
    let res = await this.instance.post(url, data).catch((error) => {
      if (error.response) {
        return { data: null, statusText: error.response.data ? error.response.data : error.response.status as string}
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return {
          data: null,
          statusText: 'The request was made but no response was received',
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        return { data: null, statusText: error.message }
      }
    })
    return res.data
      ? { data: res.data, error: null }
      : { data: null, error: res.statusText }
  }
  async download(url: string, defaultFileName: string, data: any) {
    try {
      let token = await this.getIdToken()
      return new Promise(function (resolve, reject) {
        let baseAddress = process.env.REACT_APP_CONFIG_ENDPOINT || ''
        var xhr = new XMLHttpRequest()
        xhr.open('POST', baseAddress + url, true)
        xhr.responseType = 'blob'
        xhr.setRequestHeader('Content-type', 'application/json')
        xhr.setRequestHeader('Authorization', `Bearer ${token}`)
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              var blob = xhr.response
              var filename = defaultFileName
              if (xhr.getAllResponseHeaders().indexOf('content-type') >= 0) {
                var fileInfo = xhr.getResponseHeader('content-type')
                if (fileInfo) {
                  filename = fileInfo
                    .replace('file/', '')
                    .replace(/-SPACE-/g, ' ')
                    .replace(/-DOT-/g, '.')
                    .replace(/-OPEN_BRACKET-/g, '(')
                    .replace(/-CLOSE_BRACKET-/g, ')')
                }
              }
              FileSaver.saveAs(blob, filename)
              resolve({ data: blob, filename })
            } else {
              resolve({
                data: null,
                statusText: xhr.statusText || 'Cannot download the file',
              })
            }
          }
        }
        xhr.send(JSON.stringify(data))
      })
    } catch (err) {
      return { data: null, statusText: `Error: ${err.status || err.Status}` }
    }
  }
}

let customAxios = new CustomAxios()
export default customAxios
