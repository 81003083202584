import React, { Fragment } from 'react'
import { authProvider } from '../../authProvider'
import {
  AzureAD,
  AuthenticationState,
  IAzureADFunctionProps,
} from 'react-aad-msal'

import AuthorizeUser from './AuthorizeUser'
import { IUserRoleInfo } from '../../types/interfaces'
import ErrorMessage from '../../components/ErrorMessage'
import LoadingBar from '../../components/LoadingBar'
import MasterDataList from '../MasterDataManagement'
import { RoleNames } from '../../types/enums'
import * as Text from '../../assets/FormTextLabels.json'

const Payroll = () => {
  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      {({ authenticationState }: IAzureADFunctionProps) => {
        let content
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            content = (
              <AuthorizeUser>
                {({ roles }: IUserRoleInfo) => {
                  if (roles && roles.indexOf(RoleNames.Payroll) !== -1) {
                    return <MasterDataList />
                  } else {
                    return (
                      <ErrorMessage
                        text={Text.messages.unauthorized}
                      ></ErrorMessage>
                    )
                  }
                }}
              </AuthorizeUser>
            )
            break
          case AuthenticationState.Unauthenticated:
            content = (
              <ErrorMessage text={Text.messages.unauthenticated}></ErrorMessage>
            )
            break
          case AuthenticationState.InProgress:
            content = (
              <LoadingBar text={Text.messages.authenticating}></LoadingBar>
            )
            break
        }

        return <Fragment>{content}</Fragment>
      }}
    </AzureAD>
  )
}

export default Payroll
