import React, { useRef } from 'react'
import { AppBar, Box, Grid, Tab, Tabs, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import LoadingBar from '../../components/LoadingBar'
import LookupTab from './LookupTab'
import SiteShiftsTab from './SiteShiftsTab'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'
import { authProvider } from '../../authProvider'
import SitePositionsTab from './SitePositionsTab'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: '20px 20px 0px 20px',
    borderRadius: 2,
    background: theme.palette.background.paper,
  },
  mainTable: {
    paddingTop: theme.spacing(3),
  },
}))

function TabPanel(props: any) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ minHeight: 500, width: '100%' }}
      {...other}
      className={value === index ? '' : 'hidden'}
    >
      <React.Fragment>{children}</React.Fragment>
    </div>
  )
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const handleLogoutClick = () => {
  authProvider.logout()
}

const MasterDataList = ({ siteId }: { siteId?: number }) => {
  const classes = useStyles()

  const [value, setValue] = React.useState(0)

  const siteShiftsRef = useRef<any>()
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
    if (newValue === 2) {
      siteShiftsRef.current.reloadData()
    }
  }
  return (
    <React.Suspense fallback={<LoadingBar text="Loading ..."></LoadingBar>}>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ float: 'left' }} variant="h5">
              Master Data Management
            </Typography>
            <OutlinedSecondaryButton
              variant="outlined"
              style={{ float: 'left', marginLeft: '10px' }}
              onClick={handleLogoutClick}
            >
              Log out
            </OutlinedSecondaryButton>
          </Grid>

          <Grid container item xs={12} className={classes.mainTable}>
            <AppBar position="static" color="transparent">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Lookup Data" {...a11yProps(0)} />
                <Tab label="Site Positions" {...a11yProps(1)} />
                <Tab label="Site Shifts" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <Box p={3}>
                <LookupTab />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SitePositionsTab></SitePositionsTab>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Box p={3}>
                <SiteShiftsTab ref={siteShiftsRef} />
              </Box>
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    </React.Suspense>
  )
}

export default MasterDataList
