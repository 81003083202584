import React from 'react'
import { Box, Typography } from '@material-ui/core'
import LabelValueCell from './LabelValueCell'

const LabelValueCellWithDetail = ({
  label,
  value,
  details,
}: {
  label: string
  value?: boolean
  details?: string
}) => {
  return (
    <Box display="flex" style={{ paddingTop: 10 }} flexDirection="column">
      <LabelValueCell
        label={label}
        value={value ? 'Yes' : 'No'}
        canAddColon={false}
      ></LabelValueCell>
      {value && (
        <Box display="flex" style={{ paddingTop: 5 }}>
          <Typography variant="body1" style={{ paddingLeft: 20 }}>
            Details:
          </Typography>
          <Typography variant="body1">
            <Box fontWeight="fontWeightBold" pl={1}>
              {details}
            </Box>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default LabelValueCellWithDetail
