import React, { useRef, useState } from 'react'
import { Box, TextField } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import StyledAutoComplete from '../../components/StyledAutoComplete'
import { ColDef, DataGrid, ValueFormatterParams } from '@material-ui/data-grid'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'
import NewIcon from '@material-ui/icons/Add'
import { useRecoilState, useRecoilValue } from 'recoil'
import { editableLookupTypesQuerySelector } from '../../store/selector'
import { allLookupListAtom } from './store/atom'
import { IApiResponse, IMasterLookupType } from '../../types/interfaces'
import customAxios from '../../services/customAxios'
import LookupDialog from './LookupDialog'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      '& .MuiDataGrid-window': {
        overflowX: 'hidden',
      },
    },
  },
  containerGid: {
    width: '100%',
    height: 590,
  },
  tooltipPlacementBottom: {
    fontSize: 12,
  },
}))

const LookupTab = () => {
  let classes = useStyles()
  const lookpDialogRef = useRef<any>()

  const lookupTypes = useRecoilValue(editableLookupTypesQuerySelector(null))
  const [
    selectedLookupType,
    setSelectedLookupType,
  ] = useState<IMasterLookupType>()
  const [lookupList, setLookupList] = useRecoilState(allLookupListAtom)
  const fetchData = React.useCallback(async () => {
    let response: IApiResponse
    const lookupTypeId = selectedLookupType?.id
    if (lookupTypeId) {
      response = await customAxios.getAsync(
        `/api/Lookups/editable-lookup/${lookupTypeId}`,
      )

      if (response.data) {
        setLookupList(response.data)
        return
      }
    }
    setLookupList([])
  }, [selectedLookupType, setLookupList])

  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  const onLookupDetails = (details?: any) => {
    if (selectedLookupType) {
      lookpDialogRef.current.showDialog(
        details ? details : { lookupTypeId: selectedLookupType?.id },
      )
    }
  }
  const columns: ColDef[] = [
    { field: 'name', headerName: 'Name', width: 280 },
    { field: 'description', headerName: 'Description', width: 280 },
    {
      field: 'isActive',
      headerName: 'Status',
      width: 120,
      valueFormatter: (params: ValueFormatterParams) =>
        params.value ? 'Active' : 'Inactive',
    },
    {
      field: '',
      headerName: '',
      width: 120,
      renderCell: (params: ValueFormatterParams) => {
        const handleOnClick = () => {
          onLookupDetails(params.data)
        }
        return (
          <OutlinedSecondaryButton
            size="small"
            variant="outlined"
            style={{ marginLeft: 16 }}
            onClick={handleOnClick}
          >
            Edit
          </OutlinedSecondaryButton>
        )
      },
    },
  ]

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" flexWrap="nowrap">
        <Box pb={1}>
          <StyledAutoComplete
            size="small"
            options={lookupTypes}
            value={selectedLookupType}
            onChange={(event: any, newValue: any) => {
              setSelectedLookupType(newValue)
            }}
            getOptionSelected={(option: any, value: any) =>
              option.id === value.id
            }
            getOptionLabel={(option: any) => option.name}
            style={{ width: 250 }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size="small"
                label="Lookup Type"
                variant="outlined"
              />
            )}
          />
        </Box>
      </Box>
      <div className={classes.containerGid}>
        <DataGrid
          rowHeight={40}
          sortingOrder={['desc', 'asc']}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          rows={lookupList}
          columns={columns}
          className={classes.root}
          hideFooterSelectedRowCount={true}
        />
      </div>
      <Box display="flex" flexWrap="nowrap">
        <Box pt={2}>
          <OutlinedSecondaryButton
            size="small"
            variant="outlined"
            startIcon={<NewIcon />}
            onClick={() => onLookupDetails()}
            disabled={!selectedLookupType}
          >
            Add Lookup
          </OutlinedSecondaryButton>
        </Box>
      </Box>
      <LookupDialog ref={lookpDialogRef} refreshData={fetchData} />
    </Box>
  )
}

export default LookupTab
