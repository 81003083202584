import React from 'react'
import { Box, Typography } from '@material-ui/core'

const LabelValueCell = ({
  label,
  value,
  canAddColon = true,
}: {
  label: string
  value?: string
  canAddColon?: boolean
}) => {
  return (
    <Box display="flex" style={{ paddingTop: 10 }}>
      <Typography variant="body1">{`${label}${
        canAddColon ? ':' : ''
      }`}</Typography>
      <Typography variant="body1">
        <Box fontWeight="fontWeightBold" pl={1}>
          {value}
        </Box>
      </Typography>
    </Box>
  )
}

export default LabelValueCell
