import React, { useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
} from '@material-ui/core'
import {
  selectedApplicantAtom,
  selectedStatusAtom,
  selectedSiteAtom,
  selectedShiftAtom,
  selectedSitePositionAtom,
  selectedTaxCodeAtom,
  selectedGraderLineAtom,
  commencementDateAtom,
  occupancyStartDateAtom,
  contractStartDateAtom,
  contractEndDateAtom,
  hourlyRateAtom,
  employeeCodeAtom,
  notesAtom,
  enableSaveButtonAtom,
  enablePayrollControlAtom,
  irdNoAtom,
  bankAccountNoAtom,
  isEnrolledInKiwiSaverAtom,
  isContributingtoKiwiSaverAtom,
  selectedKiwisaverPercentageAtom,
  enableKiwiSaverControlAtom,
  selectedEmpTypeAtom,
  visibleEditPayrollButtonAtom,
  visibleEditEmpTypeButtonAtom,
} from './store/atom'
import { sitePositionsSelector, siteShiftsSelector } from './store/selector'
import {
  lookupsQuerySelector,
  lookupsQuerySelectorWithSortOrder,
} from '../../store/selector'
import { makeStyles, Theme } from '@material-ui/core/styles'
import StyledAutoComplete from '../../components/StyledAutoComplete'
import { LookupTypesEnum } from '../../types/enums'
import { ILookupType, IApiResponse, IApplicant } from '../../types/interfaces'
import { useHistory } from 'react-router-dom'
import MaskedInputText from '../../components/MaskedInputText'
import LocalKeyboardDatePicker from '../../components/LocalKeyboardDatePicker'
import NumberFormattInput from '../../components/NumberFormattInput'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import customAxios from '../../services/customAxios'
import AlertDialog from '../../components/AlertDialog'
import { useReactToPrint } from 'react-to-print'
import ApplicationReport from '../Report/ApplicationReport'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import ContainedPrimaryButton from '../../components/ContainedPrimaryButton'
import OutlinedSecondaryButton from '../../components/OutlinedSecondaryButton'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import BooleanRadioGroup from '../../components/BooleanRadioGroup'
import { replaceMaskText } from '../../services/helpers'
import { useForm } from 'react-hook-form'

const materialTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        maxHeight: '38px',
        maxWidth: '185px',
        fontWeight: 700,
        fontFamily: 'Gilroy-Light',
      },
    },
  },
})

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '90px',
  },
  sitePositionAutoComplete: {
    width: '230px',
  },
  editPayrollButton: {
    alignSelf: 'center',
  },
  hideButton: {
    display: 'none',
  },
  width100: {
    width: '100%',
  },
  width30: {
    width: '30%',
  },
}))

const LabelCell = ({ label, value }: { label: string; value?: any }) => {
  return (
    <Box display="flex" flexGrow={1} flexDirection="column">
      <Typography variant="body1" align="left">
        {label}
      </Typography>
      <Typography variant="body1">
        <Box fontWeight="fontWeightBold">{value}</Box>
      </Typography>
    </Box>
  )
}

const AutoCompleteCell = ({
  label,
  options,
  selectedValue,
  handleOnChange,
  className,
  getOptionLabel,
  disabled = false,
}: {
  label: string
  options: ILookupType[]
  selectedValue: any
  handleOnChange: (event: any, newValue: any) => void
  className?: string
  getOptionLabel?: (option: any) => string
  disabled?: boolean
}) => {
  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      style={{ paddingBottom: 3 }}
    >
      <Typography variant="body1" align="left">
        {label}
      </Typography>
      <StyledAutoComplete
        size="small"
        className={className}
        options={options}
        value={selectedValue}
        onChange={handleOnChange}
        disabled={disabled}
        getOptionSelected={(option: any, value: any) => option.id === value.id}
        getOptionLabel={
          getOptionLabel ? getOptionLabel : (option: any) => option.name
        }
        style={!className ? { width: 185 } : {}}
        renderInput={(params: any) => (
          <TextField {...params} label="" variant="outlined" />
        )}
      />
    </Box>
  )
}

const LocalKeyboardDatePickerCell = ({
  label,
  value,
  handleDateChange,
  minDate,
  handleOnError,
}: {
  label: string
  value: string | Date | null
  handleDateChange: (date: Date) => void
  minDate?: ParsableDate
  handleOnError?: (
    error: React.ReactNode,
    value: MaterialUiPickersDate | ParsableDate,
  ) => void
}) => {
  return (
    <Box display="flex" flexGrow={1} flexDirection="column">
      <Typography variant="body1" align="left">
        {label}
      </Typography>
      <ThemeProvider theme={materialTheme}>
        <LocalKeyboardDatePicker
          value={value}
          handleDateChange={handleDateChange}
          minDate={minDate}
          handleOnError={handleOnError}
        ></LocalKeyboardDatePicker>
      </ThemeProvider>
    </Box>
  )
}

const MainSection = ({ siteId }: { siteId?: number }) => {
  const history = useHistory()

  const classes = useStyles()

  const [applicantDetail, setApplicantDetail] = useRecoilState(
    selectedApplicantAtom,
  )

  const { register, handleSubmit, setValue } = useForm<IApplicant>({
    mode: 'all',
  })

  const statuses = useRecoilValue(lookupsQuerySelector(LookupTypesEnum.Status))
  const [selectedStatus, setSelectedStatus] = useRecoilState(selectedStatusAtom)

  React.useMemo(() => {
    let status = statuses.find((x) => x.id === applicantDetail?.statusId)
    if (status) {
      setSelectedStatus(status)
    } else {
      setSelectedStatus(null)
    }
  }, [applicantDetail, statuses, setSelectedStatus])

  const sites = useRecoilValue(lookupsQuerySelector(LookupTypesEnum.Site))
  const [selectedSite, setSelectedSite] = useRecoilState(selectedSiteAtom)
  React.useMemo(() => {
    let lookupItem = sites.find((x) => x.id === applicantDetail?.siteId)
    if (lookupItem) {
      setSelectedSite(lookupItem)
    } else {
      setSelectedSite(null)
    }
  }, [applicantDetail, sites, setSelectedSite])

  const shifts = useRecoilValue(siteShiftsSelector)
  const [selectedShift, setSelectedShift] = useRecoilState(selectedShiftAtom)
  React.useMemo(() => {
    let lookupItem = shifts.find((x) => x.id === applicantDetail?.siteShiftId)
    if (lookupItem) {
      setSelectedShift(lookupItem)
    } else {
      setSelectedShift(null)
    }
  }, [applicantDetail, shifts, setSelectedShift])

  const sitePositions = useRecoilValue(sitePositionsSelector)
  const [selectedSitePosition, setSelectedSitePosition] = useRecoilState(
    selectedSitePositionAtom,
  )
  React.useMemo(() => {
    let lookupItem = sitePositions.find(
      (x) => x.id === applicantDetail?.sitePositionId,
    )
    if (lookupItem) {
      setSelectedSitePosition(lookupItem)
    } else {
      setSelectedSitePosition(null)
    }
  }, [applicantDetail, sitePositions, setSelectedSitePosition])

  const taxCodes = useRecoilValue(lookupsQuerySelector(LookupTypesEnum.TaxCode))
  const [selectedTaxCode, setSelectedTaxCode] =
    useRecoilState(selectedTaxCodeAtom)
  React.useMemo(() => {
    let lookupItem = taxCodes.find((x) => x.id === applicantDetail?.taxCodeId)
    if (lookupItem) {
      setSelectedTaxCode(lookupItem)
    } else {
      setSelectedTaxCode(null)
    }
  }, [applicantDetail, taxCodes, setSelectedTaxCode])

  const graderLines = useRecoilValue(
    lookupsQuerySelector(LookupTypesEnum.GraderLine),
  )

  const empTypes = useRecoilValue(
    lookupsQuerySelectorWithSortOrder(LookupTypesEnum.EmployeeType),
  )

  const setEmployeeTypeDefaultValue = async () => {
    if (!applicantDetail?.isAvailableForAllHours) {
      setSelectedEmpType(empTypes[1])
    } else {
      let lookupItem = empTypes.find((x) => x.isDefault)
      if (lookupItem) setSelectedEmpType(lookupItem)
      else setSelectedEmpType(null)
    }
  }

  const [selectedEmpType, setSelectedEmpType] =
    useRecoilState(selectedEmpTypeAtom)
  React.useMemo(() => {
    let lookupItem = empTypes.find(
      (x) => x.id === applicantDetail?.employeeTypeId,
    )
    if (lookupItem) {
      setSelectedEmpType(lookupItem)
    } else {
      setEmployeeTypeDefaultValue()
    }
  }, [applicantDetail, empTypes, setSelectedEmpType])

  const kiwisaverPercentages = useRecoilValue(
    lookupsQuerySelectorWithSortOrder(LookupTypesEnum.KiwisaverPercentage),
  )

  const [selectedKiwisaverPercentage, setSelectedKiwisaverPercentage] =
    useRecoilState(selectedKiwisaverPercentageAtom)
  React.useMemo(() => {
    let lookupItem = kiwisaverPercentages.find(
      (x) => x.id === applicantDetail?.kiwisaverContributionPercentageId,
    )
    if (lookupItem) {
      setSelectedKiwisaverPercentage(lookupItem)
    } else {
      setSelectedKiwisaverPercentage(null)
    }
  }, [applicantDetail, kiwisaverPercentages, setSelectedKiwisaverPercentage])

  const [selectedGraderLine, setSelectedGraderLine] = useRecoilState(
    selectedGraderLineAtom,
  )
  React.useMemo(() => {
    let lookupItem = graderLines.find(
      (x) => x.id === applicantDetail?.graderLineId,
    )
    if (lookupItem) {
      setSelectedGraderLine(lookupItem)
    } else {
      setSelectedGraderLine(null)
    }
  }, [applicantDetail, graderLines, setSelectedGraderLine])

  const [commencementDate, setCommencementDate] =
    useRecoilState(commencementDateAtom)
  React.useMemo(() => {
    setCommencementDate(
      applicantDetail?.commencement ? applicantDetail?.commencement : null,
    )
  }, [applicantDetail, setCommencementDate])

  const [occupancyStartDate, setOccupancyStartDate] = useRecoilState(
    occupancyStartDateAtom,
  )
  React.useMemo(() => {
    setOccupancyStartDate(
      applicantDetail?.occupancyStart ? applicantDetail?.occupancyStart : null,
    )
  }, [applicantDetail, setOccupancyStartDate])

  const [contractStartDate, setContractStartDate] = useRecoilState(
    contractStartDateAtom,
  )
  React.useMemo(() => {
    setContractStartDate(
      applicantDetail?.contractStart ? applicantDetail.contractStart : null,
    )
  }, [applicantDetail, setContractStartDate])

  const [contractEndDate, setContractEndDate] =
    useRecoilState(contractEndDateAtom)
  React.useMemo(() => {
    setContractEndDate(
      applicantDetail?.contractEnd ? applicantDetail?.contractEnd : null,
    )
  }, [applicantDetail, setContractEndDate])

  const [hourlyRate, setHourlyRate] = useRecoilState(hourlyRateAtom)
  React.useMemo(() => {
    setHourlyRate(
      applicantDetail?.hourlyRate ? applicantDetail?.hourlyRate.toString() : '',
    )
  }, [applicantDetail, setHourlyRate])

  const [employeeCode, setEmployeeCode] = useRecoilState(employeeCodeAtom)
  React.useMemo(() => {
    setEmployeeCode(
      applicantDetail?.employeeNo ? applicantDetail.employeeNo : '',
    )
  }, [applicantDetail, setEmployeeCode])

  const [irdNo, setIrdNo] = useRecoilState(irdNoAtom)
  React.useMemo(() => {
    setIrdNo(applicantDetail?.irdNo ? applicantDetail.irdNo : '')
  }, [applicantDetail, setIrdNo])

  const [bankAccountNo, setBankAccountNo] = useRecoilState(bankAccountNoAtom)
  React.useMemo(() => {
    setBankAccountNo(
      applicantDetail?.bankAccountNo ? applicantDetail.bankAccountNo : '',
    )
  }, [applicantDetail, setBankAccountNo])

  const [isEnrolledInKiwiSaver, setIsEnrolledInKiwiSaver] = useRecoilState(
    isEnrolledInKiwiSaverAtom,
  )
  React.useMemo(() => {
    setIsEnrolledInKiwiSaver(
      applicantDetail?.isEnrolledInKiwiSaver
        ? applicantDetail?.isEnrolledInKiwiSaver
        : false,
    )
  }, [applicantDetail, setIsEnrolledInKiwiSaver])
  const [isContributingtoKiwiSaver, setIsContributingtoKiwiSaver] =
    useRecoilState(isContributingtoKiwiSaverAtom)
  React.useMemo(() => {
    setIsContributingtoKiwiSaver(
      applicantDetail?.isContributingtoKiwiSaver
        ? applicantDetail?.isContributingtoKiwiSaver
        : false,
    )
  }, [applicantDetail, setIsContributingtoKiwiSaver])

  const notes = useRecoilValue(notesAtom)

  const dialogRef = React.useRef<any>()

  const handleOnSave = async () => {
    let updatingApplicant = {
      statusId: selectedStatus?.id,
      siteId: selectedSite?.id,
      siteShiftId: selectedShift?.id,
      sitePositionId: selectedSitePosition?.id,
      taxCodeId: selectedTaxCode?.id,
      graderLineId: selectedGraderLine?.id,
      commencement: commencementDate,
      occupancyStart: occupancyStartDate,
      contractStart: contractStartDate,
      contractEnd: contractEndDate,
      hourlyRate: hourlyRate === '' ? 0 : Number(hourlyRate),
      employeeNo: employeeCode,
      note: notes,
      irdNo: replaceMaskText(irdNo),
      bankAccountNo: replaceMaskText(bankAccountNo),
      isEnrolledInKiwiSaver: isEnrolledInKiwiSaver,
      isContributingtoKiwiSaver: isContributingtoKiwiSaver,
      kiwisaverContributionPercentageId: selectedKiwisaverPercentage?.id,
      kiwisaverContributionPercentageValue: selectedKiwisaverPercentage?.name,
      employeeTypeId: selectedEmpType?.id,
    }

    let applicant = {
      ...applicantDetail,
      ...updatingApplicant,
    } as IApplicant

    let res: IApiResponse
    if (siteId) {
      res = await customAxios.postAsync(
        `/api/Applicants/save/siteHR/${siteId}`,
        applicant,
      )
    } else {
      res = await customAxios.postAsync(`/api/Applicants/save`, applicant)
    }
    if (res.data) {
      dialogRef.current.initDialog({
        title: 'Information',
        content: 'Your updates to this application have been saved.',
      })
      setEnableSaveBtn(false)
      setEnablePayrollControls(false)
      setVisibleEditPayrollBtn(true)
      setVisibleEditEmpTypeBtn(true)

      //seamlessly reload applicantDetail
      setApplicantDetail(applicant)
    } else if (res.error) {
      dialogRef.current.initDialog({
        title: 'Error',
        content: res.error,
      })
    }
    dialogRef.current.openDialog()
  }

  const componentRef = useRef<any>()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const [printApplicationRows, setPrintApplicationRows] = useState([])
  const onPrintClick = async () => {
    if (applicantDetail) {
      const res = await customAxios.getAsync(
        `/api/Applicants/print-view?ids=${applicantDetail.id}`,
      )
      if (res.data) {
        setPrintApplicationRows(res.data)
        if (handlePrint) handlePrint()
      }
    }
  }

  const onEditEmpTypeClicked = async () => {
    setVisibleEditEmpTypeBtn(false)
  }

  const onCancelEmpTypeClicked = async () => {
    setVisibleEditEmpTypeBtn(true)

    setEmployeeTypeDefaultValue()
  }

  const onEditPayrollClick = async () => {
    setEnablePayrollControls(true)
    setVisibleEditPayrollBtn(false)

    if (isEnrolledInKiwiSaver) setEnableKiwiSaverControls(true)
  }

  const resetPayrollData = async () => {
    if (applicantDetail != null) {
      setIrdNo(applicantDetail.irdNo)

      let lookupItem = taxCodes.find((x) => x.id === applicantDetail.taxCodeId)
      if (lookupItem) {
        setSelectedTaxCode(lookupItem)
      } else {
        setSelectedTaxCode(null)
      }

      setBankAccountNo(applicantDetail.bankAccountNo)
      setIsEnrolledInKiwiSaver(applicantDetail.isEnrolledInKiwiSaver)
      setIsContributingtoKiwiSaver(applicantDetail.isContributingtoKiwiSaver)

      lookupItem = kiwisaverPercentages.find(
        (x) => x.id === applicantDetail?.kiwisaverContributionPercentageId,
      )
      if (lookupItem) {
        setSelectedKiwisaverPercentage(lookupItem)
      } else {
        setSelectedKiwisaverPercentage(null)
      }
    }
  }

  const onCancelClick = async () => {
    setEnablePayrollControls(false)
    setVisibleEditPayrollBtn(true)

    resetPayrollData()
  }

  const [enableKiwiSaverControls, setEnableKiwiSaverControls] = useRecoilState(
    enableKiwiSaverControlAtom,
  )
  const [enablePayrollControls, setEnablePayrollControls] = useRecoilState(
    enablePayrollControlAtom,
  )
  React.useMemo(() => {
    setEnablePayrollControls(false)
  }, [setEnablePayrollControls])

  const [visibleEditPayrollBtn, setVisibleEditPayrollBtn] = useRecoilState(
    visibleEditPayrollButtonAtom,
  )
  React.useMemo(() => {
    setVisibleEditPayrollBtn(true)
  }, [setVisibleEditPayrollBtn])

  const [visibleEditEmpTypeBtn, setVisibleEditEmpTypeBtn] = useRecoilState(
    visibleEditEmpTypeButtonAtom,
  )

  React.useMemo(() => {
    setVisibleEditEmpTypeBtn(true)
  }, [setVisibleEditEmpTypeBtn])

  const [enableSaveBtn, setEnableSaveBtn] = useRecoilState(enableSaveButtonAtom)
  React.useMemo(() => {
    setEnableSaveBtn(false)
  }, [setEnableSaveBtn])

  const [minEndDate, setMinEndDate] = React.useState(new Date(2000, 1, 1))
  React.useMemo(() => {
    let minDate: Date | null = null

    if (contractStartDate) {
      minDate = new Date(contractStartDate)
    }

    if (occupancyStartDate) {
      const date = new Date(occupancyStartDate)
      if (!minDate || date > minDate) {
        minDate = date
      }
    }

    if (commencementDate) {
      const date = new Date(commencementDate)
      if (!minDate || date > minDate) {
        minDate = date
      }
    }
    if (minDate) {
      setMinEndDate(minDate)
    }
  }, [contractStartDate, occupancyStartDate, commencementDate])

  const siteSection = siteId ? (
    <LabelCell label="Site" value={applicantDetail?.siteValue}></LabelCell>
  ) : (
    <AutoCompleteCell
      label="Site"
      options={sites}
      selectedValue={selectedSite}
      handleOnChange={(event: any, newValue: any) => {
        setSelectedSitePosition(null)
        setSelectedShift(null)
        setSelectedSite(newValue)
        setEnableSaveBtn(true)
      }}
    ></AutoCompleteCell>
  )
  return (
    <form
      className="form-step"
      onSubmit={handleSubmit(handleOnSave)}
      autoComplete="off"
    >
      <Grid container direction="column">
        {/* first row */}
        <Grid item container xs={12} style={{ paddingBottom: 20 }}>
          {/* first column */}
          <Grid item xs={2}>
            <Box display="flex" flexGrow={1} flexDirection="row">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                style={{ width: '100%' }}
              >
                <Typography
                  variant="h4"
                  align="left"
                  style={{ overflowWrap: 'break-word', width: '100%' }}
                >
                  {`${applicantDetail?.firstName} ${applicantDetail?.surName}`}
                </Typography>
                <TextField
                  value={applicantDetail?.mobile}
                  name="mobile"
                  InputProps={{
                    inputComponent: MaskedInputText as any,
                    disableUnderline: true,
                    inputProps: {
                      readOnly: true,
                      style: {
                        fontSize: '25px',
                      },
                      maskInput: [
                        /\d/,
                        /\d/,
                        /\d/,
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ],
                    },
                  }}
                />
              </Box>
            </Box>
          </Grid>
          {/* second column */}
          <Grid item xs={2}>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                {siteSection}
              </Grid>
            </Grid>
            <Grid item container xs={12} style={{ paddingTop: 10 }}>
              <Grid item xs={6}>
                <LabelCell
                  label="Preferred Location"
                  value={applicantDetail?.preferredLocationValue}
                ></LabelCell>
              </Grid>
            </Grid>
          </Grid>
          {/* third column */}
          <Grid item container xs={4}>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <AutoCompleteCell
                  label="Status"
                  options={statuses}
                  selectedValue={selectedStatus}
                  handleOnChange={(event: any, newValue: any) => {
                    setSelectedStatus(newValue)
                    setEnableSaveBtn(true)
                  }}
                ></AutoCompleteCell>
              </Grid>
              <Grid item xs={6}>
                <AutoCompleteCell
                  label="Shift"
                  options={shifts}
                  selectedValue={selectedShift}
                  handleOnChange={(event: any, newValue: any) => {
                    setSelectedShift(newValue)
                    setEnableSaveBtn(true)
                  }}
                ></AutoCompleteCell>
              </Grid>
            </Grid>

            <Grid item container xs={12} style={{ paddingTop: 10 }}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <LabelCell
                  label="Preferred Shift"
                  value={applicantDetail?.preferredShiftValue}
                ></LabelCell>
              </Grid>
            </Grid>
          </Grid>
          {/* forth column */}
          <Grid item container xs={4}>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <AutoCompleteCell
                  label="Role"
                  options={sitePositions}
                  selectedValue={selectedSitePosition}
                  handleOnChange={(event: any, newValue: any) => {
                    setSelectedSitePosition(newValue)
                    setEnableSaveBtn(true)
                  }}
                  className={classes.sitePositionAutoComplete}
                ></AutoCompleteCell>
              </Grid>
              <Grid
                item
                xs={6}
                container
                alignItems="flex-start"
                style={{ paddingBottom: 10, paddingLeft: 10, paddingTop: 20 }}
              >
                <ContainedPrimaryButton
                  size="small"
                  variant="contained"
                  className={(classes.button, classes.width30)}
                  type="submit"
                  disabled={!enableSaveBtn}
                >
                  Save
                </ContainedPrimaryButton>
                <OutlinedSecondaryButton
                  size="small"
                  variant="outlined"
                  className={(classes.button, classes.width30)}
                  style={{ marginLeft: 5 }}
                  onClick={onPrintClick}
                >
                  Print
                </OutlinedSecondaryButton>
                <OutlinedSecondaryButton
                  size="small"
                  variant="outlined"
                  className={(classes.button, classes.width30)}
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    if (enableSaveBtn) {
                      dialogRef.current.initDialog({
                        title: 'Confirm',
                        content:
                          'Your changes have not been saved. Are you sure you want to continue ?',
                        primaryButtonText: 'Yes',
                        secondaryButtonText: 'No',
                        showSecondaryButton: true,
                        handlePrimaryButtonClick: () => {
                          history.goBack()
                          setSelectedEmpType(null)
                        },
                      })
                      dialogRef.current.openDialog()
                    } else {
                      history.goBack()
                      setSelectedEmpType(null)
                    }
                  }}
                >
                  Back
                </OutlinedSecondaryButton>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <LabelCell
                  label="Preferred Role"
                  value={applicantDetail?.preferredRoleValue}
                ></LabelCell>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* second row */}
        <Grid item container xs={12} style={{ paddingTop: 20 }}>
          <Grid item xs={12}>
            <Grid item container xs={12} style={{ paddingBottom: 16 }}>
              {/* first column */}
              <Grid item xs={2} style={{ paddingRight: 20 }}>
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography variant="body1" align="left">
                    Employee Code
                  </Typography>
                  <FormControl component="fieldset" fullWidth>
                    <TextField
                      fullWidth
                      value={employeeCode}
                      onChange={(event) => {
                        setEmployeeCode(event.target.value)
                        setEnableSaveBtn(true)
                      }}
                      InputProps={{
                        inputComponent: MaskedInputText as any,
                        inputProps: {
                          minLength: 4,
                          maskInput: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
                          style: {
                            fontWeight: 700,
                          },
                        },
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              {/* second column */}
              <Grid item xs={2}>
                <LocalKeyboardDatePickerCell
                  label="Commencement Date"
                  value={commencementDate}
                  handleDateChange={(date: Date) => {
                    setCommencementDate(date ? date.toDateString() : null)
                    setEnableSaveBtn(true)
                  }}
                ></LocalKeyboardDatePickerCell>
              </Grid>
              {/* third column */}
              <Grid item xs={2}>
                <LocalKeyboardDatePickerCell
                  label="Occupancy Start Date"
                  value={occupancyStartDate}
                  handleDateChange={(date: Date) => {
                    setOccupancyStartDate(date ? date.toDateString() : null)
                    setEnableSaveBtn(true)
                  }}
                ></LocalKeyboardDatePickerCell>
              </Grid>
              {/* forth column */}
              <Grid item xs={2}>
                <LocalKeyboardDatePickerCell
                  label="Contract Start"
                  value={contractStartDate}
                  handleDateChange={(date: Date) => {
                    setContractStartDate(date ? date.toDateString() : null)
                    setEnableSaveBtn(true)
                  }}
                ></LocalKeyboardDatePickerCell>
              </Grid>
              {/* fifth column */}
              <Grid item xs={2}>
                <LocalKeyboardDatePickerCell
                  label="Contract End"
                  value={contractEndDate}
                  handleDateChange={(date: Date) => {
                    setContractEndDate(date ? date.toDateString() : null)
                    setEnableSaveBtn(true)
                  }}
                  minDate={minEndDate}
                  handleOnError={(error, value) => {
                    if (error) {
                      setEnableSaveBtn(false)
                    }
                  }}
                ></LocalKeyboardDatePickerCell>
              </Grid>
              {/* sixth column */}
              <Grid item xs={2}>
                <AutoCompleteCell
                  label="Employee Type"
                  options={empTypes}
                  selectedValue={selectedEmpType}
                  disabled={visibleEditEmpTypeBtn}
                  className={classes.width100}
                  handleOnChange={(event: any, newValue: any) => {
                    setSelectedEmpType(newValue)
                    setEnableSaveBtn(true)
                  }}
                ></AutoCompleteCell>
                <ContainedPrimaryButton
                  size="small"
                  variant="contained"
                  className={
                    visibleEditEmpTypeBtn ? classes.button : classes.hideButton
                  }
                  onClick={onEditEmpTypeClicked}
                  hidden={true}
                >
                  EDIT
                </ContainedPrimaryButton>

                <OutlinedSecondaryButton
                  size="small"
                  variant="outlined"
                  className={
                    !visibleEditEmpTypeBtn ? classes.button : classes.hideButton
                  }
                  onClick={onCancelEmpTypeClicked}
                >
                  Cancel
                </OutlinedSecondaryButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* third row */}
        <Grid item container xs={12} style={{ paddingTop: 20 }}>
          {/* first column */}
          <Grid item xs={4}>
            <Grid item container xs={12} style={{ paddingBottom: 16 }}>
              <Grid item xs={6}>
                <AutoCompleteCell
                  label="Grader Line"
                  options={graderLines}
                  selectedValue={selectedGraderLine}
                  getOptionLabel={(option: any) => option.description}
                  handleOnChange={(event: any, newValue: any) => {
                    setSelectedGraderLine(newValue)
                    setEnableSaveBtn(true)
                  }}
                  className={classes.sitePositionAutoComplete}
                ></AutoCompleteCell>
              </Grid>

              <Grid item xs={6}>
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography variant="body1" align="left">
                    Hourly Rate
                  </Typography>
                  <NumberFormattInput
                    value={hourlyRate}
                    decimalScale={4}
                    bold
                    handleChange={(event) => {
                      if (
                        (applicantDetail?.hourlyRate &&
                          applicantDetail?.hourlyRate.toString() !==
                            event.target.value) ||
                        (!applicantDetail?.hourlyRate && !!event.target.value)
                      ) {
                        setEnableSaveBtn(true)
                      }
                      setHourlyRate(event.target.value)
                    }}
                  ></NumberFormattInput>
                </Box>
              </Grid>
            </Grid>
            <Grid item container xs={12} style={{ paddingBottom: 16 }}>
              <Grid item xs={6}>
                {/* Empty Cell */}
              </Grid>

              <Grid item xs={6}>
                {/* Empty Cell */}
              </Grid>
            </Grid>
          </Grid>

          {/* second column */}
          <Grid
            item
            xs={4}
            style={{
              backgroundColor: '#eeeeee',
              paddingLeft: 10,
              paddingRight: 20,
            }}
          >
            <Grid item container xs={12} style={{ paddingBottom: 16 }}>
              <Grid item xs={6} style={{ paddingRight: 20 }}>
                <Box display="flex" flexGrow={1} flexDirection="column">
                  <Typography variant="body1" align="left">
                    IRD Number
                  </Typography>
                  <FormControl component="fieldset" fullWidth>
                    <TextField
                      fullWidth
                      value={irdNo}
                      onChange={(event) => {
                        setIrdNo(event.target.value)
                        setEnableSaveBtn(true)
                      }}
                      disabled={!enablePayrollControls}
                      InputProps={{
                        inputComponent: MaskedInputText as any,
                        inputProps: {
                          minLength: 6,
                          maskInput: [
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                          ],
                          style: {
                            fontWeight: 700,
                          },
                        },
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" flexGrow={1} flexDirection="column">
                  <AutoCompleteCell
                    label="Tax Code"
                    options={taxCodes}
                    selectedValue={selectedTaxCode}
                    handleOnChange={(event: any, newValue: any) => {
                      setSelectedTaxCode(newValue)
                      setEnableSaveBtn(true)
                    }}
                    disabled={!enablePayrollControls}
                  ></AutoCompleteCell>
                </Box>
              </Grid>
            </Grid>
            <Grid item container xs={12} style={{ paddingBottom: 16 }}>
              <Grid item xs={6}>
                <Box display="flex" flexGrow={1} flexDirection="column">
                  <Typography variant="body1" align="left">
                    Are you currently enrolled in Kiwisaver?
                  </Typography>
                  <BooleanRadioGroup
                    name="isEnrolledInKiwiSaver"
                    defaultValue={isEnrolledInKiwiSaver.toString()}
                    disabled={!enablePayrollControls}
                    onChange={(event) => {
                      setIsEnrolledInKiwiSaver(event.target.value)
                      if (event.target.value === 'false') {
                        setIsContributingtoKiwiSaver(false)
                        setSelectedKiwisaverPercentage(null)
                        setEnableKiwiSaverControls(false)
                      } else {
                        setEnableKiwiSaverControls(true)
                      }

                      setEnableSaveBtn(true)
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" flexGrow={1} flexDirection="column">
                  <Typography variant="body1" align="left">
                    Do you want to contribute to Kiwisaver while working at
                    EastPack?
                  </Typography>
                  <BooleanRadioGroup
                    name="isContributingtoKiwiSaver"
                    defaultValue={isContributingtoKiwiSaver.toString()}
                    disabled={
                      !enablePayrollControls || !enableKiwiSaverControls
                    }
                    onChange={(event) => {
                      setIsContributingtoKiwiSaver(event.target.value)
                      setEnableSaveBtn(true)
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* third column */}
          <Grid
            item
            xs={4}
            style={{ paddingRight: 20, backgroundColor: '#eeeeee' }}
          >
            <Grid item container xs={12} style={{ paddingBottom: 16 }}>
              <Grid item xs={6}>
                <Box display="flex" flexGrow={1} flexDirection="column">
                  <Typography variant="body1" align="left">
                    Bank Account
                  </Typography>
                  <FormControl component="fieldset" fullWidth>
                    <TextField
                      fullWidth
                      value={bankAccountNo}
                      onChange={(event) => {
                        setBankAccountNo(event.target.value)
                        setEnableSaveBtn(true)
                      }}
                      disabled={!enablePayrollControls}
                      InputProps={{
                        inputComponent: MaskedInputText as any,
                        inputProps: {
                          minLength: 18,
                          maskInput: [
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                          ],
                        },
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                container
                alignItems="flex-end"
                style={{ paddingBottom: 10, paddingLeft: 10 }}
              >
                <ContainedPrimaryButton
                  size="small"
                  variant="contained"
                  className={
                    visibleEditPayrollBtn ? classes.button : classes.hideButton
                  }
                  style={{ marginLeft: 5, width: 160 }}
                  onClick={onEditPayrollClick}
                >
                  Edit Payroll Data
                </ContainedPrimaryButton>

                <OutlinedSecondaryButton
                  size="small"
                  variant="outlined"
                  className={
                    !visibleEditPayrollBtn ? classes.button : classes.hideButton
                  }
                  style={{ marginLeft: 5 }}
                  onClick={onCancelClick}
                >
                  Cancel
                </OutlinedSecondaryButton>
              </Grid>
            </Grid>
            <Grid item container xs={12} style={{ paddingBottom: 16 }}>
              <Grid item xs={6}>
                <Box display="flex" flexGrow={1} flexDirection="column">
                  <AutoCompleteCell
                    label="What percentage of your pay would you like to contribute?"
                    options={kiwisaverPercentages}
                    selectedValue={selectedKiwisaverPercentage}
                    disabled={
                      !enablePayrollControls || !enableKiwiSaverControls
                    }
                    handleOnChange={(event: any, newValue: any) => {
                      setSelectedKiwisaverPercentage(newValue)
                      setEnableSaveBtn(true)
                    }}
                    className={classes.sitePositionAutoComplete}
                  ></AutoCompleteCell>
                </Box>
              </Grid>

              <Grid item xs={6}>
                {/* Empty Cell */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <AlertDialog ref={dialogRef}></AlertDialog>
        <div className="onlyPrint">
          <ApplicationReport
            ref={componentRef}
            applications={printApplicationRows}
          />
        </div>
      </Grid>
    </form>
  )
}

export default MainSection
