import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import ContainedPrimaryButton from './ContainedPrimaryButton'
import OutlinedSecondaryButton from './OutlinedSecondaryButton'

export interface IAlertDialogProps {
  title: string
  content: string
  primaryButtonText?: string
  secondaryButtonText?: string
  showSecondaryButton?: boolean
  handleSecondaryButtonClick?: () => void
  handlePrimaryButtonClick?: () => void
}

const AlertDialog = React.forwardRef((props: any, ref: any) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const defaultTitle = ''
  const defaultContent = 'Content'
  const defaultPrimaryText = 'OK'
  const defaultSecondaryText = 'Cancel'
  const defaultShowSecondary = false
  const defaultPrimaryFunc = () => () => {}
  const defaultSecondaryFunc = () => () => {}

  const [open, setOpen] = React.useState(false)
  const [titleText, setTitleText] = React.useState(defaultTitle)
  const [contentText, setContentText] = React.useState(defaultContent)
  const [primaryText, setPrimaryText] = React.useState(defaultPrimaryText)
  const [secondaryText, setSecondaryText] = React.useState(defaultSecondaryText)
  const [showSecondary, setShowSecondary] = React.useState(defaultShowSecondary)
  const [primaryFunc, setPrimaryFunc] = React.useState(defaultPrimaryFunc)
  const [secondaryFunc, setSecondaryFunc] = React.useState(defaultSecondaryFunc)

  React.useImperativeHandle(ref, () => ({
    initDialog: ({
      title,
      content,
      primaryButtonText,
      secondaryButtonText,
      showSecondaryButton,
      handlePrimaryButtonClick,
      handleSecondaryButtonClick,
    }: IAlertDialogProps) => {
      setTitleText(title)
      setContentText(content)
      setPrimaryText(primaryButtonText ? primaryButtonText : defaultPrimaryText)
      setSecondaryText(
        secondaryButtonText ? secondaryButtonText : defaultSecondaryText,
      )
      setShowSecondary(
        typeof showSecondaryButton !== 'undefined'
          ? showSecondaryButton
          : defaultShowSecondary,
      )

      if (typeof handlePrimaryButtonClick !== 'undefined') {
        setPrimaryFunc(() => () => {
          handlePrimaryButtonClick()
        })
      } else {
        setPrimaryFunc(defaultPrimaryFunc)
      }

      if (typeof handleSecondaryButtonClick !== 'undefined') {
        setSecondaryFunc(() => () => {
          handleSecondaryButtonClick()
        })
      } else {
        setSecondaryFunc(defaultSecondaryFunc)
      }
    },
    openDialog: () => {
      setOpen(true)
    },
  }))

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => {
          setOpen(false)
          secondaryFunc()
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{titleText}</DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {showSecondary && (
            <OutlinedSecondaryButton
              variant="outlined"
              onClick={() => {
                setOpen(false)
                secondaryFunc()
              }}
            >
              {secondaryText}
            </OutlinedSecondaryButton>
          )}

          <ContainedPrimaryButton
            variant="contained"
            autoFocus
            onClick={() => {
              setOpen(false)
              primaryFunc()
            }}
          >
            {primaryText}
          </ContainedPrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  )
})

export default AlertDialog
