import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
} from '@material-ui/core'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useRecoilState, useRecoilValue } from 'recoil'
import * as Text from '../../assets/FormTextLabels.json'
import ConditionalRender from '../../components/ConditionalRender'
import StepsButton from '../../components/StepsButton'
import StyledAutoComplete from '../../components/StyledAutoComplete'
import StyleTextField from '../../components/StyleTextField'
import TitleField from '../../components/TitleField'
import lookupService from '../../services/lookupService'
import { lookupsQuerySelector } from '../../store/selector'
import { LookupTypesEnum } from '../../types/enums'
import { ILookupType } from '../../types/interfaces'
import { ILookups, ISection7, lookUpsAtom, section7Atom } from './state'

const Section7 = ({
  submit,
  back,
}: {
  submit?: () => void
  back?: () => void
}) => {
  const [state, setState] = useRecoilState<ISection7>(section7Atom)
  const [lookUpsState, setlookUpsState] = useRecoilState<ILookups>(lookUpsAtom)
  const { register, handleSubmit, setValue } = useForm<ISection7>({
    mode: 'all',
    defaultValues: state,
  })

  let action: any
  const onSubmit: SubmitHandler<ISection7> = (data) => {
    setState({
      ...state,
      ...data,
    })
    if (action) action()
  }

  const blurb = Text.section7.blurbs.join('')

  React.useEffect(() => {
    ;[LookupTypesEnum.JobReferral].forEach(async (lookupName) => {
      if (!lookUpsState[lookupName] || lookUpsState[lookupName].length < 1) {
        const data = await lookupService.getLookup(lookupName)
        setlookUpsState({ ...lookUpsState, [lookupName]: data })
      }
    })
  }, [lookUpsState, setlookUpsState])

  const jobReferrals = useRecoilValue(
    lookupsQuerySelector(LookupTypesEnum.JobReferral),
  )

  const onChange = (name: string, value: ILookupType[]) => {
    const hasEmployees =
      value?.find((x) => x.name === 'Referred by an EastPack Employee') !==
      undefined

    setState({ ...state, [name]: value, hasEmployees: hasEmployees })
    setValue(name, value, { shouldDirty: true })
    setValue('hasEmployees', hasEmployees, { shouldDirty: true })
  }

  const onChangeText = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setValue(e.target.name, e.target.value, { shouldDirty: true })
  }

  const onCheckboxChange = (name: string, newValue: boolean) => {
    setState({ ...state, [name]: newValue })
  }

  const {
    isConfirm,
    jobReferralIds,
    hasEmployees,
    referralEmployeeDetail,
  } = state

  return (
    <form className="form-step" onSubmit={handleSubmit(onSubmit)}>
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TitleField variant="h4" gutterBottom>
              {Text.section7.subtitle1}
            </TitleField>
          </Grid>
          <Grid item xs={12}>
            <p dangerouslySetInnerHTML={{ __html: blurb }}></p>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section7.question01}
              </FormLabel>
              <FormControlLabel
                name="isConfirm"
                inputRef={register}
                label="I agree"
                control={
                  <Checkbox
                    color="primary"
                    required
                    checked={isConfirm}
                    onChange={(event: any, newValue: boolean) => {
                      onCheckboxChange('isConfirm', newValue)
                    }}
                  />
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend" required>
                {Text.section7.question02}
              </FormLabel>
              <StyledAutoComplete
                aria-required
                multiple
                size="small"
                options={jobReferrals}
                value={jobReferralIds}
                onChange={(event: any, newValue: any) => {
                  onChange('jobReferralIds', newValue)
                }}
                ref={() => register('jobReferralIds')}
                getOptionSelected={(option: any, value: any) =>
                  option.id === value.id
                }
                getOptionLabel={(option: any) => option.name}
                renderInput={(params: any) => (
                  <TextField {...params} size="small" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <ConditionalRender condition={hasEmployees}>
              <FormControl component="fieldset" fullWidth>
                <StyleTextField
                  required
                  name="referralEmployeeDetail"
                  label={Text.section7.question03}
                  inputRef={register}
                  defaultValue={referralEmployeeDetail}
                  fullWidth
                  onChange={onChangeText}
                  multiline={true}
                />
              </FormControl>
            </ConditionalRender>
          </Grid>
        </Grid>
      </React.Fragment>
      <StepsButton
        onBack={back}
        onNext={() => (action = submit)}
        isSubmit={true}
      />
    </form>
  )
}

export default Section7
